import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_ACTIONS,
    PROPERTY_CATEGORY,
    PROPERTY_CLIENT_FIRST_NAME,
    PROPERTY_CLIENT_LAST_NAME,
    PROPERTY_CLIENT_MIDDLE_NAME,
    PROPERTY_COACH,
    PROPERTY_COACH_PRICE_TYPE,
    PROPERTY_COMMENT,
    PROPERTY_COURT,
    PROPERTY_DATETIME,
    PROPERTY_DATETIME_CREATION,
    PROPERTY_DURATION,
    PROPERTY_GROUP,
    PROPERTY_IMPLEMENTATION_DOCUMENT,
    PROPERTY_IS_ARCHIVE,
    PROPERTY_ORGANIZATION,
    PROPERTY_PRICE_TYPE,
    PROPERTY_TOP_PRIORITY,
    PROPERTY_USER,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import WaitingListEditButton from 'page/WaitingList/components/WaitingListEditButton';
import ArchiveButton from 'shared/component/Button/ArchiveButton/ArchiveButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import { renderChips } from 'shared/component/DataGrid/Cell/Chip';
import useArchiveWaitingList from 'shared/hook/waitingList/archive/useArchiveWaitingList';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, isArchive, datetime } } = props;
    const { archiveWaitingList } = useArchiveWaitingList();

    const handleArchive = async () => {
        await archiveWaitingList(id);
    };

    return (
        <>
            <WaitingListEditButton id={ id } />
            { !isArchive && <ArchiveButton id={ id } datetime={ datetime } title="Лист ожидания" actionArchive={ handleArchive } /> }
        </>
    );
};

const renderCourts: FC<GridRenderCellParams> = (props) => {
    const { row } = props;

    return renderChips({
        data: row.courts,
        getId: (court) => court.value,
        getLabel: (court) => court.label,
    });
};

const renderCoaches: FC<GridRenderCellParams> = (props) => {
    const { row } = props;

    return renderChips({
        data: row.coaches,
        getId: (coach) => coach.value,
        getLabel: (coach) => coach.label,
    });
};

export const WAITING_LIST_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_CLIENT_LAST_NAME,
        field: 'clientLastName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<string | null>) => renderStringCell(params.row?.client?.lastName),
        hasuraFilterFields: ['client_datum', 'client', 'last_name'],
        hasuraSortFields: ['client_datum', 'client', 'last_name'],
    },
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME,
        field: 'clientFirstName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<string | null>) => renderStringCell(params.row?.client?.firstName),
        hasuraFilterFields: ['client_datum', 'client', 'first_name'],
        hasuraSortFields: ['client_datum', 'client', 'first_name'],
    },
    {
        headerName: PROPERTY_CLIENT_MIDDLE_NAME,
        field: 'clientMiddleName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<string | null>) => renderStringCell(params.row?.client?.middleName),
        hasuraFilterFields: ['client_datum', 'client', 'middle_name'],
        hasuraSortFields: ['client_datum', 'client', 'middle_name'],
    },
    {
        headerName: PROPERTY_GROUP,
        field: 'group',
        minWidth: 250,
        align: 'left',
        headerAlign: 'left',
        type: 'singleSelect',
        renderCell: (params: GridRenderCellParams<string>) =>
            renderStringCell(params.row.group?.label),
        hasuraSortFields: ['group', 'name'],
        hasuraFilterFields: ['group', 'id'],
    },
    {
        headerName: PROPERTY_IMPLEMENTATION_DOCUMENT,
        field: 'groupDocument',
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['implementation_document', 'number'],
        hasuraFilterFields: ['implementation_document', 'number'],
        renderCell: (params: GridRenderCellParams<string>) =>
            renderStringCell(params.row.groupDocument?.label),
    },
    {
        headerName: PROPERTY_ORGANIZATION,
        field: 'organization',
        minWidth: 200,
        align: 'left',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['organization', 'name'],
        hasuraFilterFields: ['organization', 'id'],
        renderCell: (params: GridRenderCellParams<string>) =>
            renderStringCell(params.row.organization?.label),
    },
    {
        headerName: PROPERTY_CATEGORY,
        field: 'serviceCategory',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['service_category', 'name'],
        hasuraFilterFields: ['service_category', 'id'],
        renderCell: (params: GridRenderCellParams<string>) =>
            renderStringCell(params.row.serviceCategory?.label),
    },
    {
        headerName: PROPERTY_PRICE_TYPE,
        field: 'priceType',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['price_type', 'title'],
        hasuraFilterFields: ['price_type', 'id'],
        renderCell: (params: GridRenderCellParams<string>) =>
            renderStringCell(params.row.priceType?.label),
    },
    {
        headerName: PROPERTY_COACH_PRICE_TYPE,
        field: 'coachPriceType',
        minWidth: 230,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['coach_price_type', 'title'],
        hasuraFilterFields: ['coach_price_type', 'id'],
        renderCell: (params: GridRenderCellParams<string>) =>
            renderStringCell(params.row.coachPriceType?.label),
    },
    {
        headerName: PROPERTY_USER,
        field: 'user',
        minWidth: 200,
        align: 'left',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['user', 'full_name'],
        hasuraFilterFields: ['user', 'full_name'],
        renderCell: (params: GridRenderCellParams<string>) =>
            renderStringCell(params.row.user?.label),
    },
    {
        headerName: PROPERTY_DURATION,
        field: 'duration',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraSortFields: ['duration'],
        hasuraFilterFields: ['duration'],
    },
    {
        headerName: PROPERTY_DATETIME,
        field: 'datetime',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['datetime'],
        hasuraFilterFields: ['datetime'],
        renderCell: (params) => renderDateToGrid(params.row?.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_TOP_PRIORITY,
        field: 'topPriority',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        hasuraSortFields: ['top_priority'],
        hasuraFilterFields: ['top_priority'],
        renderCell: isActiveIcon,
    },
    {
        headerName: PROPERTY_COMMENT,
        field: 'comment',
        minWidth: 300,
        align: 'left',
        headerAlign: 'left',
        type: 'string',
    },
    {
        headerName: PROPERTY_DATETIME_CREATION,
        field: 'createdAt',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['created_at'],
        hasuraFilterFields: ['created_at'],
        renderCell: (params) => renderDateToGrid(params.row?.createdAt, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_IS_ARCHIVE,
        field: 'isArchive',
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        hasuraSortFields: ['is_archive'],
        hasuraFilterFields: ['is_archive'],
        renderCell: isActiveIcon,
    },
    {
        headerName: PROPERTY_COURT,
        field: 'courts',
        minWidth: 300,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: renderCourts,
    },
    {
        headerName: PROPERTY_COACH,
        field: 'coaches',
        minWidth: 300,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: renderCoaches,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
