import { gql } from '@apollo/client';

export type WaitingListCoachCreatePayloadT = {
    coach_id: string;
    waiting_list_id: string;
}

export type WaitingListCourtCreatePayloadT = {
    court_id: string;
    waiting_list_id: string;
}

export type CreateMultipleWaitingListCoachResponseT = {
    createMultipleWaitingListCoach: CreateMultipleWaitingListResponseDataT;
    createMultipleWaitingListCourt: CreateMultipleWaitingListResponseDataT;
};

export type CreateMultipleWaitingListResponseDataT = {
    affected_rows: number;
};

export type CreateMultipleWaitingListCoachVarT = {
    waitingListCoachPayload: WaitingListCoachCreatePayloadT[];
    waitingListCourtPayload: WaitingListCourtCreatePayloadT[];
};

export const CREATE_MULTIPLE_WAITING_LIST_COACH_AND_COURT = gql`
    mutation CreateMultipleWaitingListCoachAndCourt(
        $waitingListCoachPayload: [waiting_list_coach_insert_input!]!
        $waitingListCourtPayload: [waiting_list_court_insert_input!]!
    ) {
        createMultipleWaitingListCoach: insert_waiting_list_coach(
            objects: $waitingListCoachPayload
        ) {
            affected_rows
        }
        createMultipleWaitingListCourt: insert_waiting_list_court(
            objects: $waitingListCourtPayload
        ) {
            affected_rows
        }
    }
`;
