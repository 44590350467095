import { gql } from '@apollo/client';

export type CoachUpdateResponseDataT = {
    updateCoach: {
        id: string;
    };
} | null | undefined;

type CoachUpdateMutationPayloadT = {
    category_id: string | null;
    service_app_id: string;
    color: string;
    active: boolean;
    work_days: Array<{
        day: number;
        start: string;
        end: string;
    }>;
};

export type CoachUpdateVarT = {
    id: string;
    set: CoachUpdateMutationPayloadT;
}

export const COACH_UPDATE = gql`
    mutation updateCoach($set: coach_set_input = {}, $id: String = "") {
        updateCoach: update_coach_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
