import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    PRICES_BY_COACH_CATEGORIES_QUERY,
    PricesByCoachCategoriesResponseT,
} from 'shared/graphql/query/PricesByCoachCategory/PricesByCoachCategoryQuery';
import { useStore } from 'store';
import { FetchDataParams } from 'types/DataGrid';
import { FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT } from 'types/Graphql';
import { EMPTY_PRICES_BY_COACH_CATEGORIES_RESULT } from 'types/PricesByCoachCategory';

import { PricesByCoachCategoriesDTO } from '../DTO/PricesByCoachCategoriesDTO';

import { PricesByCoachCategoriesDataT } from './PricesByCoachCategoriesData';

const usePricesByCoachCategories = (): PricesByCoachCategoriesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PricesByCoachCategoriesResponseT, FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT>(PRICES_BY_COACH_CATEGORIES_QUERY, {
        fetchPolicy: 'network-only',
    });

    const { serviceApp } = useStore('serviceApp');

    const decodedPricesByCoachCategories = decodeDTO(
        PricesByCoachCategoriesDTO(serviceApp.fiatCurrencyTitle),
        EMPTY_PRICES_BY_COACH_CATEGORIES_RESULT,
        (pricesByCoachCategoriesData) => pricesByCoachCategoriesData,
        {
            pricesByCoachCategories: data?.coachCategories,
            pricesByCoachCategoriesCount: data?.coachCategoriesCount,
        },
    );

    const fetchPricesByCoachCategories = async (priceTypeId: string, priceSeasonId: string, {
        limit, offset, orderBy = { name: 'asc' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                    priceTypeId,
                    priceSeasonId,
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchPricesByCoachCategories,
        data: decodedPricesByCoachCategories.pricesByCoachCategories,
        count: decodedPricesByCoachCategories.pricesByCoachCategoriesCount,
        loading,
    };
};

export default usePricesByCoachCategories;
