import { PriceT } from './Price';

export type PricesByGroupViewT = {
    id: string;
    name: string;
    prices: PriceT[];
};

export type PricesByGroupViewsT = {
    pricesByGroupViews: PricesByGroupViewT[];
    pricesByGroupViewsCount: number;
};

export const EMPTY_PRICES_BY_GROUP_VIEWS_RESULT: PricesByGroupViewsT = {
    pricesByGroupViews: [],
    pricesByGroupViewsCount: 0,
};
