import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ServiceAppDocumentT } from 'types/ServiceAppDocument';

const DTO = t.type({
    id: t.string,
    title: t.string,
    url: t.string,
    is_active: t.boolean,
    position: t.number,
    service_app: t.type({
        id: t.string,
        label: t.string,
    }),
});

export type ServiceAppDocumentDTOT = t.TypeOf<typeof DTO>;

export const ServiceAppDocumentDTO = tPromise.extendDecoder<ServiceAppDocumentDTOT, ServiceAppDocumentT>(
    DTO,
    ({
        id, service_app, title, url, is_active, position,
    }) => ({
        id,
        title,
        url,
        position,
        serviceApp: { id: service_app.id, label: service_app.label },
        isActive: is_active,
    }),
) as t.Type<ServiceAppDocumentT, ServiceAppDocumentDTOT>;
