import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { ImplementationDocumentT } from 'types/ImplementationDocument';
import { OptionT } from 'types/Option';

export type ImplementationDocumentsResponseT = {
    implementationDocuments: ImplementationDocumentT[];
    implementationDocumentsCount: AggregateT;
};

export type ImplementationDocumentFilterOptionsResponseT = {
    implementationDocuments: OptionT[];
};

export const IMPLEMENTATION_DOCUMENT_FILTER_OPTIONS_QUERY = gql`
    query ImplementationDocuments(
        $limit: Int
        $offset: Int
        $orderBy: implementation_document_order_by! = {}
        $where: implementation_document_bool_exp! = {}
    ) {
        implementationDocuments: implementation_document(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            value: id
            label: number
        }
    }
`;

export const IMPLEMENTATION_DOCUMENTS_QUERY = gql`
    query ImplementationDocumentsQuery(
        $limit: Int
        $offset: Int
        $orderBy: implementation_document_order_by! = {}
        $where: implementation_document_bool_exp! = {}
    ) {
        implementationDocuments: implementation_document(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            number
            datetime
            client_datum {
                client {
                    first_name
                    last_name
                    middle_name
                }
            }
            service_category {
                name
            }
            type
            group {
                name
            }
            groupMainCoach {
                employee {
                    full_name
                }
            }
            comment
        }
        implementationDocumentsCount: implementation_document_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
