import {
    ERROR_MEMBER_CARD_TYPE_DUPLICATE,
    SUCCESS_MEMBER_CARD_TYPE_DUPLICATE,
} from 'constants/notificationMessage';

import useMemberCardTypeDuplicate from 'shared/data-hook/memberCardType/duplicate/useMemberCardTypeDuplicate';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const useDuplicateMemberCardType = () => {
    const { showError, showSuccess } = useNotification();
    const { duplicateMemberCardType } = useMemberCardTypeDuplicate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const memberCardTypeDuplicate = async (id: string) => {
        const { status, reason } = await duplicateMemberCardType(id);

        if (status === 'error') {
            showError(reason || ERROR_MEMBER_CARD_TYPE_DUPLICATE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_MEMBER_CARD_TYPE_DUPLICATE);
        }
    };

    return {
        memberCardTypeDuplicate,
    };
};

export default useDuplicateMemberCardType;
