import * as t from 'io-ts';
import { EmployeeFormFields } from 'page/Employee/components/formData';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { extractDigits } from 'shared/utils/digitsFromString';

export const DTO = t.type({
    full_name: t.string,
    email: Nullable(t.string),
    phone: Nullable(t.string),
    type: t.string,
});

export type EmployeeDTOT = t.TypeOf<typeof DTO>;

export const CreateEmployeeDTO = ({
    fullName,
    phone,
    email,
    type,
}: EmployeeFormFields): EmployeeDTOT => ({
    full_name: fullName,
    email: email || null,
    phone: phone ? `+${extractDigits(phone)}` : null,
    type,
});
