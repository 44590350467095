import { MODAL_TITLE_CREATE_GROUP_CATEGORY } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateGroupCategory from 'shared/hook/groupCategory/create/useCreateGroupCategory';
import { useStore } from 'store';

import { getGroupCategoryValidationSchema, GROUP_CATEGORY_DEFAULT_VALUES, GroupCategoryFormFields } from './formData';
import GroupCategoryForm from './GroupCategoryForm';

const CreateGroupCategoryModal: FC = observer(() => {
    const {
        isOpen, onClose,
    } = useStore('createGroupCategoryModal');
    const { createGroupCategory, loading: loadingSave } = useCreateGroupCategory();
    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<GroupCategoryFormFields>({
        defaultValues: GROUP_CATEGORY_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getGroupCategoryValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateGroupCategory = (data: GroupCategoryFormFields) => {
        createGroupCategory(data).then(handleCloseModal);
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_GROUP_CATEGORY }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <GroupCategoryForm
                    control={ control }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateGroupCategory) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateGroupCategoryModal;
