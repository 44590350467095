import { gql } from '@apollo/client';
import { EmployeeT } from 'types/Employee';

export type EmployeeDeleteResponseDataT = {
    deleteEmployee: {
        id: EmployeeT['id'];
    };
} | null | undefined;

export type EmployeeDeleteVarT = {
    id: EmployeeT['id'];
};

export const EMPLOYEE_DELETE = gql`
    mutation DeleteEmployee($id: String = "") {
        deleteEmployee: delete_employee_by_pk(id: $id) {
            id
        }
    }
`;
