import React, { FC, useState } from 'react';
import {
    MenuItem, Select,
} from '@mui/material';
import { parseSelectOptions } from 'shared/helper/common/parseSelectOptions';
import { useStore } from 'store';

export const SeasonSelect: FC = () => {
    const {
        priceSeasons, activePriceSeason, setActivePriceSeason,
    } = useStore('priceMemberCards');
    const [selectedPriceSeason, setSelectedPriceSeason] = useState(activePriceSeason.id || '');
    const priceSeasonOptions = parseSelectOptions(priceSeasons);

    return (
        <Select
            size="small"
            label="Сезон"
            labelId="season-label"
            value={ selectedPriceSeason }
            onChange={ (e) => {
                const selectedItem = priceSeasons.find((item) => item.id === e.target.value);

                if (selectedItem) {
                    setSelectedPriceSeason(selectedItem.id);
                    setActivePriceSeason({ id: selectedItem.id, title: selectedItem.title });
                }
            } }
        >
            { priceSeasonOptions.map((item) => (
                <MenuItem
                    key={ item.value }
                    value={ item.value }
                >
                    { item.label }
                </MenuItem>
            )) }
        </Select>
    );
};
