import { action, makeObservable, observable } from 'mobx';
import { GroupViewsGrouping, GroupViewsGroupingT } from 'types/GroupView';

import BaseModalStore from './BaseModalStore';

export default class SelectGroupViewsGroupingModal extends BaseModalStore {
    public groupViewsGrouping: GroupViewsGroupingT = GroupViewsGrouping.Empty;

    public setGroupViewsGrouping(groupViewsGrouping: GroupViewsGroupingT) {
        this.groupViewsGrouping = groupViewsGrouping;
    }

    constructor() {
        super();

        makeObservable(this, {
            groupViewsGrouping: observable,
            setGroupViewsGrouping: action.bound,
        });
    }
}
