import { GroupViewsGrouping, GroupViewsGroupingT } from 'types/GroupView';
import { object, string } from 'yup';

export type SelectGroupViewsGroupingFormFields = {
    grouping: GroupViewsGroupingT;
};

export enum SELECT_GROUP_VIEWS_GROUPING_FIELD_KEYS {
    grouping = 'grouping',
}

export const SELECT_GROUP_VIEWS_GROUPING_DEFAULT_VALUES: SelectGroupViewsGroupingFormFields = {
    grouping: GroupViewsGrouping.Empty,
};

export const SELECT_GROUP_VIEWS_GROUPING_VALIDATION_SCHEMA = object().shape({
    grouping: string(),
}).required();
