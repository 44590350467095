import { PHPSESSID } from 'constants/cookie';
import { USER_ITEM } from 'constants/localStorage';
import { PATH_LOGIN } from 'constants/routes';

import clearCookie from 'shared/helper/common/clearCookie';

const logoutAction = () => {
    localStorage.removeItem(USER_ITEM);
    clearCookie(PHPSESSID);
    window.location.pathname = PATH_LOGIN;
};

export default logoutAction;
