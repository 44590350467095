import { gql } from '@apollo/client';

export type CreateEmployeePayloadT = {
    full_name: string;
    email: string | null;
    phone: string | null;
    type: string;
};

export type CreateEmployeeResponseT = {
    status: string;
    reason: null | string;
};

export type CreateEmployeeVarT = {
    object: CreateEmployeePayloadT;
};

export type CreateEmployeeT = {
    adminEmployeeCreate: CreateEmployeeResponseT;
};

export const EMPTY_CREATE_EMPLOYEE_RESPONSE: CreateEmployeeResponseT = {
    status: 'error',
    reason: '',
};

export const CREATE_EMPLOYEE = gql`
    mutation EmployeeCreate($object: employee_insert_input = {}) {
        createEmployee: insert_employee_one(object: $object) {
            id
        }
    }
`;
