import { useEffect } from 'react';
import useWaitingList from 'shared/data-hook/waitingList/fetch/fetchOne/useWaitingList';
import { useStore } from 'store';

const useFetchWaitingList = (id: string) => {
    const { fetchWaitingList, loading } = useWaitingList();
    const { setWaitingList } = useStore('updateWaitingListModal');

    useEffect(() => {
        fetchWaitingList(id).then((fetchedWaitingList) => {
            setWaitingList(fetchedWaitingList);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchWaitingList;
