import { useMutation } from '@apollo/client';
import { WaitingListFormFields } from 'page/WaitingList/components/formData';
import {
    WAITING_LIST_UPDATE,
    WaitingListUpdateResponseDataT,
    WaitingListUpdateVar,
} from 'shared/graphql/mutation/waitingList/WaitingListUpdate';
import {
    CREATE_MULTIPLE_WAITING_LIST_COACH_AND_COURT,
    CreateMultipleWaitingListCoachResponseT,
    CreateMultipleWaitingListCoachVarT,
    WaitingListCoachCreatePayloadT,
    WaitingListCourtCreatePayloadT,
} from 'shared/graphql/mutation/waitingListCoachAndCourt/WaitingListCoachAndCourtMultipleCreate';
import {
    DELETE_MULTIPLE_WAITING_LIST_COACH_AND_COURT,
    DeleteMultipleWaitingListCoachAndCourtResponseT, DeleteMultipleWaitingListCoachAndCourtVarT,
} from 'shared/graphql/mutation/waitingListCoachAndCourt/WaitingListCoachAndCourtMultipleDelete';
import { WaitingListT } from 'types/WaitingList';

import { CreateWaitingListDTO } from '../create/DTO/CreateWaitingListDTO';

import { WaitingListUpdateDataT } from './WaitingListUpdateData';

const useWaitingListUpdate = (): WaitingListUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        WaitingListUpdateResponseDataT,
        WaitingListUpdateVar
    >(WAITING_LIST_UPDATE);
    const [waitingListCoachAndCourtsMultipleDelete] = useMutation<
        DeleteMultipleWaitingListCoachAndCourtResponseT,
        DeleteMultipleWaitingListCoachAndCourtVarT
    >(DELETE_MULTIPLE_WAITING_LIST_COACH_AND_COURT);
    const [waitingListCoachMultipleCreate] = useMutation<
        CreateMultipleWaitingListCoachResponseT,
        CreateMultipleWaitingListCoachVarT
    >(CREATE_MULTIPLE_WAITING_LIST_COACH_AND_COURT);

    const waitingListUpdate = async (
        id: WaitingListT['id'],
        input: WaitingListFormFields,
    ) => {
        if (!id) {
            // TODO: use Sentry
            throw new Error('WaitingList is not found');
        }

        try {
            const { coaches: coachIds, courts: courtIds, ...restData } = input;
            const coaches: WaitingListCoachCreatePayloadT[] = coachIds?.map((item) => ({ waiting_list_id: id, coach_id: item })) || [];
            const courts: WaitingListCourtCreatePayloadT[] = courtIds?.map((item) => ({ waiting_list_id: id, court_id: item })) || [];

            const { data } = await update({
                variables: {
                    id,
                    set: CreateWaitingListDTO.encode(restData),
                },
            });

            await waitingListCoachAndCourtsMultipleDelete({
                variables: {
                    id,
                },
            });

            await waitingListCoachMultipleCreate({
                variables: {
                    waitingListCoachPayload: coaches,
                    waitingListCourtPayload: courts,
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        waitingListUpdate,
        loading,
        error,
    };
};

export default useWaitingListUpdate;
