export type SearchT = {
    name: string;
    showAllCategories: boolean;
}

export const getGroupCategoriesFilter = ({ name, showAllCategories }: SearchT) => ({
    ...(name && {
        group_views: {
            name: {
                _iregex: name.replaceAll(' ', '.*'),
            },
        },
    }),
    ...(!showAllCategories && {
        group_views_aggregate: {
            count: {
                predicate: { _neq: 0 },
            },
        },
    }),
});
