import { useEffect } from 'react';
import {
    Control, ControllerRenderProps, useController, UseFormSetValue,
} from 'react-hook-form';
import getFullName from 'shared/helper/string/getFullName';
import { useStore } from 'store';
import { FilterOptionT } from 'types/DataGrid';
import { WaitingListT } from 'types/WaitingList';

import { WAITING_LIST_FIELDS_KEYS, WaitingListFormFields } from './formData';

type ReturnT = {
    groupOptions: FilterOptionT[];
    organizationOptions: FilterOptionT[];
    serviceCategoryOptions: FilterOptionT[];
    coachOptions: FilterOptionT[];
    courtOptions: FilterOptionT[];
    priceTypeOptions: FilterOptionT[];
    coaches: ControllerRenderProps<any, WAITING_LIST_FIELDS_KEYS.coaches>;
    courts: ControllerRenderProps<any, WAITING_LIST_FIELDS_KEYS.courts>;
}
const useSetWaitingListForm = (
    control: Control<any>,
    isUpdate?: boolean,
    waitingList?: WaitingListT,
    setValue?: UseFormSetValue<WaitingListFormFields>,
): ReturnT => {
    const { getFilterOption } = useStore('filterOptions');
    const groupOptions = getFilterOption('group');
    const organizationOptions = getFilterOption('organization');
    const serviceCategoryOptions = getFilterOption('serviceCategory');
    const coachOptions = getFilterOption('employee');
    const courtOptions = getFilterOption('court');
    const priceTypeOptions = getFilterOption('priceType');
    const { field: coachesField } = useController({ control, name: WAITING_LIST_FIELDS_KEYS.coaches });
    const { field: courtsField } = useController({ control, name: WAITING_LIST_FIELDS_KEYS.courts });

    useEffect(() => {
        if (!isUpdate || !waitingList || !setValue) {
            return;
        }

        const {
            coaches, courts, client, user, groupDocument,
        } = waitingList;

        Object.keys(waitingList).forEach((key) => {
            switch (key) {
                case 'coaches':
                    setValue('coaches', coaches.map((item) => item.value));
                    break;

                case 'courts':
                    setValue('courts', courts.map((item) => item.value));
                    break;

                case 'client':
                    if (client) {
                        setValue('client', {
                            value: client.id || '',
                            label: getFullName(client),
                        });
                    }
                    break;

                case 'user':
                    if (user) {
                        setValue('user', {
                            value: user.value || '',
                            label: user.label,
                        });
                    }
                    break;

                case 'groupDocument':
                    if (groupDocument) {
                        setValue('groupDocument', {
                            value: groupDocument.value || '',
                            label: groupDocument.label,
                        });
                    }
                    break;
                case 'serviceCategory':
                case 'group':
                case 'organization':
                case 'priceType':
                case 'coachPriceType':
                    setValue?.(key as keyof WaitingListFormFields, waitingList[key]?.value);
                    break;
                default:
                    setValue?.(
                        key as keyof WaitingListFormFields,
                        waitingList[key as keyof WaitingListFormFields as never],
                    );
                    break;
            }
        });
    }, [waitingList, isUpdate]);

    return {
        groupOptions,
        organizationOptions,
        serviceCategoryOptions,
        coachOptions,
        courtOptions,
        priceTypeOptions,
        coaches: coachesField,
        courts: courtsField,
    };
};

export default useSetWaitingListForm;
