import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { CoachesT } from 'types/Coach';

import { CoachDTO } from './CoachDTO';

const DTO = t.type({
    coaches: t.array(CoachDTO),
    coachesCount: AggregateDTO,
});

type CoachesDTOT = t.TypeOf<typeof DTO>;

export const CoachesDTO = tPromise.extendDecoder<CoachesDTOT, CoachesT>(
    DTO,
    ({
        coaches,
        coachesCount,
    }) => ({
        coaches,
        coachesCount,
    }),
) as t.Type<CoachesT, CoachesDTOT>;
