import { MODAL_TITLE_CREATE_WAITING_LIST } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateWaitingList from 'shared/hook/waitingList/create/useCreateWaitingList';
import { useStore } from 'store';

import { getWaitingListValidationSchema, WAITING_LIST_DEFAULT_VALUES, WaitingListFormFields } from './formData';
import WaitingListForm from './WaitingListForm';

const CreateWaitingListModal: FC = observer(() => {
    const { isOpen, onClose } = useStore('createWaitingListModal');
    const { createWaitingList, loading: loadingSave } = useCreateWaitingList();
    const {
        control, handleSubmit, reset, setValue, formState: { isDirty, isValid },
    } = useForm<WaitingListFormFields>({
        defaultValues: WAITING_LIST_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getWaitingListValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateWaitingList = (data: WaitingListFormFields) => {
        createWaitingList(data).then(() => {
            handleCloseModal();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_WAITING_LIST }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <WaitingListForm
                    control={ control }
                    setValue={ setValue }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateWaitingList) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateWaitingListModal;
