import {
    PATH_AGE_GROUPS,
    PATH_BULK_OPERATIONS,
    PATH_CLIENT, PATH_COACH_HOURS,
    PATH_COACH_SETTINGS,
    PATH_COACHES_IN_GROUPS,
    PATH_COURT,
    PATH_COURT_CATEGORY,
    PATH_COURT_VIDEO_ANALYTICS,
    PATH_DOCUMENTS, PATH_EMPLOYEE,
    PATH_GROUP,
    PATH_IMPLEMENTATION,
    PATH_IMPLEMENTATION_DOCUMENTS,
    PATH_IMPORT_PRICE, PATH_INTERNET_ACQUIRING,
    PATH_LOGOUT,
    PATH_MEMBER_CARD,
    PATH_MEMBER_CARD_TYPE,
    PATH_ORGANIZATION,
    PATH_ORGANIZATION_LOCATION,
    PATH_PAYMENT,
    PATH_PAYMENT_SETTINGS,
    PATH_PRICE_MEMBER_CARDS,
    PATH_PRICE_SEASON,
    PATH_PRICE_TYPE,
    PATH_PRT, PATH_SCHEDULE_SLOT_RESERVE,
    PATH_SERVICE_APP,
    PATH_SERVICE_CATEGORY,
    PATH_TASK_STATUS,
    PATH_TASK_TYPE,
    PATH_WAITING_LIST,
} from 'constants/routes';

import React, { FC } from 'react';
import {
    Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import { Toolbar } from '@mui/material';
import Age from 'page/Age';
import BulkOperations from 'page/BulkOperations';
import Client from 'page/Client';
import Coach from 'page/CoachCategory';
import CoachesInGroups from 'page/CoachesInGroups';
import CoachHours from 'page/CoachHours';
import Court from 'page/Court';
import CourtCategory from 'page/CourtCategory';
import CourtVideoAnalytics from 'page/CourtVideoAnalytics';
import Employee from 'page/Employee';
import GroupView from 'page/GroupCategory';
import Implementation from 'page/Implementation';
import ImplementationDocuments from 'page/ImplementationDocuments';
import ImportPrice from 'page/ImportPrice';
import InternetAcquiring from 'page/InternetAcquiring';
import Logout from 'page/Logout';
import MemberCard from 'page/MemberCard';
import MemberCardType from 'page/MemberCardType';
import Organization from 'page/Organization';
import OrganizationLocation from 'page/OrganizationLocation';
import Payment from 'page/Payment';
import PaymentSettings from 'page/PaymentSetting';
import PriceMemberCards from 'page/PriceMemberCard';
import Season from 'page/PriceSeason';
import PriceType from 'page/PriceType';
import Prt from 'page/Prt';
import ScheduleSlotReserve from 'page/ScheduleSlotReserve';
import ServiceApp from 'page/ServiceApp';
import Documents from 'page/ServiceAppDocuments';
import ServiceCategory from 'page/ServiceCategory';
import TaskStatus from 'page/TaskStatus';
import TaskType from 'page/TaskType';
import Drawer from 'shared/component/Drawer';
import { AuthGuard } from 'shared/component/Guard';
import Header from 'shared/component/Header';

import WaitingList from '../page/WaitingList';

import { Container, RoutesWrap } from './styles';

const ProtectedRoutes: FC = () => {
    const location = useLocation();
    const path = location.pathname === '/' ? PATH_PRICE_SEASON : `/${location.pathname.split('/')[1]}`;

    return (
        <AuthGuard>
            <Container>
                <Header />
                <Drawer path={ path } />
                <RoutesWrap component="main">
                    <Toolbar />
                    <Routes>
                        <Route path="/" element={ <Navigate to={ PATH_PRICE_SEASON } /> } />
                        <Route path={ PATH_LOGOUT } element={ <Logout /> } />
                        <Route path={ `${PATH_PRICE_TYPE}/*` } element={ <PriceType /> } />
                        <Route path={ `${PATH_PRICE_SEASON}/*` } element={ <Season /> } />
                        <Route path={ `${PATH_PRICE_MEMBER_CARDS}/*` } element={ <PriceMemberCards /> } />
                        <Route path={ `${PATH_SERVICE_CATEGORY}/*` } element={ <ServiceCategory /> } />
                        <Route path={ `${PATH_MEMBER_CARD}/*` } element={ <MemberCard /> } />
                        <Route path={ `${PATH_MEMBER_CARD_TYPE}/*` } element={ <MemberCardType /> } />
                        <Route path={ `${PATH_BULK_OPERATIONS}/*` } element={ <BulkOperations /> } />
                        <Route path={ `${PATH_SCHEDULE_SLOT_RESERVE}/*` } element={ <ScheduleSlotReserve /> } />
                        <Route path={ `${PATH_IMPLEMENTATION}/*` } element={ <Implementation /> } />
                        <Route path={ `${PATH_IMPLEMENTATION_DOCUMENTS}/*` } element={ <ImplementationDocuments /> } />
                        <Route path={ `${PATH_COURT}/*` } element={ <Court /> } />
                        <Route path={ `${PATH_COURT_CATEGORY}/*` } element={ <CourtCategory /> } />
                        <Route path={ `${PATH_COURT_VIDEO_ANALYTICS}/*` } element={ <CourtVideoAnalytics /> } />
                        <Route path={ `${PATH_ORGANIZATION}/*` } element={ <Organization /> } />
                        <Route path={ `${PATH_ORGANIZATION_LOCATION}/*` } element={ <OrganizationLocation /> } />
                        <Route path={ `${PATH_EMPLOYEE}/*` } element={ <Employee /> } />
                        <Route path={ `${PATH_COACH_SETTINGS}/*` } element={ <Coach /> } />
                        <Route path={ `${PATH_SERVICE_APP}/*` } element={ <ServiceApp /> } />
                        <Route path={ `${PATH_DOCUMENTS}/*` } element={ <Documents /> } />
                        <Route path={ `${PATH_CLIENT}/*` } element={ <Client /> } />
                        <Route path={ `${PATH_COACH_HOURS}/*` } element={ <CoachHours /> } />
                        <Route path={ `${PATH_COACHES_IN_GROUPS}/*` } element={ <CoachesInGroups /> } />
                        <Route path={ `${PATH_PAYMENT}/*` } element={ <Payment /> } />
                        <Route path={ `${PATH_PAYMENT_SETTINGS}/*` } element={ <PaymentSettings /> } />
                        <Route path={ `${PATH_GROUP}/*` } element={ <GroupView /> } />
                        <Route path={ `${PATH_AGE_GROUPS}/*` } element={ <Age /> } />
                        <Route path={ `${PATH_PRT}/*` } element={ <Prt /> } />
                        <Route path={ `${PATH_INTERNET_ACQUIRING}/*` } element={ <InternetAcquiring /> } />
                        <Route path={ `${PATH_MEMBER_CARD}/*` } element={ <MemberCard /> } />
                        <Route path={ `${PATH_TASK_STATUS}/*` } element={ <TaskStatus /> } />
                        <Route path={ `${PATH_TASK_TYPE}/*` } element={ <TaskType /> } />
                        <Route path={ `${PATH_IMPORT_PRICE}/*` } element={ <ImportPrice /> } />
                        <Route path={ `${PATH_WAITING_LIST}/*` } element={ <WaitingList /> } />
                    </Routes>
                </RoutesWrap>
            </Container>
        </AuthGuard>
    );
};

export default ProtectedRoutes;
