import { makeAutoObservable } from 'mobx';
import { EmployeeT, EMPTY_EMPLOYEE_RESULT } from 'types/Employee';

export default class EmployeeStore {
    public employees: EmployeeT[] = [];

    public employee: EmployeeT = EMPTY_EMPLOYEE_RESULT;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setEmployees(employees: EmployeeT[], count: number) {
        this.employees = employees;
        this.count = count;
    }

    public setEmployee(employee: EmployeeT) {
        this.employee = employee;
    }
}
