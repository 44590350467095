export type EmployeeT = {
    id: string;
    fullName: string;
    email?: string;
    phone?: string;
    type: string;
};

export type EmployeesT = {
    employees: EmployeeT[];
    employeesCount: number;
};

export const EMPTY_EMPLOYEE_RESULT: EmployeeT = {
    id: '',
    fullName: '',
    email: '',
    phone: '',
    type: '',
};

export const EMPTY_EMPLOYEES_RESULT: EmployeesT = {
    employees: [],
    employeesCount: 0,
};
