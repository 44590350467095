import {
    PROPERTY_EMAIL, PROPERTY_FULL_NAME, PROPERTY_PHONE_NUMBER,
    PROPERTY_TYPE,
} from 'constants/propertyNames';

import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';
import { object, Schema, string } from 'yup';

export type EmployeeFormFields = {
    fullName: string;
    phone?: string;
    email?: string;
    type: string;
};

export const EMPLOYEE_DEFAULT_VALUES: EmployeeFormFields = {
    fullName: '',
    phone: '',
    email: '',
    type: '',
};

export const EMPLOYEE_LABEL_DATA = {
    fullName: PROPERTY_FULL_NAME,
    phone: PROPERTY_PHONE_NUMBER,
    email: PROPERTY_EMAIL,
    type: PROPERTY_TYPE,
};

export enum EMPLOYEE_FIELD_KEYS {
    fullName = 'fullName',
    phone = 'phone',
    email = 'email',
    type = 'type',
}

export const getEmployeeValidationSchema = (): Schema =>
    object({
        fullName: string().required(),
        phone: string(),
        email: string().email(COMMON_FORM_ERRORS[FormErrorMessages.TypeError]),
        type: string().required(),
    });
