import { useMutation } from '@apollo/client';
import { EmployeeFormFields } from 'page/Employee/components/formData';
import {
    EMPLOYEE_UPDATE,
    EmployeeUpdateResponseDataT,
    EmployeeUpdateVar,
} from 'shared/graphql/mutation/employee/EmployeeUpdate';
import { EmployeeT } from 'types/Employee';

import { CreateEmployeeDTO } from '../create/DTO/CreateWaitingListDTO';

import { EmployeeUpdateDataT } from './EmployeeUpdateData';

const useEmployeeUpdate = (): EmployeeUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        EmployeeUpdateResponseDataT,
        EmployeeUpdateVar
    >(EMPLOYEE_UPDATE);

    const employeeUpdate = async (
        input: EmployeeFormFields,
        id: EmployeeT['id'],
    ) => {
        if (!id) {
            // TODO: use Sentry
            throw new Error('Employee is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id,
                    set: CreateEmployeeDTO(input),
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        employeeUpdate,
        loading,
        error,
    };
};

export default useEmployeeUpdate;
