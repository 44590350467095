import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { WaitingListT } from 'types/WaitingList';

export type WaitingListsResponseT = {
    waitingLists: WaitingListT[];
    waitingListsCount: AggregateT;
};

export type WaitingListResponseT = {
    waitingList: WaitingListT;
};

export const WAITING_LIST_QUERY = gql`
    query WaitingList($id: String!) {
        waitingList: waiting_list_by_pk(id: $id) {
            id
            client_datum {
                client {
                    id
                    first_name
                    last_name
                    middle_name
                }
            }
            group {
                id
                name
            }
            implementation_document {
                id
                number
            }
            organization {
                id
                name
            }
            service_category {
                id
                name
            }
            coach_price_type {
                id
                title
            }
            price_type {
                id
                title
            }
            user {
                id
                full_name
            }
            duration
            datetime
            top_priority
            comment
            created_at
            is_archive
            waiting_list_courts {
                court {
                    id
                    name
                }
            }
            waiting_list_coaches {
                coach {
                    employee {
                        id
                        full_name
                    }
                }
            }
        }
    }
`;

export const WAITING_LISTS_QUERY = gql`
    query WaitingLists(
        $limit: Int
        $offset: Int
        $orderBy: waiting_list_order_by! = {}
        $where: waiting_list_bool_exp! = {}
    ) {
        waitingLists: waiting_list(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            client_datum {
                client {
                    id
                    first_name
                    last_name
                    middle_name
                }
            }
            group {
                id
                name
            }
            implementation_document {
                id
                number
            }
            organization {
                id
                name
            }
            service_category {
                id
                name
            }
            coach_price_type {
                id
                title
            }
            price_type {
                id
                title
            }
            user {
                id
                full_name
            }
            duration
            datetime
            top_priority
            comment
            created_at
            is_archive
            waiting_list_courts {
                court {
                    id
                    name
                }
            }
            waiting_list_coaches {
                coach {
                    employee {
                        id
                        full_name
                    }
                }
            }
        }
        waitingListsCount: waiting_list_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
