import { gql } from '@apollo/client';
import { OptionT } from 'types/Option';

export type UserT = {
    activeClientId: string | null;
};

export type UserResponseT = {
    user: UserT;
};

export type UserFilterOptionsResponseT = {
    users: OptionT[];
};

export const USER_FILTER_OPTIONS_QUERY = gql`
    query Users(
        $limit: Int
        $offset: Int
        $orderBy: user_order_by! = {}
        $where: user_bool_exp! = {}
    ) {
        users: user(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: {
                _and: [
                    $where,
                    { full_name: { _is_null: false } }
                ]
            }
        ) {
            value: id
            label: full_name
        }
    }
`;

export const USER_QUERY = gql`
    query User($id: String!) {
        user: user_by_pk(id: $id) {
            active_client_id
        }
    }
`;
