import { GROUP_VIEWS_GROUPING_OPTIONS } from 'constants/GroupViewsGroupingOptions';
import { MODAL_TITLE_SELECT_GROUPING } from 'constants/modalTitles';
import { PROPERTY_GROUPING } from 'constants/propertyNames';

import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { useStore } from 'store';

import {
    SELECT_GROUP_VIEWS_GROUPING_DEFAULT_VALUES, SELECT_GROUP_VIEWS_GROUPING_FIELD_KEYS,
    SELECT_GROUP_VIEWS_GROUPING_VALIDATION_SCHEMA,
    SelectGroupViewsGroupingFormFields,
} from './formData';

const SelectGroupViewsGroupingModal: FC = observer(() => {
    const {
        isOpen, onClose, groupViewsGrouping, setGroupViewsGrouping,
    } = useStore('selectGroupViewsGroupingModal');
    const [loading, setLoading] = useState(false);
    const {
        control, handleSubmit, reset, formState: { isValid }, setValue,
    } = useForm<SelectGroupViewsGroupingFormFields>({
        defaultValues: SELECT_GROUP_VIEWS_GROUPING_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(SELECT_GROUP_VIEWS_GROUPING_VALIDATION_SCHEMA),
    });

    useEffect(() => {
        if (isOpen) {
            setValue(SELECT_GROUP_VIEWS_GROUPING_FIELD_KEYS.grouping, groupViewsGrouping);
        }
    }, [groupViewsGrouping, isOpen]);

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleSelectPriceGrouping = (data: SelectGroupViewsGroupingFormFields) => {
        setLoading(true);
        setGroupViewsGrouping(data.grouping);

        setLoading(false);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>{ MODAL_TITLE_SELECT_GROUPING }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 spacing={ 2 } container padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <Select
                            name={ SELECT_GROUP_VIEWS_GROUPING_FIELD_KEYS.grouping }
                            label={ PROPERTY_GROUPING }
                            control={ control }
                            options={ GROUP_VIEWS_GROUPING_OPTIONS }
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loading }
                    onClick={ handleSubmit(handleSelectPriceGrouping) }
                    disabled={ !isValid }
                >
                    Подтвердить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default SelectGroupViewsGroupingModal;
