import { PROPERTY_WITHOUT_CATEGORY } from 'constants/propertyNames';

import { CoachCategoryT } from 'types/CoachCategory';
import { v4 } from 'uuid';

export const ROW_WITHOUT_CATEGORY: CoachCategoryT = {
    id: v4(),
    name: PROPERTY_WITHOUT_CATEGORY,
    basicRate: 0,
    coaches: [],
};
