import { gql } from '@apollo/client';

export type DuplicateMemberCardTypeResponseT = {
    adminMemberCardTypeDuplicate: DuplicateMemberCardTypeResponseDataT;
};

export type DuplicateMemberCardTypeResponseDataT = {
    reason: string;
    status: string;
}

export type DuplicateMemberCardTypeVarT = {
    appId: string;
    payload: {
        member_card_type_id: string;
    };
};

export const EMPTY_DUPLICATE_RESULT_DATA: DuplicateMemberCardTypeResponseDataT = {
    reason: '',
    status: 'error',
};

export const DUPLICATE_MEMBER_CARD_TYPE = gql`
    mutation DuplicateMemberCardTypeMutation($appId: String!, $payload: json!) {
        adminMemberCardTypeDuplicate(
            app_id: $appId
            payload: $payload
        ) {
            status
            reason
        }
    }
`;
