import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { COACHES_QUERY, CoachesResponseT } from 'shared/graphql/query/coach/CoachQuery';
import { EMPTY_COACHES_RESULT } from 'types/Coach';
import { FetchDataParams } from 'types/DataGrid';

import { CoachesDTO } from '../DTO/CoachesDTO';
import { getCoachesWithoutCategoryFilter, SearchT } from '../filter/getCoachesWithoutCategoryFilter';

import { CoachDataT } from './CoachesData';

const useCoaches = (): CoachDataT => {
    const [fetch, { data, loading }] = useLazyQuery<CoachesResponseT>(
        COACHES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedCoachesData = decodeDTO(
        CoachesDTO,
        EMPTY_COACHES_RESULT,
        (coaches) => coaches,
        {
            coaches: data?.coaches,
            coachesCount: data?.coachesCount,
        },
    );

    const fetchCoaches = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
        search = { without: false },
    }: FetchDataParams<SearchT> = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: {
                        ...filterData(where),
                        ...getCoachesWithoutCategoryFilter(search),
                    },
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedCoachesData.coaches,
        count: decodedCoachesData.coachesCount,
        fetchCoaches,
        loading,
    };
};

export default useCoaches;
