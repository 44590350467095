type ParamsT = {
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
};

const getFullName = (params?: ParamsT): string => {
    const { firstName, middleName, lastName } = params ?? {};
    const last = lastName ?? '';
    const first = firstName ? ` ${firstName}` : '';
    const middle = middleName ? ` ${middleName}` : '';

    return `${last}${first}${middle}`;
};

export default getFullName;
