import {
    PROPERTY_CASHBACK_PERCENT_BONUS, PROPERTY_CASHBACK_PERCENT_FIAT,
    PROPERTY_DISCOUNT_PERCENT_BONUS, PROPERTY_DISCOUNT_PERCENT_BONUS_PER_FREE_SLOT,
    PROPERTY_DISCOUNT_PERCENT_FIAT,
    PROPERTY_MULTIPLYING_FACTOR,
    PROPERTY_PRICE,
} from 'constants/propertyNames';

import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';
import { array, object } from 'yup';
import * as yup from 'yup';

const MIN_VALUE = 0;
const MAX_VALUE = 100;
const MIN_VALUE_MESSAGE = 'Значение не должно быть отрицательным';
const MAX_VALUE_MESSAGE = 'Значение должно быть не больше 100';

export type MemberCardTypeDiscountFullReturnsT = {
    enabledAtMonths: string[];
    fullReturnQuantityLimit: number;
    memberCardTypeDiscountFullReturnPriceSalesRounds: string[];
    isPerformerIncomeRecalculating: boolean;
};

export type FieldT = {
    priceSalesRound: {
        title: string;
        position: number;
    };
    priceSalesRoundId: string;
    discountPercentFiat: number;
    discountPercentBonus: number;
    multiplyingFactor: number;
    discountPercentBonusPerFreeSlot: number;
    cashbackPercentBonus: number;
    cashbackPercentFiat: number;
}

export type PriceMemberCardFormFields = {
    price: number;
    memberCardTypeDiscountRates: FieldT[];
    memberCardTypeDiscountFullReturns: MemberCardTypeDiscountFullReturnsT[];
};

export const MEMBER_CARD_TYPE_DISCOUNT_RATES_DEFAULT_VALUES = {
    priceSalesRound: {
        title: '',
        position: 0,
    },
    priceSalesRoundId: '',
    discountPercentFiat: 0,
    discountPercentBonus: 0,
    multiplyingFactor: 0,
    discountPercentBonusPerFreeSlot: 0,
    cashbackPercentBonus: 0,
    cashbackPercentFiat: 0,
};

export const MEMBER_CARD_TYPE_DISCOUNT_FULL_RETURN_DEFAULT_VALUES: MemberCardTypeDiscountFullReturnsT = {
    enabledAtMonths: [],
    fullReturnQuantityLimit: 0,
    memberCardTypeDiscountFullReturnPriceSalesRounds: [],
    isPerformerIncomeRecalculating: false,
};

export const PRICE_MEMBER_CARD_DEFAULT_VALUES: PriceMemberCardFormFields = {
    price: 0,
    memberCardTypeDiscountRates: [],
    memberCardTypeDiscountFullReturns: [],
};

export const PRICE_MEMBER_CARD_LABEL_DATA = {
    price: PROPERTY_PRICE,
};

export const MEMBER_CARD_DISCOUNT_RATES_LABELS = {
    discountPercentFiat: PROPERTY_DISCOUNT_PERCENT_FIAT,
    discountPercentBonus: PROPERTY_DISCOUNT_PERCENT_BONUS,
    cashbackPercentFiat: PROPERTY_CASHBACK_PERCENT_FIAT,
    cashbackPercentBonus: PROPERTY_CASHBACK_PERCENT_BONUS,
    discountPercentBonusPerFreeSlot: PROPERTY_DISCOUNT_PERCENT_BONUS_PER_FREE_SLOT,
    multiplyingFactor: PROPERTY_MULTIPLYING_FACTOR,
};

export const PARAMS_MEMBER_CARD_DISCOUNT_RATES = Object.keys(MEMBER_CARD_DISCOUNT_RATES_LABELS);

export const getPriceMemberCardTypeValidationSchema = () => (
    object().shape({
        price: yup.number().required(),
        memberCardTypeDiscountRates: array().of(object().shape({
            discountPercentFiat: yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required]).required(COMMON_FORM_ERRORS[FormErrorMessages.Required])
                .min(MIN_VALUE, MIN_VALUE_MESSAGE)
                .max(MAX_VALUE, MAX_VALUE_MESSAGE),
            discountPercentBonus: yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required]).required(COMMON_FORM_ERRORS[FormErrorMessages.Required])
                .min(MIN_VALUE, MIN_VALUE_MESSAGE)
                .max(MAX_VALUE, MAX_VALUE_MESSAGE),
            cashbackPercentBonus: yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required]).required(COMMON_FORM_ERRORS[FormErrorMessages.Required])
                .min(MIN_VALUE, MIN_VALUE_MESSAGE)
                .max(MAX_VALUE, MAX_VALUE_MESSAGE),
            discountPercentBonusPerFreeSlot: yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required]).required(COMMON_FORM_ERRORS[FormErrorMessages.Required])
                .min(MIN_VALUE, MIN_VALUE_MESSAGE)
                .max(MAX_VALUE, MAX_VALUE_MESSAGE),
            multiplyingFactor: yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required]).required(COMMON_FORM_ERRORS[FormErrorMessages.Required])
                .min(MIN_VALUE, MIN_VALUE_MESSAGE)
                .max(MAX_VALUE, MAX_VALUE_MESSAGE),
            cashbackPercentFiat: yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required]).required(COMMON_FORM_ERRORS[FormErrorMessages.Required])
                .min(MIN_VALUE, MIN_VALUE_MESSAGE)
                .max(MAX_VALUE, MAX_VALUE_MESSAGE),
        })),
        memberCardTypeDiscountFullReturns: array().of(object().shape({
            enabledAtMonths: array().min(1).of(yup.string().required()),
            fullReturnQuantityLimit: yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required]).required(COMMON_FORM_ERRORS[FormErrorMessages.Required])
                .min(MIN_VALUE, MIN_VALUE_MESSAGE)
                .max(MAX_VALUE, MAX_VALUE_MESSAGE),
            memberCardTypeDiscountFullReturnPriceSalesRounds: array().min(1).of(yup.string().required()),
        })),
    }).required()
);
