import { gql } from '@apollo/client';
import { PaymentDocumentT } from 'types/PaymentDocument';

export type PaymentDocumentArchivePayloadT = {
    paymentDocumentId: PaymentDocumentT['id'];
};

export type PaymentDocumentArchivePayloadVarT = {
    payment_document_id: PaymentDocumentT['id'];
};

export type BaseResponseT = {
    status: 'ok' | 'error';
};

export type PaymentDocumentArchiveResponseDataT = {
    baseResponse: BaseResponseT[];
} | null | undefined;

export type PaymentDocumentArchiveVarT = {
    appId: string;
    payload: PaymentDocumentArchivePayloadVarT;
}

export const PAYMENT_DOCUMENT_ARCHIVE = gql`
    mutation archivePaymentDocument (
        $appId: String!, $payload: json!
    ) {
        baseResponse: adminPaymentDocumentArchive(app_id: $appId, payload: [$payload]) {
            status
            reason
        }
    }
`;
