import { EMPLOYEE_TYPES_OPTIONS } from 'constants/employeeTypes';
import {
    PROPERTY_ACTIONS, PROPERTY_EMAIL, PROPERTY_FULL_NAME, PROPERTY_PHONE_NUMBER, PROPERTY_TYPE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteEmployee from 'shared/hook/employee/delete/useDeleteEmployee';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

import EmployeeEditButton from '../components/EmployeeEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { id, title },
    } = props;
    const { employeeDelete } = useDeleteEmployee();

    return (
        <>
            <EmployeeEditButton id={ id } />
            <DeleteButton id={ id } title={ title } actionDelete={ employeeDelete } />
        </>
    );
};

export const EMPLOYEE_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_FULL_NAME,
        field: 'fullName',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_PHONE_NUMBER,
        field: 'phone',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<string | null>) => renderStringCell(params.row?.phone),
    },
    {
        headerName: PROPERTY_EMAIL,
        field: 'email',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<string | null>) => renderStringCell(params.row?.email),
    },
    {
        headerName: PROPERTY_TYPE,
        field: 'type',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: EMPLOYEE_TYPES_OPTIONS,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
