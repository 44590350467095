import { DATA_GRID_PAGE_SIZES_EXTEND, PRICE_DATA_GRID_INITIAL_STATE } from 'constants/dataGrid';

import React, {
    FC, memo,
} from 'react';
import {
    GridColumnGroupingModel,
    GridColumns, GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';
import { observer } from 'mobx-react';
import { useGroupedPriceData } from 'page/Price/components/useGroupedPriceData';
import DataGrid from 'shared/component/DataGrid';
import { DataGridPropsT } from 'types/DataGrid';

type PropsT = {
    columns: GridColumns;
    loading: boolean;
    columnGroupingModel: GridColumnGroupingModel;
    multipleDeleteAction?: Function;
    multipleRefreshAction: Function;
    exportData: () => void;
    columnVisibilityModel: GridColumnVisibilityModel;
    setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void;
} & DataGridPropsT;

const PriceGrid: FC<PropsT> = observer((props) => {
    const { setColumnVisibilityModel, ...restProps } = props;

    const { data: { rows, count } } = useGroupedPriceData();

    return (
        <DataGrid
            { ...restProps }
            rows={ rows }
            rowCount={ count }
            rowsPerPageOptions={ DATA_GRID_PAGE_SIZES_EXTEND }
            className="price-data-grid"
            initialState={ PRICE_DATA_GRID_INITIAL_STATE }
            onColumnVisibilityModelChange={ (model) => setColumnVisibilityModel(model) }
            displayColumns={ false }
        />
    );
});

export default memo(PriceGrid);
