export const BOOKING_DATE_FORMAT = 'd MMMM, cccc, HH:mm';
export const DATE_FORMAT_FOR_COMPARE = 'dd.LL.yyyy';

export const DATE_TIME_FORMAT_FOR_DATA_GRID = 'DD MMMM YYYY HH:mm:ss';
export const DATE_FORMAT_FOR_DATA_GRID = 'DD MMMM YYYY г.';
export const DATE_FORMAT_BASIC = 'DD.MM.YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const API_DATE_TIME_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DDTHH:mm';
export const API_TIME_FORMAT = 'HH:mm';
export const API_END_OF_DAY_TIME = '24:00';
export const MONTH_SHORT = 'мес.';
