import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { addParamsToFilterModel } from 'shared/utils/addParamsToFilterModel';
import { addParamsToSortModel } from 'shared/utils/addParamsToSortModel';
import { CustomHeaderData } from 'types/DataGrid';

const useFetchDataByPriceTypeIdAndPriceSeasonId = (
    priceTypeId: string,
    priceSeasonId: string,
    sortModel: GridSortModel,
    filterModel: GridFilterModel,
    page: number,
    pageSize: number,
    columns: CustomHeaderData[],
    fetch: Function,
) => {
    return fetch(priceTypeId, priceSeasonId, {
        limit: pageSize,
        offset: page === 0 ? 0 : page * pageSize,
        orderBy: addParamsToSortModel(sortModel, columns),
        where: addParamsToFilterModel(filterModel, columns),
    }).then();
};

export default useFetchDataByPriceTypeIdAndPriceSeasonId;
