import { gql } from '@apollo/client';

export type ServiceAppDocumentFields = {
    service_app_id: string;
    title: string;
    url: string;
    is_active: boolean;
    position: number;
};

export type ServiceAppDocumentUpdateVar = {
    id: string;
    set: ServiceAppDocumentFields;
};

export type ServiceAppDocumentUpdateResponseDataT = {
    updateServiceAppDocument: {
        id: string;
    };
} | null | undefined;

export const SERVICE_APP_DOCUMENT_UPDATE = gql`
    mutation updateServiceAppDocument(
        $set: service_app_document_set_input = {},
        $id: String = ""
    ) {
        updateServiceAppDocument: update_service_app_document_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
