import { action, makeObservable, observable } from 'mobx';
import { EMPTY_SERVICE_APP_DOCUMENT_RESULT, ServiceAppDocumentT } from 'types/ServiceAppDocument';

import BaseModalStore from './BaseModalStore';

export default class UpdateServiceAppDocumentModal extends BaseModalStore {
    public document: ServiceAppDocumentT = EMPTY_SERVICE_APP_DOCUMENT_RESULT;

    public setDocument(document: ServiceAppDocumentT) {
        this.document = document;
    }

    constructor() {
        super();

        makeObservable(this, {
            document: observable,
            setDocument: action.bound,
        });
    }
}
