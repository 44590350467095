import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceExtendedDTO } from 'shared/data-hook/price/fetch/DTO/PriceExtendedDTO';
import { PricesByCoachCategoryT } from 'types/PricesByCoachCategory';

const DTO = (currency: string) => t.type({
    id: t.string,
    name: t.string,
    prices: t.array(PriceExtendedDTO(currency)),
});

export type PricesByCoachCategoryDTOT = t.TypeOf<ReturnType<typeof DTO>>;

export const PricesByCoachCategoryDTO = (currency: string) => tPromise.extendDecoder<PricesByCoachCategoryDTOT, PricesByCoachCategoryT>(
    DTO(currency),
    ({
        id,
        name,
        prices,
    }) => ({
        id,
        name,
        prices,
    }),
) as t.Type<PricesByCoachCategoryT, PricesByCoachCategoryDTOT>;
