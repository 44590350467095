import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { EMPLOYEE_HEADER_DATA } from '../domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const EmployeeList: FC<PropsT> = observer((props) => {
    const { employees, count } = useStore('employee');

    return (
        <DataGrid
            rows={ employees }
            rowCount={ count }
            columns={ EMPLOYEE_HEADER_DATA }
            { ...props }
        />
    );
});

export default EmployeeList;
