import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import DynamicSelectWithSearch from 'shared/component/Form/DynamicSelectWithSearch';
import useImplementationDocumentFilterOptions from 'shared/data-hook/implementationDocument/fetch/fetchImplementationDocumentFilterOptions/useImplementationDocumentFilterOptions';

type PropsT<T extends FieldValues> = {
    name: string;
    control: Control<T>;
    size?: 'small';
    label: string;
};

const ImplementationDocumentSelect = <T extends FieldValues>(props: PropsT<T>) => {
    const { fetchImplementationDocumentFilterOptions } = useImplementationDocumentFilterOptions();

    return (
        <DynamicSelectWithSearch
            { ...props }
            fetchOptions={ fetchImplementationDocumentFilterOptions }
        />
    );
};

export default ImplementationDocumentSelect;
