import { MODAL_TITLE_UPDATE_SERVICE_APP_DOCUMENT } from 'constants/modalTitles';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import DocumentForm from 'page/ServiceAppDocuments/components/DocumentForm';
import {
    getServiceAppDocumentValidationSchema,
    SERVICE_APP_DOCUMENT_DEFAULT_VALUES,
    ServiceAppDocumentFormFields,
} from 'page/ServiceAppDocuments/components/formData';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchServiceAppDocument
    from 'shared/hook/serviceAppDocument/fetch/fetchOne/useFetchServiceAppDocument';
import useUpdateServiceAppDocument from 'shared/hook/serviceAppDocument/update/useUpdateServiceAppDocument';
import { useStore } from 'store';

const UpdateDocumentModal: FC = observer(() => {
    const {
        isOpen, onClose, document, itemId: documentId,
    } = useStore('updateServiceAppDocumentModal');
    const { loading: loadingFetch } = useFetchServiceAppDocument(documentId);
    const { updateDocument, loading: loadingUpdate } = useUpdateServiceAppDocument();
    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<ServiceAppDocumentFormFields>({
        defaultValues: SERVICE_APP_DOCUMENT_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getServiceAppDocumentValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateDocument = async (data: ServiceAppDocumentFormFields) => {
        await updateDocument(data, documentId);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_UPDATE_SERVICE_APP_DOCUMENT }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch && <Spinner /> }
                <DocumentForm
                    control={ control }
                    setValue={ setValue }
                    document={ document }
                    isUpdate
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => {
                        reset({
                            ...document,
                            serviceApp: document.serviceApp.id,
                        });
                    } }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateDocument) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ loadingUpdate }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateDocumentModal;
