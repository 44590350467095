import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { GroupCategoryDTO } from 'shared/data-hook/groupCategory/fetch/DTO/GroupCategoryDTO';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { GroupCategoriesT } from 'types/GroupCategory';

const DTO = t.type({
    groupCategories: t.array(GroupCategoryDTO),
    groupCategoriesCount: AggregateDTO,
});

export type GroupCategoriesDTOT = t.TypeOf<typeof DTO>;

export const GroupCategoriesDTO = tPromise.extendDecoder<GroupCategoriesDTOT, GroupCategoriesT>(
    DTO,
    ({
        groupCategories,
        groupCategoriesCount,
    }) => ({
        groupCategories,
        groupCategoriesCount,
    }),
) as t.Type<GroupCategoriesT, GroupCategoriesDTOT>;
