import { gql } from '@apollo/client';

export type ServiceAppUpdateFields = {
    label: string;
    token: string;
    description: string;
    url: string;
    hash: string;
    timezone: number;
    fiat_currency_title: string;
    video_analytics_telegram_bot_chat_ids: string[];
    entry_notification_telegram_bot_chat_ids: string[];
    acquiring_notification_telegram_bot_chat_ids: string[];
    domain_name: string;
    sms_sender_name: string;
    sms_sending_enabled: boolean;
    locale: string;
    default_credit_for_new_clients: number;
    default_organization_location_id: string | null;
    frontend_apps_are_disabled: boolean;
    telegram_notification_bot: string | null;
};

export type ServiceAppUpdateVar = {
    id: string;
    set: ServiceAppUpdateFields;
};

export type ServiceAppUpdateResponseDataT = {
    updateServiceApp: {
        id: string;
    };
} | null | undefined;

export const SERVICE_APP_UPDATE = gql`
    mutation updateServiceApp($set: service_app_set_input = {}, $id: String = "") {
        updateServiceApp: update_service_app_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
