import {
    ERROR_EMPLOYEE_CREATE, SUCCESS_EMPLOYEE_CREATE,
} from 'constants/notificationMessage';

import { EmployeeFormFields } from 'page/Employee/components/formData';
import useEmployeeCreate from 'shared/data-hook/employee/create/useEmployeeCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateEmployee = () => {
    const { error, loading, createEmployee: actionCreate } = useEmployeeCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createEmployee = async (input: EmployeeFormFields): Promise<void> => {
        await actionCreate(input);

        if (error) {
            showError(ERROR_EMPLOYEE_CREATE);
        } else {
            showSuccess(SUCCESS_EMPLOYEE_CREATE);
        }

        toggleRefetchTrigger();
    };

    return {
        createEmployee,
        loading,
    };
};

export default useCreateEmployee;
