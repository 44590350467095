import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { WaitingListDTO } from 'shared/data-hook/waitingList/fetch/DTO/WaitingListDTO';
import { WaitingListDataT } from 'shared/data-hook/waitingList/fetch/fetchOne/WaitingListData';
import { WAITING_LIST_QUERY, WaitingListResponseT } from 'shared/graphql/query/waitingList/WaitingListQuery';
import { EMPTY_WAITING_LIST_RESULT, WaitingListT } from 'types/WaitingList';

const useWaitingList = (): WaitingListDataT => {
    const [fetch, { loading, error }] = useLazyQuery<WaitingListResponseT>(
        WAITING_LIST_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchWaitingList = async (id: string): Promise<WaitingListT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                WaitingListDTO,
                EMPTY_WAITING_LIST_RESULT,
                (waitingList) => waitingList,
                data?.waitingList,
            );
        } catch {
            return EMPTY_WAITING_LIST_RESULT;
        }
    };

    return {
        fetchWaitingList,
        loading,
        error,
    };
};

export default useWaitingList;
