import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PricesByCoachCategoryT } from 'types/PricesByCoachCategory';

export type PricesByCoachCategoryResponseT = {
    pricesByCoachCategory: PricesByCoachCategoryT;
};

export type PricesByCoachCategoriesResponseT = {
    coachCategories: PricesByCoachCategoryT[];
    coachCategoriesCount: AggregateT;
};

export const PRICES_BY_COACH_CATEGORIES_QUERY = gql`
    query PricesByCoachCategories(
        $limit: Int,
        $offset: Int,
        $orderBy: coach_category_order_by! = {},
        $where: coach_category_bool_exp! = {},
        $priceTypeId: String!,
        $priceSeasonId: String!
    ) {
        coachCategories: coach_category(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: {
                _and: [
                    $where
                    {
                        prices: {
                            price_type_id: { _eq: $priceTypeId }
                            price_season_id: { _eq: $priceSeasonId }
                        }
                    }
                ]
            }
        ) {
            id
            name
            prices(
                where: {
                    price_type_id: { _eq: $priceTypeId }
                    price_season_id: { _eq: $priceSeasonId }
                }
            ) {
                id
                day
                time_end
                time_start
                court_type {
                    name
                }
                service_category {
                    name
                }
                group_view {
                    name
                }
                coach_category {
                    name
                }
                price_rates {
                    price_sales_round_id
                    sale_rate
                    min_rate
                    forward_transfer_cost
                    back_transfer_cost
                    transferable
                    cancellable
                    return_rate
                    performer_return_income_rate
                    performer_return_income_sum
                    performer_sale_income_rate
                    performer_sale_income_sum
                    share_of_payment_fiat
                    share_of_payment_bonus
                    transfer_disabled_before_hours
                }
            }
        }
        coachCategoriesCount: coach_category_aggregate(
            where: {
                _and: [
                    $where
                    {
                        prices: {
                            price_type_id: { _eq: $priceTypeId }
                            price_season_id: { _eq: $priceSeasonId }
                        }
                    }
                ]
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;
