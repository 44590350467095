import { gql } from '@apollo/client';

export type CreateServiceAppDocumentPayloadT = {
    service_app_id: string;
    title: string;
    url: string;
    is_active: boolean;
    position: number;
}

export type CreateServiceAppDocumentResponseT = {
    status: string;
    reason: null | string;
}

export type CreateServiceAppDocumentVarT = {
    object: CreateServiceAppDocumentPayloadT;
};

export type CreateServiceAppDocumentT = {
    adminServiceAppDocumentCreate: CreateServiceAppDocumentResponseT;
}

export const EMPTY_CREATE_SERVICE_APP_DOCUMENT_RESPONSE: CreateServiceAppDocumentResponseT = {
    status: 'error',
    reason: '',
};

export const CREATE_SERVICE_APP_DOCUMENT = gql`
    mutation ServiceAppDocumentCreate($object: service_app_document_insert_input = {}) {
        createServiceAppDocument: insert_service_app_document_one(
            object: $object
        ) {
            id
        }
    }
`;
