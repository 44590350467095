import { UseFormReset } from 'react-hook-form';
import getFullName from 'shared/helper/string/getFullName';
import { WaitingListT } from 'types/WaitingList';

import { WaitingListFormFields } from './formData';

type ReturnT = {
    resetWaitingList: (
        waitingList: WaitingListT,
        reset: UseFormReset<WaitingListFormFields>,
    ) => void;
};

const useWaitingListForm = (): ReturnT => {
    const resetWaitingList = (
        waitingList: WaitingListT,
        reset: UseFormReset<WaitingListFormFields>,
    ) => {
        const {
            client,
            group,
            groupDocument,
            organization,
            serviceCategory,
            priceType,
            coachPriceType,
            user,
            coaches,
            courts,
        } = waitingList;

        reset({
            ...waitingList,
            client: {
                value: client?.id,
                label: getFullName(client ?? {}),
            },
            group: group?.value,
            groupDocument: groupDocument
                ? {
                    value: groupDocument.value,
                    label: groupDocument.label,
                }
                : undefined,
            organization: organization.value,
            serviceCategory: serviceCategory?.value,
            priceType: priceType.value,
            coachPriceType: coachPriceType?.value,
            user: {
                value: user.value,
                label: user.label,
            },
            coaches: coaches?.map((coach) => coach.value),
            courts: courts?.map((court) => court.value),
        });
    };

    return { resetWaitingList };
};

export default useWaitingListForm;
