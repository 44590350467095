import { gql } from '@apollo/client';

export type GroupCategoryUpdateVarT = {
    id: string;
    set: GroupCategoryUpdateMutationPayloadT;
};

type GroupCategoryUpdateMutationPayloadT = {
    name: string;
}

export type GroupCategoryUpdateResponseDataT = {
    updateGroupCategory: {
        id: string;
    };
} | null | undefined;

export const GROUP_CATEGORY_UPDATE = gql`
    mutation UpdateGroupCategory($set: group_category_set_input = {}, $id: String = "") {
        updateGroupCategory: update_group_category_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
