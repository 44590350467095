import {
    PROPERTY_IS_ACTIVE, PROPERTY_POSITION, PROPERTY_SERVICE_APP,
    PROPERTY_TITLE, PROPERTY_URL,
} from 'constants/propertyNames';

import { number, object, string } from 'yup';

export type ServiceAppDocumentFormFields = {
    serviceApp: string;
    title: string;
    url: string;
    isActive: boolean;
    position: number;
};

export const SERVICE_APP_DOCUMENT_DEFAULT_VALUES: ServiceAppDocumentFormFields = {
    serviceApp: '',
    title: '',
    url: '',
    isActive: false,
    position: 0,
};

export const SERVICE_APP_DOCUMENT_LABEL_DATA = {
    serviceApp: PROPERTY_SERVICE_APP,
    title: PROPERTY_TITLE,
    url: PROPERTY_URL,
    isActive: PROPERTY_IS_ACTIVE,
    position: PROPERTY_POSITION,
};

export enum SERVICE_APP_DOCUMENT_FIELD_KEYS {
    serviceApp = 'serviceApp',
    title = 'title',
    url = 'url',
    isActive = 'isActive',
    position = 'position',
}

export const getServiceAppDocumentValidationSchema = () =>
    object()
        .shape({
            serviceApp: string().required(),
            title: string().required(),
            url: string().required(),
            position: number().required(),
        })
        .required();
