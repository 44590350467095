import { useMutation } from '@apollo/client';
import { ServiceAppDocumentFormFields } from 'page/ServiceAppDocuments/components/formData';
import { CreateServiceAppDocumentDataT } from 'shared/data-hook/serviceAppDocument/create/CreateServiceAppDocumentData';
import {
    CREATE_SERVICE_APP_DOCUMENT,
    CreateServiceAppDocumentResponseT,
    CreateServiceAppDocumentT,
    CreateServiceAppDocumentVarT,
    EMPTY_CREATE_SERVICE_APP_DOCUMENT_RESPONSE,
} from 'shared/graphql/mutation/serviceAppDocument/ServiceAppDocumentCreate';

const useServiceAppDocumentCreate = (): CreateServiceAppDocumentDataT => {
    const [create, { loading, error }] = useMutation<CreateServiceAppDocumentT, CreateServiceAppDocumentVarT>(
        CREATE_SERVICE_APP_DOCUMENT,
    );

    const createDocument = async (input: ServiceAppDocumentFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        service_app_id: input.serviceApp,
                        title: input.title,
                        url: input.url,
                        is_active: input.isActive,
                        position: input.position,
                    },
                },
            });

            return data?.adminServiceAppDocumentCreate as CreateServiceAppDocumentResponseT;
        } catch {
            return EMPTY_CREATE_SERVICE_APP_DOCUMENT_RESPONSE;
        }
    };

    return {
        createDocument,
        loading,
        error,
    };
};

export default useServiceAppDocumentCreate;
