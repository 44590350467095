import { PRICE_DATA_GRID_INITIAL_STATE } from 'constants/dataGrid';

import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { TabContext as BaseTabContext, TabList } from '@mui/lab';
import { Box, Button, Tab } from '@mui/material';
import { observer } from 'mobx-react';
import { Wrapper } from 'page/Main/styles';
import CategoryGrid from 'page/Price/components/TabContext/ByCategory/CategoryGrid';
import PriceGrid from 'page/Price/components/TabContext/WithoutCategory/PriceGrid';
import useTabContext from 'page/Price/components/useTabContext';
import { StyledTabPanel, StyledWrap } from 'page/Price/domain/Styled';
import ControlPanel from 'shared/component/ControlPanel';
import DisplayColumnsModal from 'shared/component/DataGrid/DisplayColumnsModal/DisplayColumnsModal';
import DisplayColumnsIcon from 'shared/component/Icons/DisplayColumn';
import { DataGridPropsT } from 'types/DataGrid';
import { NestedTablePricesT } from 'types/NestedTablePrices';
import { PriceGrouping } from 'types/Price';

type PropsT = {
    tab: string;
    handleTabChange: (newTab: string) => void;
    loading: boolean;
    fetchSalesRounds: (priceTypeId?: string | undefined) => Promise<void>;
    priceSeasonId: string;
} & DataGridPropsT;

const PriceList: FC<PropsT> = observer((props) => {
    const {
        tab,
    } = props;

    const {
        columnVisibilityModel,
        exportData,
        priceTypes,
        handleRefresh,
        deleteMultiplePrice,
        openFilPriceModal,
        openCreatePriceModal,
        openSelectPriceGrouping,
        changeTab,
        setColumnVisibilityModel,
        groupHeaderData,
        headerData,
        priceGrouping,
    } = useTabContext(props);

    const nestedTableProps: NestedTablePricesT = {
        columnGroupingModel: groupHeaderData,
        columnVisibilityModel,
        multipleDeleteAction: deleteMultiplePrice,
        multipleRefreshAction: handleRefresh,
    };

    return (
        <Wrapper>
            <BaseTabContext value={ tab }>
                <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
                    <TabList
                        scrollButtons={ true }
                        variant="scrollable"
                        onChange={ changeTab }
                    >
                        {
                            tab && priceTypes.map((item) => (
                                <Tab label={ item.title } value={ item.id } key={ item.id } />
                            ))
                        }
                    </TabList>
                </Box>
                <StyledWrap>
                    <ControlPanel>
                        <Button variant="outlined" size="small" onClick={ openCreatePriceModal } startIcon={ <AddIcon /> }>создать строки</Button>
                        <Button variant="outlined" size="small" onClick={ openFilPriceModal } startIcon={ <PlaylistAddIcon /> }>Заполнить тарифы</Button>
                        <DisplayColumnsModal table="price" initialState={ PRICE_DATA_GRID_INITIAL_STATE } columns={ headerData } setColumnVisibilityModel={ setColumnVisibilityModel } />
                        <Button variant="outlined" size="small" onClick={ openSelectPriceGrouping } startIcon={ <DisplayColumnsIcon /> }>Выбрать группировку</Button>
                    </ControlPanel>
                    {
                        priceTypes.map(({ id }) => (
                            <StyledTabPanel value={ id } key={ id } sx={ { padding: 0 } }>
                                { priceGrouping === PriceGrouping.Empty ? (
                                    <PriceGrid
                                        { ...props }
                                        columns={ headerData }
                                        columnGroupingModel={ groupHeaderData }
                                        multipleDeleteAction={ deleteMultiplePrice }
                                        multipleRefreshAction={ handleRefresh }
                                        columnVisibilityModel={ columnVisibilityModel }
                                        setColumnVisibilityModel={ setColumnVisibilityModel }
                                        exportData={ exportData }
                                    />
                                ) : (
                                    <CategoryGrid
                                        { ...props }
                                        multipleDeleteAction={ deleteMultiplePrice }
                                        multipleRefreshAction={ handleRefresh }
                                        detailPanelColumns={ headerData }
                                        nestedTableProps={ nestedTableProps }
                                        exportData={ exportData }
                                    />
                                ) }
                            </StyledTabPanel>
                        ))
                    }
                </StyledWrap>
            </BaseTabContext>
        </Wrapper>
    );
});

export default PriceList;
