import { gql } from '@apollo/client';
import { ClientT } from 'types/Client';

export type ClientsResponseT = {
    clients: ClientT[];
};

export type ClientResponseT = {
    client: ClientT;
};

export type ClientFilterOption = {
    value: string;
    label: {
        full_name: string;
    };
}
export type ClientFilterOptionsResponseT = {
    clients: ClientFilterOption[];
}

export const CLIENTS_QUERY = gql`
    query Clients (
        $limit: Int,
        $offset: Int,
        $orderBy: client_data_order_by! = {},
        $where: client_data_bool_exp!
    ) {
        clients: client_data(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            client {
                first_name
                last_name
                middle_name
            }
            email
            relation_id
        }
    }
`;

export const CLIENT_QUERY = gql`
    query Client($id: String!) {
        client: client_data_by_pk(id: $id) {
            id
            client {
                first_name
                last_name
                middle_name
            }
            email
            relation_id
        }
    }
`;

export const CLIENT_FILTER_OPTIONS_QUERY = gql`
    query Clients(
        $limit: Int
        $offset: Int
        $orderBy: client_data_order_by! = {}
        $where: client_data_bool_exp! = {}
    ) {
        clients: client_data(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: { _and: [$where, { client: { full_name: { _is_null: false } } }] }
        ) {
            value: client_id
            label: client {
                full_name
            }
        }
    }
`;
