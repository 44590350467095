import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { addParamsToFilterModel } from 'shared/utils/addParamsToFilterModel';
import { addParamsToSortModel } from 'shared/utils/addParamsToSortModel';
import { CustomHeaderData } from 'types/DataGrid';

const useFetchData = (
    sortModel: GridSortModel,
    filterModel: GridFilterModel,
    page: number,
    pageSize: number,
    columns: CustomHeaderData[],
    fetch: Function,
    search?: Record<string, any>,
) => {
    return fetch({
        limit: pageSize,
        offset: page === 0 ? 0 : page * pageSize,
        orderBy: addParamsToSortModel(sortModel, columns),
        where: addParamsToFilterModel(filterModel, columns),
        search,
    }).then();
};

export default useFetchData;
