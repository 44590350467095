export const enum EmployeeType {
    Coach = 'coach',
    Manager = 'manager',
    Vendor = 'vendor',
    Admin = 'admin',
}

export const EMPLOYEE_TYPE_LABELS: Record<EmployeeType, string> = {
    [EmployeeType.Coach]: 'Тренер',
    [EmployeeType.Manager]: 'Менеджер',
    [EmployeeType.Vendor]: 'Вендор',
    [EmployeeType.Admin]: 'Админ',
};

export const EMPLOYEE_TYPES_OPTIONS = [
    {
        value: EmployeeType.Coach,
        label: EMPLOYEE_TYPE_LABELS[EmployeeType.Coach],
    },
    {
        value: EmployeeType.Manager,
        label: EMPLOYEE_TYPE_LABELS[EmployeeType.Manager],
    },
    {
        value: EmployeeType.Vendor,
        label: EMPLOYEE_TYPE_LABELS[EmployeeType.Vendor],
    },
    {
        value: EmployeeType.Admin,
        label: EMPLOYEE_TYPE_LABELS[EmployeeType.Admin],
    },
];
