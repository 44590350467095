import { gql } from '@apollo/client';

export type DeleteMultipleWaitingListCoachAndCourtResponseT = {
    deleteMultipleWaitingListCoach: DeleteMultipleWaitingListResponseDataT;
    deleteMultipleWaitingListCourt: DeleteMultipleWaitingListResponseDataT;
};

export type DeleteMultipleWaitingListResponseDataT = {
    affected_rows: number;
};

export type DeleteMultipleWaitingListCoachAndCourtVarT = {
    id: string;
};

export const DELETE_MULTIPLE_WAITING_LIST_COACH_AND_COURT = gql`
    mutation DeleteMultipleWaitingListCoach($id: String! = "") {
        deleteMultipleWaitingListCoach: delete_waiting_list_coach(where: {waiting_list_id: {_eq: $id}}) {
            affected_rows
        }
        deleteMultipleWaitingListCourt: delete_waiting_list_court(where: {waiting_list_id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
