import { PriceRateT } from 'types/PriceRate';

export type PriceT = {
    id: string;
    day: number;
    timeStart: string;
    timeEnd: string;
    courtType: string;
    category: string;
    coachCategory?: string;
    groupView?: string;
    priceRates?: PriceRateT[];
};

export type PricesT = {
    prices: PriceT[];
    pricesCount: number;
};

export type PriceTimePeriodT = {
    timeStart: string;
    timeEnd: string;
};

export type PriceTimePeriodFilterT = {
    priceTypeId: string;
    priceSeasonId: string;
    days: number[];
    courtTypeIds: string[];
    serviceCategoryIds: string[];
    coachCategoryIds: string[];
    groupViewIds: string[];
};

export const EMPTY_PRICES_RESULT: PricesT = {
    prices: [],
    pricesCount: 0,
};

export const EMPTY_PRICE_RESULT: PriceT = {
    id: '',
    day: 1,
    timeStart: '',
    timeEnd: '',
    courtType: '',
    category: '',
};

export enum PriceGrouping {
    Coach = 'coach',
    Service = 'service',
    GroupView = 'groupView',
    Empty = 'withoutCategory',
}

export type PriceGroupingT = PriceGrouping;
