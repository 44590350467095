import { PriceGrouping, PriceGroupingT } from 'types/Price';
import { object, string } from 'yup';

export type SelectPriceGroupingFormFields = {
    grouping: PriceGroupingT;
};

export enum SELECT_PRICE_GROUPING_FIELD_KEYS {
    grouping = 'grouping',
}

export const SELECT_PRICE_GROUPING_DEFAULT_VALUES: SelectPriceGroupingFormFields = {
    grouping: PriceGrouping.Empty,
};

export const SELECT_PRICE_GROUPING_VALIDATION_SCHEMA = object().shape({
    grouping: string(),
}).required();
