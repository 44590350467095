import { useLazyQuery } from '@apollo/client';
import { getFullNameFilter } from 'shared/data-hook/client/filter/getFullNameFilter';
import {
    CLIENT_FILTER_OPTIONS_QUERY, ClientFilterOptionsResponseT,
} from 'shared/graphql/query/client/ClientQuery';
import { FetchDataParams, SearchT } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { OptionT } from 'types/Option';

import { ClientFilterOptionsDataT } from './ClientFilterOptionsData';

const useClientFilterOptions = (): ClientFilterOptionsDataT => {
    const [fetch, { loading, error }] = useLazyQuery<ClientFilterOptionsResponseT, QueryVarsT>(
        CLIENT_FILTER_OPTIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchClientFilterOptions = async ({
        page = 1,
        limit,
        orderBy = {},
        search = { name: '' },
    }: FetchDataParams<SearchT> & {
        page?: number;
    } = {}): Promise<OptionT[]> => {
        try {
            const { data } = await fetch({
                variables: {
                    limit,
                    offset: limit ? (page - 1) * limit : 0,
                    orderBy,
                    where: {
                        ...getFullNameFilter(search),
                    },
                },
            });

            return data?.clients.map(({ value, label: { full_name } }) => ({ value, label: full_name })) ?? [];
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchClientFilterOptions,
        loading,
        error,
    };
};

export default useClientFilterOptions;
