import { object, string } from 'yup';

export type GroupCategoryFormFields = {
    name: string;
}

export enum GROUP_CATEGORY_FIELD_KEYS {
    name = 'name',
}

export const GROUP_CATEGORY_DEFAULT_VALUES = {
    name: '',
};

export const GROUP_CATEGORY_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
}).required();

export const getGroupCategoryValidationSchema = () => GROUP_CATEGORY_VALIDATION_SCHEMA;
