import { MODAL_TITLE_SELECT_GROUPING } from 'constants/modalTitles';
import { PRICE_GROUPING_OPTIONS } from 'constants/PriceGroupingOptions';
import { PROPERTY_GROUPING } from 'constants/propertyNames';

import React, {
    Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { observer } from 'mobx-react';
import { DEFAULT_PRICE_SORT_MODEL } from 'page/Price/domain/priceSortModel';
import Select from 'shared/component/Form/Select';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { useStore } from 'store';

import {
    SELECT_PRICE_GROUPING_DEFAULT_VALUES,
    SELECT_PRICE_GROUPING_FIELD_KEYS, SELECT_PRICE_GROUPING_VALIDATION_SCHEMA,
    SelectPriceGroupingFormFields,
} from './formData';

type PropsT = {
    setSortModel: Dispatch<SetStateAction<GridSortModel>>;
}

const SelectPriceGroupingModal: FC<PropsT> = observer((props) => {
    const { setSortModel } = props;
    const {
        isOpen, onClose, priceGrouping, setPriceGrouping,
    } = useStore('selectPriceGroupingModal');
    const [loading, setLoading] = useState(false);
    const {
        control, handleSubmit, reset, formState: { isValid }, setValue,
    } = useForm<SelectPriceGroupingFormFields>({
        defaultValues: SELECT_PRICE_GROUPING_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(SELECT_PRICE_GROUPING_VALIDATION_SCHEMA),
    });

    useEffect(() => {
        if (isOpen) {
            setValue(SELECT_PRICE_GROUPING_FIELD_KEYS.grouping, priceGrouping);
        }
    }, [priceGrouping, isOpen]);

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleSelectPriceGrouping = (data: SelectPriceGroupingFormFields) => {
        setLoading(true);
        setPriceGrouping(data.grouping);
        setSortModel(DEFAULT_PRICE_SORT_MODEL);

        setLoading(false);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>{ MODAL_TITLE_SELECT_GROUPING }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 spacing={ 2 } container padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <Select
                            name={ SELECT_PRICE_GROUPING_FIELD_KEYS.grouping }
                            label={ PROPERTY_GROUPING }
                            control={ control }
                            options={ PRICE_GROUPING_OPTIONS }
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loading }
                    onClick={ handleSubmit(handleSelectPriceGrouping) }
                    disabled={ !isValid }
                >
                    Подтвердить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default SelectPriceGroupingModal;
