import { MODAL_TITLE_UPDATE_WAITING_LIST } from 'constants/modalTitles';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import useWaitingListForm from 'page/WaitingList/components/useWaitingListForm';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchWaitingList from 'shared/hook/waitingList/fetch/fetchOne/useFetchWaitingList';
import useUpdateWaitingList from 'shared/hook/waitingList/update/useUpdateWaitingList';
import { useStore } from 'store';

import {
    getWaitingListValidationSchema,
    WAITING_LIST_DEFAULT_VALUES,
    WaitingListFormFields,
} from './formData';
import WaitingListForm from './WaitingListForm';

const UpdateWaitingListModal: FC = observer(() => {
    const {
        isOpen,
        onClose,
        waitingList,
        itemId: waitingListId,
    } = useStore('updateWaitingListModal');
    const { loading: loadingFetch } = useFetchWaitingList(waitingListId);
    const { updateWaitingList, loading: loadingUpdate } = useUpdateWaitingList();
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { isValid, isDirty },
    } = useForm<WaitingListFormFields>({
        defaultValues: WAITING_LIST_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getWaitingListValidationSchema()),
    });
    const { resetWaitingList } = useWaitingListForm();

    const handleCloseModal = () => {
        onClose();
        reset(WAITING_LIST_DEFAULT_VALUES);
    };

    const handleUpdateWaitingList = async (data: WaitingListFormFields) => {
        await updateWaitingList(waitingListId, data);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>{ MODAL_TITLE_UPDATE_WAITING_LIST }</ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch && <Spinner /> }
                <WaitingListForm
                    isUpdate
                    control={ control }
                    setValue={ setValue }
                    waitingList={ waitingList }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => {
                        resetWaitingList(waitingList, reset);
                    } }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateWaitingList) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ loadingUpdate }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateWaitingListModal;
