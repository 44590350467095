import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ServiceAppDocumentDTO } from 'shared/data-hook/serviceAppDocument/fetch/DTO/ServiceAppDocumentDTO';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { ServiceAppDocumentsT } from 'types/ServiceAppDocument';

const DTO = t.type({
    documents: t.array(ServiceAppDocumentDTO),
    documentsCount: AggregateDTO,
});

type ServiceAppDocumentDTOT = t.TypeOf<typeof DTO>;

export const ServiceAppDocumentsDTO = tPromise.extendDecoder<ServiceAppDocumentDTOT, ServiceAppDocumentsT>(
    DTO,
    ({
        documents,
        documentsCount,
    }) => ({
        documents,
        documentsCount,
    }),
) as t.Type<ServiceAppDocumentsT, ServiceAppDocumentDTOT>;
