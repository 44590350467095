import { makeAutoObservable } from 'mobx';
import {
    EMPTY_SERVICE_APP_DOCUMENT_RESULT,
    ServiceAppDocumentT,
} from 'types/ServiceAppDocument';

export default class ServiceAppDocumentStore {
    public documents: ServiceAppDocumentT[] = [];

    public document: ServiceAppDocumentT = EMPTY_SERVICE_APP_DOCUMENT_RESULT;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setDocuments(documents: ServiceAppDocumentT[], count: number) {
        this.documents = documents;
        this.count = count;
    }

    public setDocument(document: ServiceAppDocumentT) {
        this.document = document;
    }
}
