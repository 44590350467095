import { useLazyQuery } from '@apollo/client';
import { PricesByGroupViewsDTO } from 'shared/data-hook/pricesByGroupViews/fetch/DTO/PricesByGroupViewsDTO';
import { PricesByGroupViewsDataT } from 'shared/data-hook/pricesByGroupViews/fetch/fetchAll/PricesByGroupViewsData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    PRICES_BY_GROUP_VIEW_QUERY,
    PricesByGroupViewsResponseT,
} from 'shared/graphql/query/PricesByGroupView/PricesByGroupViewQuery';
import { useStore } from 'store';
import { FetchDataParams } from 'types/DataGrid';
import { FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT } from 'types/Graphql';
import { EMPTY_PRICES_BY_GROUP_VIEWS_RESULT } from 'types/PricesByGroupView';

const usePricesByGroupViews = (): PricesByGroupViewsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PricesByGroupViewsResponseT, FetchDataByPriceTypeIdAndPriceSeasonIdQueryVarsT>(PRICES_BY_GROUP_VIEW_QUERY, {
        fetchPolicy: 'network-only',
    });

    const { serviceApp } = useStore('serviceApp');

    const decodedPricesByGroupViews = decodeDTO(
        PricesByGroupViewsDTO(serviceApp.fiatCurrencyTitle),
        EMPTY_PRICES_BY_GROUP_VIEWS_RESULT,
        (pricesByGroupViewsData) => pricesByGroupViewsData,
        {
            pricesByGroupViews: data?.groupViews,
            pricesByGroupViewsCount: data?.groupViewsCount,
        },
    );
    const fetchPricesByGroupViews = async (priceTypeId: string, priceSeasonId: string, {
        limit, offset, orderBy = { name: 'asc' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                    priceTypeId,
                    priceSeasonId,
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchPricesByGroupViews,
        data: decodedPricesByGroupViews.pricesByGroupViews,
        count: decodedPricesByGroupViews.pricesByGroupViewsCount,
        loading,
    };
};

export default usePricesByGroupViews;
