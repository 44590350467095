import {
    ERROR_GROUP_CATEGORY_CREATE,
    SUCCESS_GROUP_CATEGORY_CREATE,
} from 'constants/notificationMessage';

import { GroupCategoryFormFields } from 'page/GroupCategory/components/modals/GroupCategoryModal/formData';
import useGroupCategoryCreate from 'shared/data-hook/groupCategory/create/useGroupCategoryCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateGroupCategory = () => {
    const { showError, showSuccess } = useNotification();
    const { loading, error, groupCategoryCreate } = useGroupCategoryCreate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createGroupCategory = async (input: GroupCategoryFormFields) => {
        const response = await groupCategoryCreate(input);

        if (error || !response) {
            showError(ERROR_GROUP_CATEGORY_CREATE);
        } else {
            showSuccess(SUCCESS_GROUP_CATEGORY_CREATE);
        }

        toggleRefetchTrigger();
    };

    return {
        createGroupCategory,
        loading,
    };
};

export default useCreateGroupCategory;
