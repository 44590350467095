import { useMutation } from '@apollo/client';
import { ServiceAppDocumentDeleteDataT } from 'shared/data-hook/serviceAppDocument/delete/ServiceAppDocumentDeleteData';
import {
    SERVICE_APP_DOCUMENT_DELETE,
    ServiceAppDocumentDeleteResponseDataT, ServiceAppDocumentDeleteVarT,
} from 'shared/graphql/mutation/serviceAppDocument/ServiceAppDocumentDelete';
import { ServiceAppDocumentT } from 'types/ServiceAppDocument';

const useServiceAppDocumentDelete = (): ServiceAppDocumentDeleteDataT => {
    const [documentDelete, { loading, error }] = useMutation<
        ServiceAppDocumentDeleteResponseDataT,
        ServiceAppDocumentDeleteVarT
    >(SERVICE_APP_DOCUMENT_DELETE);

    const deleteAction = async (id: ServiceAppDocumentT['id']) => {
        try {
            const { data } = await documentDelete({
                variables: {
                    id,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteDocument: deleteAction,
        loading,
        error,
    };
};

export default useServiceAppDocumentDelete;
