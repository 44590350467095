import { PROPERTY_WITHOUT_CATEGORY } from 'constants/propertyNames';

import { GroupCategoryT } from 'types/GroupCategory';
import { v4 } from 'uuid';

export const ROW_WITHOUT_CATEGORY: GroupCategoryT = {
    id: v4(),
    name: PROPERTY_WITHOUT_CATEGORY,
    groupViews: [],
};
