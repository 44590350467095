import { gql } from '@apollo/client';

export type EmployeeFields = {
    full_name: string;
    phone: string | null;
    email: string | null;
    type: string;
};

export type EmployeeUpdateVar = {
    id: string;
    set: EmployeeFields;
};

export type EmployeeUpdateResponseDataT = {
    updateEmployee: {
        id: string;
    };
} | null | undefined;

export const EMPLOYEE_UPDATE = gql`
    mutation updateEmployee(
        $set: employee_set_input = {},
        $id: String = ""
    ) {
        updateEmployee: update_employee_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
