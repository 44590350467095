import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { useStore } from 'store';

import { WAITING_LIST_HEADER_DATA } from './HeaderData';

export const prepareWaitingListHeaderData = (): GridColumns => {
    const { getFilterOption } = useStore('filterOptions');
    const priceType = getFilterOption('priceType');
    const serviceCategory = getFilterOption('serviceCategory');
    const group = getFilterOption('group');
    const organization = getFilterOption('organization');

    return useMemo(
        () =>
            mapFields(WAITING_LIST_HEADER_DATA, {
                priceType,
                coachPriceType: priceType,
                serviceCategory,
                group,
                organization,
            }),
        [priceType, serviceCategory, group, organization],
    );
};
