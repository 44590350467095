import { useLazyQuery } from '@apollo/client';
import { GroupCategoryDTO } from 'shared/data-hook/groupCategory/fetch/DTO/GroupCategoryDTO';
import { GroupCategoryDataT } from 'shared/data-hook/groupCategory/fetch/fetchOne/GroupCategoryData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { GROUP_CATEGORY_QUERY, GroupCategoryResponseT } from 'shared/graphql/query/groupCategory/GroupCategoryQuery';
import { EMPTY_GROUP_CATEGORY_RESULT, GroupCategoryT } from 'types/GroupCategory';

const useGroupCategory = (): GroupCategoryDataT => {
    const [fetch, { loading, error }] = useLazyQuery<GroupCategoryResponseT>(
        GROUP_CATEGORY_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchGroupCategory = async (id: string): Promise<GroupCategoryT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                GroupCategoryDTO,
                EMPTY_GROUP_CATEGORY_RESULT,
                (groupCategory) => groupCategory,
                data?.groupCategory,
            );
        } catch {
            return EMPTY_GROUP_CATEGORY_RESULT;
        }
    };

    return {
        fetchGroupCategory,
        loading,
        error,
    };
};

export default useGroupCategory;
