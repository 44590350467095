import React, { FC } from 'react';
import {
    Box, InputLabel, ToggleButton as BaseToggleButton, ToggleButtonGroup,
} from '@mui/material';
import parse from 'html-react-parser';
import AuxiliaryPopover, { PropsT as AuxiliaryPopoverPropsT } from 'shared/component/AuxiliaryPopover';

type PropsT = Partial<AuxiliaryPopoverPropsT> & {
    buttons: Array<{
        value: any;
        label: string;
        popoverText?: string;
    }>;
    formAlignment: any;
    setFormAlignment: Function;
    empty?: boolean;
    label?: string;
    fullWidth?: boolean;
    labelSize?: number;
}

const ToggleButton: FC<PropsT> = (props) => {
    const {
        buttons,
        formAlignment,
        setFormAlignment,
        empty,
        label,
        fullWidth,
        auxiliaryPopoverText,
        labelSize = 16,
        auxiliaryPopoverIconSize,
    } = props;

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        if (!newAlignment && !empty) {
            return;
        }

        setFormAlignment(newAlignment);
    };

    return (
        <Box>
            <InputLabel
                sx={ { display: 'flex', alignItems: 'center' } }
            >
                <Box
                    sx={ {
                        marginRight: auxiliaryPopoverText ? '4px' : 0,
                        fontSize: labelSize,
                    } }
                >
                    { label }
                </Box>
                { auxiliaryPopoverText && (
                    <AuxiliaryPopover
                        auxiliaryPopoverIconSize={ auxiliaryPopoverIconSize }
                        auxiliaryPopoverText={ auxiliaryPopoverText }
                    />
                ) }
            </InputLabel>
            <ToggleButtonGroup
                color="primary"
                value={ formAlignment }
                exclusive
                onChange={ handleChange }
                size="small"
                sx={ { width: '100%' } }
            >
                {
                    buttons.map((button) => (
                        <BaseToggleButton
                            sx={ {
                                ...(fullWidth && { width: `calc(100% / ${buttons.length})` }),
                                ...(button.popoverText && { height: '40px', padding: 0 }),
                            } }
                            value={ button.value }
                            key={ `${button.value}-${button.label}` }
                        >
                            { parse(button.label) }
                            { button.popoverText && (
                                <AuxiliaryPopover
                                    auxiliaryPopoverIconSize={ auxiliaryPopoverIconSize }
                                    auxiliaryPopoverText={ button.popoverText }
                                />
                            ) }
                        </BaseToggleButton>
                    ))
                }
            </ToggleButtonGroup>
        </Box>
    );
};

export default ToggleButton;
