import { FC } from 'react';
import { observer } from 'mobx-react';
import { GROUP_CATEGORY_HEADER_DATA } from 'page/GroupCategory/domain/HeaderData';
import { GROUP_VIEW_HEADER_DATA } from 'page/GroupView/domain/HeaderData';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareNestedGroupViewsHeaderData } from '../domain/prepareNestedGroupViewsHeaderData';
import { ROW_WITHOUT_CATEGORY } from '../domain/rows';

import { useGroupedGroupViewsData } from './useGroupedGroupViewsData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const GroupViewsByCategoryGrid: FC<PropsT> = observer((props) => {
    const { groupViews } = useStore('groupView');
    const { data: { rows, count } } = useGroupedGroupViewsData();
    const filteredRows = groupViews.length > 0 ? [...rows, { ...ROW_WITHOUT_CATEGORY, groupViews }] : rows;
    const columns = prepareNestedGroupViewsHeaderData(GROUP_VIEW_HEADER_DATA);

    return (
        <DataGrid
            rows={ filteredRows }
            rowCount={ count }
            columns={ GROUP_CATEGORY_HEADER_DATA }
            detailPanelColumns={ columns }
            { ...props }
        />
    );
});

export default GroupViewsByCategoryGrid;
