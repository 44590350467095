import { gql } from '@apollo/client';
import { ServiceAppDocumentT } from 'types/ServiceAppDocument';

export type ServiceAppDocumentDeleteResponseDataT = {
    deleteServiceAppDocument: {
        id: ServiceAppDocumentT['id'];
    };
} | null | undefined;

export type ServiceAppDocumentDeleteVarT = {
    id: ServiceAppDocumentT['id'];
};

export const SERVICE_APP_DOCUMENT_DELETE = gql`
    mutation DeleteServiceAppDocument($id: String = "") {
        deleteServiceAppDocument: delete_service_app_document_by_pk(id: $id) {
            id
        }
    }
`;
