import { useMutation } from '@apollo/client';
import { WaitingListArchiveVar } from 'shared/graphql/mutation/waitingList/WaitingListArchive';
import {
    WAITING_LIST_UPDATE,
    WaitingListUpdateResponseDataT,
} from 'shared/graphql/mutation/waitingList/WaitingListUpdate';
import { WaitingListT } from 'types/WaitingList';

import { WaitingListArchiveDataT } from './WaitingListArchiveData';

const useWaitingListArchive = (): WaitingListArchiveDataT => {
    const [update, { loading, error }] = useMutation<
        WaitingListUpdateResponseDataT,
        WaitingListArchiveVar
    >(WAITING_LIST_UPDATE);

    const waitingListArchive = async (
        id: WaitingListT['id'],
    ) => {
        if (!id) {
            // TODO: use Sentry
            throw new Error('WaitingList is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id,
                    set: {
                        is_archive: true,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        waitingListArchive,
        loading,
        error,
    };
};

export default useWaitingListArchive;
