import { number, object, string } from 'yup';

export type ServiceCategoryFormFields = {
    color: string | null;
    name: string;
    minAdditionalDuration: number;
    minDuration: number;
    position: number;
    allowedForClient: boolean;
}

export enum SERVICE_CATEGORY_FIELDS_KEYS {
    color = 'color',
    name = 'name',
    minAdditionalDuration = 'minAdditionalDuration',
    minDuration = 'minDuration',
    position = 'position',
    allowedForClient = 'allowedForClient',
}

export const SERVICE_CATEGORY_DEFAULT_VALUES = {
    color: '',
    name: '',
    minAdditionalDuration: 0,
    minDuration: 0,
    position: 0,
    allowedForClient: false,
};

export const SERVICE_CATEGORY_VALIDATION_SCHEMA = object().shape({
    color: string().required(),
    name: string().required(),
    minAdditionalDuration: number().required(),
    minDuration: number().required(),
    position: number().required(),
}).required();

export const getServiceCategoryValidationSchema = () => SERVICE_CATEGORY_VALIDATION_SCHEMA;
