import { useLazyQuery } from '@apollo/client';
import { ServiceAppDocumentsDTO } from 'shared/data-hook/serviceAppDocument/fetch/DTO/ServiceAppDocumentsDTO';
import { ServiceAppDocumentsDataT } from 'shared/data-hook/serviceAppDocument/fetch/fetchAll/ServiceAppDocumentsData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    SERVICE_APP_DOCUMENTS_QUERY,
    ServiceAppDocumentsResponseT,
} from 'shared/graphql/query/serviceAppDocument/serviceAppDocumentQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_SERVICE_APP_DOCUMENTS_RESULT } from 'types/ServiceAppDocument';

const useServiceAppDocuments = (): ServiceAppDocumentsDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<ServiceAppDocumentsResponseT, QueryVarsT>(
        SERVICE_APP_DOCUMENTS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedAgesData = decodeDTO(
        ServiceAppDocumentsDTO,
        EMPTY_SERVICE_APP_DOCUMENTS_RESULT,
        (documents) => documents,
        {
            documents: data?.documents,
            documentsCount: data?.documentsCount,
        },
    );

    const fetchDocuments = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedAgesData.documents,
        count: decodedAgesData.documentsCount,
        fetchDocuments,
        loading,
        error,
    };
};

export default useServiceAppDocuments;
