import {
    PROPERTY_CATEGORY as PRICE_GROUPING_SERVICE_LABEL, PROPERTY_COACH_CATEGORY as PRICE_GROUPING_COACH_LABEL, PROPERTY_GROUP_VIEW as PRICE_GROUPING_GROUP_VIEW_LABEL, PROPERTY_WITHOUT_GROUPING as PRICE_GROUPING_WITHOUT_LABEL,
} from 'constants/propertyNames';

import { PriceGrouping } from 'types/Price';

export const PRICE_GROUPING_OPTIONS = [
    {
        value: PriceGrouping.Empty,
        label: PRICE_GROUPING_WITHOUT_LABEL,
    },
    {
        value: PriceGrouping.Service,
        label: PRICE_GROUPING_SERVICE_LABEL,
    },
    {
        value: PriceGrouping.Coach,
        label: PRICE_GROUPING_COACH_LABEL,
    },
    {
        value: PriceGrouping.GroupView,
        label: PRICE_GROUPING_GROUP_VIEW_LABEL,
    },
];
