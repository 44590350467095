export type PaymentT = {
    id: string;
    paymentDocument: {
        id: string;
        number: string;
        isArchive: boolean | null;
    };
    place: string | null;
    service: string | null;
    operation: string | null;
    reason: string | null;
    datetime: Date;
    clientId: string | null;
    payer: string | null;
    paymentMethod: {
        id: string;
        title: string;
    } | null;
    performer: string | null;
    userId: string | null;
    comment: string | null;
    organizationLocation: {
        id: string;
        name: string;
    } | null;
    total: number;
    paymentInFiat: number | null;
    paymentInBonus: number | null;
    paymentType: {
        id: string;
        title: string;
    } | null;
    clientDatum: {
        fullName: string;
    } | null;
};

export type PaymentsT = {
    payments: PaymentT[];
    paymentsCount: number;
};

export const EMPTY_PAYMENTS: PaymentsT = {
    payments: [],
    paymentsCount: 0,
};
