import { action, makeObservable, observable } from 'mobx';
import { EMPTY_GROUP_CATEGORY_RESULT, GroupCategoryT } from 'types/GroupCategory';

import BaseModalStore from './BaseModalStore';

export default class UpdateGroupCategoryModal extends BaseModalStore {
    public groupCategory: GroupCategoryT = EMPTY_GROUP_CATEGORY_RESULT;

    public setGroupCategory(groupCategory: GroupCategoryT) {
        this.groupCategory = groupCategory;
    }

    constructor() {
        super();

        makeObservable(this, {
            groupCategory: observable,
            setGroupCategory: action.bound,
        });
    }
}
