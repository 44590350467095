import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';
import { GroupViewsGrouping } from 'types/GroupView';

import GroupViewsByCategoryGrid from './GroupViewsByCategoryGrid';
import GroupViewsGrid from './GroupViewsGrid';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const GroupViewsList: FC<PropsT> = observer((props) => {
    const { groupViewsGrouping } = useStore('selectGroupViewsGroupingModal');

    return (
        groupViewsGrouping === GroupViewsGrouping.Empty ? (
            <GroupViewsGrid { ...props } />
        ) : (
            <GroupViewsByCategoryGrid { ...props } />
        )
    );
});

export default GroupViewsList;
