import { PAGE_TITLE_PRICE } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import CreateButtonLink from 'shared/component/Button/CreateButtonLink';
import ControlPanel from 'shared/component/ControlPanel';
import CreateScheduleModal from 'shared/component/Modal/CreateScheduleModal';
import usePriceSeasons from 'shared/data-hook/priceSeason/fetch/fetchAll/usePriceSeasons';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import PriceSeasonList from './components/PriceSeasonList';
import { PRICE_SEASON_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();

    const {
        data: priceSeasonsData, loading: priceSeasonsLoading, count: rowCount, fetchPriceSeasons,
    } = usePriceSeasons();
    const { setPriceSeasons } = useStore('priceSeason');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_PRICE);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchPriceSeasons, refetchTrigger, PRICE_SEASON_HEADER_DATA);

    useEffect(() => {
        setPriceSeasons(priceSeasonsData, rowCount);
    }, [priceSeasonsData, priceSeasonsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <CreateButtonLink />
            </ControlPanel>
            <PriceSeasonList
                loading={ priceSeasonsLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateScheduleModal />
        </StyledPageWrap>
    );
});

export default List;
