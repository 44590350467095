import React, { ReactElement } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import {
    Box, InputAdornment, InputLabel, Skeleton, TextField as BaseTextField,
} from '@mui/material';
import AuxiliaryPopover, { PropsT as AuxiliaryPopoverPropsT } from 'shared/component/AuxiliaryPopover';
import { getErrorMessage } from 'shared/utils/getErrorMessage';

type PropsT = Partial<AuxiliaryPopoverPropsT> & {
    label?: string | ReactElement;
    loading?: boolean;
    size?: 'small' | 'medium';
    dateRangeProps?: any;
    customError?: boolean;
    inputAdornment?: string | ReactElement;
    withoutError?: boolean;
    labelSize?: number;
    labelWrap?: boolean;
    multiline?: boolean;
    readOnly?: boolean;
};

const TextField = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control,
        name,
        label,
        type,
        loading,
        size = 'medium',
        dateRangeProps,
        customError,
        inputAdornment,
        withoutError,
        auxiliaryPopoverText,
        auxiliaryPopoverIconSize,
        labelSize = 16,
        labelWrap = false,
        multiline = false,
        readOnly = false,
    } = props;

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field, fieldState }) => (
                <Box>
                    { label && (
                        <InputLabel
                            sx={ {
                                display: 'flex',
                                alignItems: 'center',
                            } }
                        >
                            <Box
                                sx={ {
                                    marginRight: auxiliaryPopoverText ? '4px' : 0,
                                    fontSize: labelSize,
                                    whiteSpace: labelWrap ? 'normal' : 'nowrap',
                                } }
                            >
                                { label }
                            </Box>

                            { auxiliaryPopoverText && (
                                <AuxiliaryPopover
                                    auxiliaryPopoverText={ auxiliaryPopoverText }
                                    auxiliaryPopoverIconSize={ auxiliaryPopoverIconSize }
                                />
                            ) }
                        </InputLabel>
                    ) }
                    { loading ? (
                        <Skeleton variant="rounded" height={ size ? 40 : 56 } />
                    ) : (
                        <BaseTextField
                            { ...dateRangeProps }
                            { ...field }
                            type={ type }
                            error={ !!fieldState.error }
                            value={ field.value ?? '' }
                            InputProps={ {
                                readOnly,
                                endAdornment: inputAdornment && (
                                    <InputAdornment position="end">{ inputAdornment }</InputAdornment>
                                ),
                            } }
                            size={ size }
                            helperText={
                                withoutError
                                    ? null
                                    : (customError && fieldState.error?.message) ||
                                      getErrorMessage(fieldState)
                            }
                            fullWidth
                            multiline={ multiline }
                        />
                    ) }
                </Box>
            ) }
        />
    );
};

export default TextField;
