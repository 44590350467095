import { EMPTY_OPTION } from 'constants/emptyOption';
import {
    PROPERTY_CLIENT,
    PROPERTY_COACH,
    PROPERTY_COACH_PRICE_TYPE,
    PROPERTY_COMMENT,
    PROPERTY_COURT,
    PROPERTY_DATETIME,
    PROPERTY_DATETIME_CREATION,
    PROPERTY_DURATION,
    PROPERTY_GROUP, PROPERTY_IMPLEMENTATION_DOCUMENT,
    PROPERTY_ORGANIZATION,
    PROPERTY_PRICE_TYPE,
    PROPERTY_SERVICE,
    PROPERTY_TOP_PRIORITY,
    PROPERTY_USER,
} from 'constants/propertyNames';

import { OptionT } from 'types/Option';
import {
    bool, date, number, object, string,
} from 'yup';

export type WaitingListFormFields = {
    client: OptionT;
    group?: string;
    groupDocument?: OptionT;
    organization: string;
    serviceCategory?: string;
    priceType: string;
    coachPriceType?: string;
    user: OptionT;
    duration: number;
    datetime: Date;
    topPriority?: boolean;
    comment?: string;
    createdAt: Date;
    coaches?: string[];
    courts?: string[];
};

export const WAITING_LIST_DEFAULT_VALUES: WaitingListFormFields = {
    client: EMPTY_OPTION,
    group: '',
    groupDocument: EMPTY_OPTION,
    organization: '',
    serviceCategory: '',
    priceType: '',
    coachPriceType: '',
    user: EMPTY_OPTION,
    duration: 0,
    datetime: new Date(),
    topPriority: false,
    comment: '',
    createdAt: new Date(),
    coaches: [],
    courts: [],
};

export const WAITING_LIST_LABEL_DATA = {
    client: PROPERTY_CLIENT,
    group: PROPERTY_GROUP,
    groupDocument: PROPERTY_IMPLEMENTATION_DOCUMENT,
    organization: PROPERTY_ORGANIZATION,
    serviceCategory: PROPERTY_SERVICE,
    priceType: PROPERTY_PRICE_TYPE,
    coachPriceType: PROPERTY_COACH_PRICE_TYPE,
    user: PROPERTY_USER,
    duration: PROPERTY_DURATION,
    datetime: PROPERTY_DATETIME,
    topPriority: PROPERTY_TOP_PRIORITY,
    comment: PROPERTY_COMMENT,
    createdAt: PROPERTY_DATETIME_CREATION,
    coaches: PROPERTY_COACH,
    courts: PROPERTY_COURT,
};

export enum WAITING_LIST_FIELDS_KEYS {
    client = 'client',
    group = 'group',
    groupDocument = 'groupDocument',
    organization = 'organization',
    serviceCategory = 'serviceCategory',
    priceType = 'priceType',
    coachPriceType = 'coachPriceType',
    user = 'user',
    duration = 'duration',
    datetime = 'datetime',
    topPriority = 'topPriority',
    comment = 'comment',
    createdAt = 'createdAt',
    coaches = 'coaches',
    courts = 'courts',
}

export const getWaitingListValidationSchema = () =>
    object()
        .shape({
            client: object().shape({
                value: string().required(),
                label: string().required(),
            }).required(),
            group: string(),
            organization: string().required(),
            serviceCategory: string(),
            priceType: string().required(),
            coachPriceType: string(),
            user: object().shape({
                value: string().required(),
                label: string().required(),
            }).required(),
            duration: number().required(),
            datetime: date().required(),
            topPriority: bool(),
            comment: string(),
            createdAt: date().required(),
        })
        .required();
