import { PAGE_TITLE_GROUP_VIEW_SETTING } from 'constants/pageTitles';
import { PROPERTY_SEARCH_BY_NAME, PROPERTY_SHOW_EMPTY_CATEGORIES } from 'constants/propertyNames';
import { DEFAULT_DEBOUNCE_DELAY_IN_MS } from 'constants/time';

import React, {
    ChangeEvent, FC, useEffect, useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
    Button, FormControlLabel, Switch, TextField,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import CreateGroupCategoryModal from 'page/GroupCategory/components/modals/GroupCategoryModal/CreateGroupCategoryModal';
import UpdateGroupCategoryModal from 'page/GroupCategory/components/modals/GroupCategoryModal/UpdateGroupCategoryModal';
import SelectGroupingModal from 'page/GroupCategory/components/modals/SelectGroupingModal/SelectGroupingModal';
import useFetchGroupViewsByCategories from 'page/GroupCategory/components/useFetchGroupViewsByCategories';
import { GROUP_CATEGORY_HEADER_DATA } from 'page/GroupCategory/domain/HeaderData';
import CreateGroupViewModal from 'page/GroupView/components/CreateGroupViewModal';
import UpdateGroupViewModal from 'page/GroupView/components/UpdateGroupViewModal';
import { GROUP_VIEW_HEADER_DATA } from 'page/GroupView/domain/HeaderData';
import ControlPanel from 'shared/component/ControlPanel';
import DisplayColumns from 'shared/component/Icons/DisplayColumn';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchData from 'shared/hook/dataGrid/useFetchData';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';
import { SearchT } from 'types/DataGrid';

import { GroupViewsGrouping } from '../../types/GroupView';

import GroupViewsList from './components/GroupViewsList';

const List: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen: createGroupViewModal } = useStore('createGroupCategoryModal');
    const { onOpen: groupViewCreateModal } = useStore('createGroupViewModal');
    const [searchFilters, setSearchFilters] = useState<SearchT>({ name: '' });
    const { fetch, loading } = useFetchGroupViewsByCategories(searchFilters);
    const { onOpen: openSelectGroupViewsGrouping, groupViewsGrouping } = useStore('selectGroupViewsGroupingModal');
    const [showAllCategories, setShowAllCategories] = useState(false);

    useEffect(() => {
        useFetchData(
            sortModel,
            filterModel,
            page,
            pageSize,
            [...GROUP_CATEGORY_HEADER_DATA, ...GROUP_VIEW_HEADER_DATA],
            fetch,
            { name: searchFilters.name, showAllCategories },
        );
    }, [sortModel, filterModel, page, pageSize, groupViewsGrouping, refetchTrigger, searchFilters, showAllCategories]);

    usePageTitle(PAGE_TITLE_GROUP_VIEW_SETTING);

    const handleSetSearchValue = debounce((name: string) => {
        setSearchFilters({ name });
    }, DEFAULT_DEBOUNCE_DELAY_IN_MS);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetSearchValue(e.target.value);
    };

    const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setShowAllCategories(e.target.checked);
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <TextField
                    label={ PROPERTY_SEARCH_BY_NAME }
                    size="small"
                    onChange={ handleSearchChange }
                />
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => createGroupViewModal() }
                    startIcon={ <AddIcon /> }
                >
                    Добавить категорию группы
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => groupViewCreateModal() }
                    startIcon={ <AddIcon /> }
                >
                    Добавить вид группы
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => openSelectGroupViewsGrouping() }
                    startIcon={ <DisplayColumns /> }
                >
                    Группировать строки
                </Button>
                { groupViewsGrouping === GroupViewsGrouping.GroupCategory && (
                    <FormControlLabel
                        control={ (
                            <Switch
                                checked={ showAllCategories }
                                onChange={ handleSwitchChange }
                                inputProps={ { 'aria-label': 'controlled' } }
                            />
                        ) }
                        label={ PROPERTY_SHOW_EMPTY_CATEGORIES }
                    />
                ) }
            </ControlPanel>
            <GroupViewsList
                loading={ loading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                sortModel={ sortModel }
                setSortModel={ setSortModel }
                filterModel={ filterModel }
                setFilterModel={ setFilterModel }
            />
            <CreateGroupViewModal />
            <CreateGroupCategoryModal />
            <UpdateGroupViewModal />
            <UpdateGroupCategoryModal />
            <SelectGroupingModal />
        </StyledPageWrap>
    );
});

export default List;
