import { useMutation } from '@apollo/client';
import { WaitingListFormFields } from 'page/WaitingList/components/formData';
import { CreateWaitingListDataT } from 'shared/data-hook/waitingList/create/CreateWaitingListData';
import {
    CREATE_WAITING_LIST,
    CreateWaitingListVarT, WaitingListCreateResponseDataT,
} from 'shared/graphql/mutation/waitingList/WaitingListCreate';

import { CreateWaitingListDTO } from './DTO/CreateWaitingListDTO';

const useWaitingListCreate = (): CreateWaitingListDataT => {
    const [create, { loading, error }] = useMutation<WaitingListCreateResponseDataT, CreateWaitingListVarT>(
        CREATE_WAITING_LIST,
    );

    const createWaitingList = async (input: WaitingListFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: CreateWaitingListDTO.encode(input),
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        createWaitingList,
        loading,
        error,
    };
};

export default useWaitingListCreate;
