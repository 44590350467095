import { makeAutoObservable } from 'mobx';
import { WaitingListT } from 'types/WaitingList';

export default class WaitingListStore {
    public waitingLists: WaitingListT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setWaitingLists(waitingLists: WaitingListT[], count: number) {
        this.waitingLists = waitingLists;
        this.count = count;
    }
}
