import { makeAutoObservable } from 'mobx';
import { PricesByGroupViewT } from 'types/PricesByGroupView';

export default class PricesByGroupViewStore {
    public pricesByGroupViewList: PricesByGroupViewT[] = [];

    public pricesByGroupView: PricesByGroupViewT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get groupViews(): PricesByGroupViewT[] {
        return this.pricesByGroupViewList;
    }

    public setPricesByGroupViews(pricesByGroupViews: PricesByGroupViewT[], count?: number) {
        this.pricesByGroupViewList = pricesByGroupViews;
        this.count = count ?? 0;
    }
}
