import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { EMPLOYEE_QUERY, EmployeeResponseT } from 'shared/graphql/query/employee/EmployeeQuery';
import { EmployeeT, EMPTY_EMPLOYEE_RESULT } from 'types/Employee';

import { EmployeeDTO } from '../DTO/EmployeeDTO';

import { EmployeeDataT } from './EmployeeData';

const useEmployee = (): EmployeeDataT => {
    const [fetch, { loading, error }] = useLazyQuery<EmployeeResponseT>(
        EMPLOYEE_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchEmployee = async (id: string): Promise<EmployeeT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                EmployeeDTO,
                EMPTY_EMPLOYEE_RESULT,
                (employee) => employee,
                data?.employee,
            );
        } catch {
            return EMPTY_EMPLOYEE_RESULT;
        }
    };

    return {
        fetchEmployee,
        loading,
        error,
    };
};

export default useEmployee;
