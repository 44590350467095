import React, { FC, memo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { observer } from 'mobx-react';
import { useCategoryHeaderData } from 'page/Price/components/useCategoryHeaderData';
import { useGroupedPriceData } from 'page/Price/components/useGroupedPriceData';
import DataGrid from 'shared/component/DataGrid';
import { DataGridPropsT } from 'types/DataGrid';
import { NestedTablePricesT } from 'types/NestedTablePrices';

type PropsT = {
    detailPanelColumns: GridColumns;
    loading: boolean;
    multipleDeleteAction?: Function;
    multipleRefreshAction: Function;
    exportData: () => void;
    nestedTableProps: NestedTablePricesT;
} & DataGridPropsT;

const CategoryGrid: FC<PropsT> = observer((props) => {
    const { data: { rows, count } } = useGroupedPriceData();
    const headerData = useCategoryHeaderData();

    return (
        <DataGrid
            { ...props }
            rows={ rows }
            rowCount={ count }
            columns={ headerData }
        />
    );
});

export default memo(CategoryGrid);
