import { PAGE_TITLE_EMPLOYEE } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useEmployees from 'shared/data-hook/employee/fetch/fetchAll/useEmployees';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateEmployeeModal from './components/CreateEmployeeModal';
import EmployeeListComponent from './components/EmployeeListComponent';
import UpdateEmployeeModal from './components/UpdateEmployeeModal';
import { EMPLOYEE_HEADER_DATA } from './domain/HeaderData';

const EmployeeList: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const {
        data: employeesData,
        loading: employeesLoading,
        count: rowCount,
        fetchEmployees,
    } = useEmployees();

    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createEmployeeModal');
    const { setEmployees } = useStore('employee');

    useFetchDataForGrid(
        sortModel,
        filterModel,
        page,
        pageSize,
        fetchEmployees,
        refetchTrigger,
        EMPLOYEE_HEADER_DATA,
    );
    useEffect(() => {
        setEmployees(employeesData, rowCount);
    }, [employeesData, employeesLoading]);

    usePageTitle(PAGE_TITLE_EMPLOYEE);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => onOpen() }
                    startIcon={ <AddIcon /> }
                >
                    добавить
                </Button>
            </ControlPanel>
            <EmployeeListComponent
                loading={ employeesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateEmployeeModal />
            <UpdateEmployeeModal />
        </StyledPageWrap>
    );
});

export default EmployeeList;
