import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareWaitingListHeaderData } from '../domain/prepareWaitingListHeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const WaitingListComponent: FC<PropsT> = observer((props) => {
    const { waitingLists, count } = useStore('waitingList');
    const columns = prepareWaitingListHeaderData();

    return (
        <DataGrid
            rows={ waitingLists }
            rowCount={ count }
            columns={ columns }
            { ...props }
        />
    );
});

export default WaitingListComponent;
