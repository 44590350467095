import React, { FC } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import DateTimePicker from 'shared/component/Form/DateTimePicker';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import ClientSelect from 'shared/feature/select/ClientSelect';
import ImplementationDocumentSelect from 'shared/feature/select/ImplementationDocumentSelect';
import UserSelect from 'shared/feature/select/UserSelect';
import { WaitingListT } from 'types/WaitingList';

import {
    WAITING_LIST_FIELDS_KEYS,
    WAITING_LIST_LABEL_DATA,
    WaitingListFormFields,
} from './formData';
import useSetWaitingListForm from './useSetWaitingListForm';

type PropsT = {
    control: Control<WaitingListFormFields>;
    setValue: UseFormSetValue<WaitingListFormFields>;
    waitingList?: WaitingListT;
    isUpdate?: boolean;
};

const WaitingListForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, waitingList, isUpdate,
    } = props;

    const {
        groupOptions,
        organizationOptions,
        serviceCategoryOptions,
        coachOptions,
        courtOptions,
        priceTypeOptions,
        coaches,
        courts,
    } = useSetWaitingListForm(
        control,
        isUpdate,
        waitingList,
        setValue,
    );

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <ClientSelect
                    name={ WAITING_LIST_FIELDS_KEYS.client as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.client] }
                />
            </Grid2>
            <Grid2>
                <ImplementationDocumentSelect
                    name={ WAITING_LIST_FIELDS_KEYS.groupDocument as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.groupDocument] }
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ organizationOptions }
                    name={ WAITING_LIST_FIELDS_KEYS.organization as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.organization] }
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ groupOptions }
                    name={ WAITING_LIST_FIELDS_KEYS.group as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.group] }
                    empty
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ serviceCategoryOptions }
                    name={ WAITING_LIST_FIELDS_KEYS.serviceCategory as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.serviceCategory] }
                    empty
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ priceTypeOptions }
                    name={ WAITING_LIST_FIELDS_KEYS.priceType as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.priceType] }
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ priceTypeOptions }
                    name={ WAITING_LIST_FIELDS_KEYS.coachPriceType as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.coachPriceType] }
                    empty
                />
            </Grid2>
            <Grid2>
                <MultipleSelect
                    control={ control }
                    options={ coachOptions }
                    name={ WAITING_LIST_FIELDS_KEYS.coaches }
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.coaches] }
                    selectedValues={ coaches?.value }
                    size="small"
                />
            </Grid2>
            <Grid2>
                <MultipleSelect
                    control={ control }
                    options={ courtOptions }
                    name={ WAITING_LIST_FIELDS_KEYS.courts }
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.courts] }
                    selectedValues={ courts?.value }
                    size="small"
                />
            </Grid2>
            <Grid2>
                <UserSelect
                    name={ WAITING_LIST_FIELDS_KEYS.user as keyof WaitingListFormFields }
                    control={ control }
                    size="small"
                    label={ WAITING_LIST_LABEL_DATA[WAITING_LIST_FIELDS_KEYS.user] }
                />
            </Grid2>
            <Grid2>
                <TextField
                    control={ control }
                    name={ WAITING_LIST_FIELDS_KEYS.duration }
                    label={ WAITING_LIST_LABEL_DATA.duration }
                    type="number"
                    size="small"
                />
            </Grid2>
            <Grid2>
                <TextField
                    control={ control }
                    name={ WAITING_LIST_FIELDS_KEYS.comment }
                    label={ WAITING_LIST_LABEL_DATA.comment }
                    type="text"
                    multiline={ true }
                />
            </Grid2>
            <Grid2>
                <Switch
                    name={ WAITING_LIST_FIELDS_KEYS.topPriority }
                    control={ control }
                    label={ WAITING_LIST_LABEL_DATA.topPriority }
                />
            </Grid2>
            <Grid2>
                <DateTimePicker
                    fullWidth
                    setValue={ setValue }
                    value={ dayjs(waitingList?.datetime) }
                    control={ control }
                    label={ WAITING_LIST_LABEL_DATA.datetime }
                    name={ WAITING_LIST_FIELDS_KEYS.datetime }
                    customError
                />
            </Grid2>
            <Grid2>
                <DateTimePicker
                    fullWidth
                    setValue={ setValue }
                    value={ dayjs(waitingList?.createdAt) }
                    control={ control }
                    label={ WAITING_LIST_LABEL_DATA.createdAt }
                    name={ WAITING_LIST_FIELDS_KEYS.createdAt }
                    customError
                />
            </Grid2>
        </Grid2>
    );
});

export default WaitingListForm;
