import { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import { useStore } from 'store';

type PropsT = {
    id: string;
};

const DocumentEditButton: FC<PropsT> = (props) => {
    const { id } = props;
    const { onOpen: handleOpenModal } = useStore('updateServiceAppDocumentModal');

    return (
        <Tooltip title="Редактировать" placement="top">
            <IconButton onClick={ () => handleOpenModal(id) }>
                <EditIcon color="primary" />
            </IconButton>
        </Tooltip>
    );
};

export default DocumentEditButton;
