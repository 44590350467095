import { gql } from '@apollo/client';
import { CourtScheduleItem } from 'types/Court';

export type CourtCreatePayloadT = {
    name: string;
    order: number;
    organization_location_id: string;
    type_id: string;
    bonus_classes: boolean;
    schedule: CourtScheduleItem[];
    organization_id: string | null;
    camera_id: string | null;
    enabled_at_months: string[];
    schedule_slot_mode: string | null;
};

export type CourtCreateResponseDataT = {
    createCourt: {
        id: string;
    };
} | null | undefined;

export type CourtCreateVarT = {
    object: CourtCreatePayloadT;
};

export const COURT_CREATE = gql`
    mutation CourtCreate($object: court_insert_input = {}) {
        createCourt: insert_court_one(
            object: $object
        ) {
            id
        }
    }
`;
