// eslint-disable-next-line import/no-named-default
import { default as BasePhoneInput } from 'react-phone-input-2';
import { styled } from '@mui/material';

export const PhoneInput = styled(BasePhoneInput)`
    .special-label {
        display: none;
    }

    .form-control {
        width: 100%;
        padding: 10px 14px 8px 58px;
        font-family: inherit;
    }

    .country-list {
        position: fixed;
        max-height: 250px;
    }

    .arrow {
        display: none;
    }

    .selected-flag {
        display: flex;
        align-items: center;
        justify-content: center;

        .flag {
            position: static;
            margin-top: 0;
        }
    }
`;
