import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientDataDTO } from 'shared/data-hook/utils/DTO/Client';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { WaitingListT } from 'types/WaitingList';

const DTO = t.type({
    id: t.string,
    client_datum: ClientDataDTO,
    group: Nullable(t.type({
        id: t.string,
        name: t.string,
    })),
    implementation_document: Nullable(t.type({
        id: t.string,
        number: t.string,
    })),
    organization: t.type({
        id: t.string,
        name: t.string,
    }),
    service_category: t.type({
        id: t.string,
        name: t.string,
    }),
    coach_price_type: Nullable(t.type({
        id: t.string,
        title: t.string,
    })),
    price_type: t.type({
        id: t.string,
        title: t.string,
    }),
    user: t.type({
        id: t.string,
        full_name: t.string,
    }),
    duration: t.number,
    datetime: t.string,
    top_priority: Nullable(t.boolean),
    comment: Nullable(t.string),
    created_at: t.string,
    is_archive: Nullable(t.boolean),
    waiting_list_courts: t.array(t.type({
        court: t.type({
            id: t.string,
            name: t.string,
        }),
    })),
    waiting_list_coaches: t.array(t.type({
        coach: t.type({
            employee: t.type({
                id: t.string,
                full_name: t.string,
            }),
        }),
    })),
});

export type WaitingListDTOT = t.TypeOf<typeof DTO>;

export const WaitingListDTO = tPromise.extendDecoder<WaitingListDTOT, WaitingListT>(
    DTO,
    ({
        id, client_datum, group, implementation_document, organization, service_category, coach_price_type,
        price_type, user, duration, datetime, top_priority, comment, created_at, is_archive, waiting_list_courts, waiting_list_coaches,
    }) => ({
        id,
        client: client_datum?.client ?? null,
        duration,
        datetime: new Date(datetime),
        topPriority: top_priority ?? false,
        comment: comment ?? undefined,
        createdAt: new Date(created_at),
        isArchive: is_archive ?? false,
        courts: waiting_list_courts.map(({ court }) => ({
            value: court.id,
            label: court.name,
        })),
        coaches: waiting_list_coaches.map(({ coach: { employee } }) => ({
            value: employee.id,
            label: employee.full_name,
        })),
        group: group ? { value: group?.id, label: group?.name } : undefined,
        groupDocument: implementation_document ? { value: implementation_document?.id, label: implementation_document?.number } : undefined,
        organization: { value: organization?.id, label: organization?.name },
        coachPriceType: coach_price_type ? { value: coach_price_type?.id, label: coach_price_type?.title } : undefined,
        priceType: { value: price_type?.id, label: price_type?.title },
        user: { value: user?.id, label: user?.full_name },
        serviceCategory: service_category ? { value: service_category?.id, label: service_category?.name } : undefined,
    }),
) as t.Type<WaitingListT, WaitingListDTOT>;
