import {
    PROPERTY_ACTIONS,
    PROPERTY_IS_ACTIVE,
    PROPERTY_POSITION, PROPERTY_SERVICE_APP,
    PROPERTY_TITLE,
    PROPERTY_URL,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DocumentEditButton from 'page/ServiceAppDocuments/components/DocumentEditButton';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeleteServiceAppDocument from 'shared/hook/serviceAppDocument/delete/useDeleteServiceAppDocument';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const {
        row: { id, title },
    } = props;
    const { documentDelete } = useDeleteServiceAppDocument();

    return (
        <>
            <DocumentEditButton id={ id } />
            <DeleteButton id={ id } title={ title } actionDelete={ documentDelete } />
        </>
    );
};

export const SERVICE_APP_DOCUMENT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_POSITION,
        field: 'position',
        minWidth: 150,
        align: 'left',
        headerAlign: 'left',
        type: 'number',
    },
    {
        headerName: PROPERTY_TITLE,
        field: 'title',
        minWidth: 400,
        align: 'left',
        headerAlign: 'left',
        type: 'string',
    },
    {
        headerName: PROPERTY_URL,
        field: 'url',
        minWidth: 350,
        align: 'left',
        headerAlign: 'left',
        type: 'string',
    },
    {
        headerName: PROPERTY_SERVICE_APP,
        field: 'serviceApp',
        minWidth: 350,
        align: 'left',
        headerAlign: 'left',
        type: 'singleSelect',
        hasuraFilterFields: ['service_app', 'label'],
        hasuraSortFields: ['service_app', 'label'],
        renderCell: (({ row }) => row.serviceApp.label),
    },
    {
        headerName: PROPERTY_IS_ACTIVE,
        field: 'isActive',
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        renderCell: isActiveIcon,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
