import {
    ERROR_WAITING_LIST_ARCHIVE,
    SUCCESS_WAITING_LIST_ARCHIVE,
} from 'constants/notificationMessage';

import useWaitingListArchive from 'shared/data-hook/waitingList/archive/useWaitingListArchive';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useArchiveWaitingList = () => {
    const { error, waitingListArchive, loading } = useWaitingListArchive();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const archiveWaitingList = async (id: string) => {
        const response = await waitingListArchive(id);

        if (error || !response) {
            showError(ERROR_WAITING_LIST_ARCHIVE);
        } else {
            showSuccess(SUCCESS_WAITING_LIST_ARCHIVE);
        }

        toggleRefetchTrigger();
    };

    return {
        archiveWaitingList,
        loading,
    };
};

export default useArchiveWaitingList;
