import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { SERVICE_APP_DOCUMENT_HEADER_DATA } from 'page/ServiceAppDocuments/domain/HeaderData';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const DocumentList: FC<PropsT> = observer((props) => {
    const { documents, count } = useStore('serviceAppDocument');

    return (
        <DataGrid
            { ...props }
            rows={ documents }
            rowCount={ count }
            columns={ SERVICE_APP_DOCUMENT_HEADER_DATA }
        />
    );
});

export default DocumentList;
