export type ServiceAppDocumentT = {
    id: string;
    serviceApp: {
        id: string;
        label: string;
    };
    title: string;
    url: string;
    isActive: boolean;
    position: number;
};

export type ServiceAppDocumentsT = {
    documents: ServiceAppDocumentT[];
    documentsCount: number;
};

export const EMPTY_SERVICE_APP_DOCUMENTS_RESULT: ServiceAppDocumentsT = {
    documents: [],
    documentsCount: 0,
};

export const EMPTY_SERVICE_APP_DOCUMENT_RESULT: ServiceAppDocumentT = {
    id: '',
    serviceApp: {
        id: '',
        label: '',
    },
    title: '',
    url: '',
    isActive: false,
    position: 0,
};
