import React, { FC, useState } from 'react';
import { Control, useController, useFieldArray } from 'react-hook-form';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Collapse, ListItemButton, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import MultipleSelect, { OptionsType } from 'shared/component/Form/MultipleSelect';
import Switch from 'shared/component/Form/Switch';

import {
    GROUP_ROLE_PERMISSIONS, INDIVIDUAL_ROLE_PERMISSIONS,
    PRICE_SALE_ROUND_ROLE_PERMISSION_LABEL_DATA,
    PRICE_SALES_ROUND_LABEL_DATA,
    PriceSalesRoundFormFields,
    PriceSalesRoundRolePermissionFormFields,
} from './formData';

type PropsT = {
    control: Control<PriceSalesRoundFormFields>;
    loadingCourt: boolean;
    courtFilterOptions: OptionsType[];
};

const PriceSaleRoundRolePermissionForm: FC<PropsT> = observer((props) => {
    const { control, loadingCourt, courtFilterOptions } = props;
    const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});

    const handleOpenSetting = (id: string) => {
        setIsOpen((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const { fields } = useFieldArray<PriceSalesRoundFormFields>({
        control,
        name: 'priceSaleRoundRolePermissions',
    });

    const controllers = fields.map((rolePermission, index) => {
        return useController({
            control,
            name: `priceSaleRoundRolePermissions.${index}.entryIndividualCreateAllowedCourtIds`,
            defaultValue: rolePermission.entryIndividualCreateAllowedCourtIds,
        });
    });

    return (
        <Grid2>
            { fields.map((rolePermission, index) => {
                const { field: entryIndividualCreateAllowedCourtIdsField } = controllers[index];

                return (
                    <Grid2 key={ rolePermission.id } spacing={ 2 }>
                        <ListItemButton onClick={ () => handleOpenSetting(rolePermission.id) }>
                            <Typography variant="h6">{ PRICE_SALES_ROUND_LABEL_DATA.priceSaleRoundRolePermissions } { rolePermission.role }</Typography>
                            { isOpen[rolePermission.id] ? <ExpandLess /> : <ExpandMore /> }
                        </ListItemButton>
                        <Collapse in={ isOpen[rolePermission.id] }>
                            <Grid2 sx={ { display: 'flex' } }>
                                <Grid2 container sx={ { width: '100%' } } p={ 2 } flexDirection="column">
                                    <Typography variant="h6">Группы</Typography>
                                    { GROUP_ROLE_PERMISSIONS.map((groupPermission) => (
                                        <Switch
                                            key={ groupPermission }
                                            name={ `priceSaleRoundRolePermissions.${index}.${groupPermission}` }
                                            label={ PRICE_SALE_ROUND_ROLE_PERMISSION_LABEL_DATA[groupPermission as keyof PriceSalesRoundRolePermissionFormFields as never] }
                                            control={ control }
                                        />
                                    )) }
                                </Grid2>
                                <Grid2 container sx={ { width: '100%' } } p={ 2 } flexDirection="column">
                                    <Typography variant="h6">Идивидуально</Typography>
                                    { INDIVIDUAL_ROLE_PERMISSIONS.map((individualPermission) => (
                                        <Switch
                                            key={ individualPermission }
                                            name={ `priceSaleRoundRolePermissions.${index}.${individualPermission}` }
                                            label={ PRICE_SALE_ROUND_ROLE_PERMISSION_LABEL_DATA[individualPermission as keyof PriceSalesRoundRolePermissionFormFields as never] }
                                            control={ control }
                                        />
                                    )) }
                                    <MultipleSelect
                                        name={ `priceSaleRoundRolePermissions.${index}.entryIndividualCreateAllowedCourtIds` }
                                        label={ PRICE_SALE_ROUND_ROLE_PERMISSION_LABEL_DATA.entryIndividualCreateAllowedCourtIds }
                                        options={ courtFilterOptions }
                                        selectedValues={ entryIndividualCreateAllowedCourtIdsField.value }
                                        loading={ loadingCourt }
                                        control={ control }
                                    />
                                </Grid2>
                            </Grid2>
                        </Collapse>
                    </Grid2>
                );
            }) }
        </Grid2>
    );
});

export default PriceSaleRoundRolePermissionForm;
