import { MODAL_TITLE_UPDATE_EMPLOYEE } from 'constants/modalTitles';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchEmployee from 'shared/hook/employee/fetch/fetchOne/useFetchEmployee';
import useUpdateEmployee from 'shared/hook/employee/update/useUpdateEmployee';
import { useStore } from 'store';

import EmployeeForm from './EmployeeForm';
import { EMPLOYEE_DEFAULT_VALUES, EmployeeFormFields, getEmployeeValidationSchema } from './formData';

const UpdateEmployeeModal: FC = observer(() => {
    const {
        isOpen, onClose, employee, itemId: employeeId,
    } = useStore('updateEmployeeModal');
    const { loading: loadingFetch } = useFetchEmployee(employeeId);
    const { updateEmployee, loading: loadingUpdate } = useUpdateEmployee();
    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<EmployeeFormFields>({
        defaultValues: EMPLOYEE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getEmployeeValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateEmployee = async (data: EmployeeFormFields) => {
        await updateEmployee(data, employeeId);
        handleCloseModal();
    };

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_UPDATE_EMPLOYEE }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch && <Spinner /> }
                <EmployeeForm
                    control={ control }
                    setValue={ setValue }
                    employee={ employee }
                    isUpdate
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => {
                        reset(employee);
                    } }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateEmployee) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ loadingUpdate }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateEmployeeModal;
