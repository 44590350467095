import { useLazyQuery } from '@apollo/client';
import { ServiceAppDocumentDTO } from 'shared/data-hook/serviceAppDocument/fetch/DTO/ServiceAppDocumentDTO';
import { ServiceAppDocumentDataT } from 'shared/data-hook/serviceAppDocument/fetch/fetchOne/ServiceAppDocumentData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    SERVICE_APP_DOCUMENT_QUERY,
    ServiceAppDocumentResponseT,
} from 'shared/graphql/query/serviceAppDocument/serviceAppDocumentQuery';
import { EMPTY_SERVICE_APP_DOCUMENT_RESULT, ServiceAppDocumentT } from 'types/ServiceAppDocument';

const useServiceAppDocument = (): ServiceAppDocumentDataT => {
    const [fetch, { loading, error }] = useLazyQuery<ServiceAppDocumentResponseT>(
        SERVICE_APP_DOCUMENT_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchDocument = async (id: string): Promise<ServiceAppDocumentT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                ServiceAppDocumentDTO,
                EMPTY_SERVICE_APP_DOCUMENT_RESULT,
                (document) => document,
                data?.document,
            );
        } catch {
            return EMPTY_SERVICE_APP_DOCUMENT_RESULT;
        }
    };

    return {
        fetchDocument,
        loading,
        error,
    };
};

export default useServiceAppDocument;
