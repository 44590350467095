import { gql } from '@apollo/client';
import { GroupCategoryT } from 'types/GroupCategory';

export type GroupCategoryDeleteResponseDataT = {
    delete_group_category: {
        id: GroupCategoryT['id'];
    };
} | null | undefined;

export type GroupCategoryDeleteVarT = {
    id: GroupCategoryT['id'];
}

export const GROUP_CATEGORY_DELETE = gql`
    mutation GroupCategoryDelete($id: String!) {
        delete_group_category: delete_group_category_by_pk(id: $id) {
            id
        }
    }
`;
