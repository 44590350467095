import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { createApolloClient } from 'config/Apollo';

import { theme } from './config/Theme';
import App from './App';

import './assets/fonts/index.css';
import 'react-phone-input-2/lib/material.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);
const apolloClient = createApolloClient();

ReactDOM.render(
    <ThemeProvider theme={ theme }>
        <CssBaseline />
        <BrowserRouter>
            <ApolloProvider client={ apolloClient }>
                <App />
            </ApolloProvider>
        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById('root'),
);
