import { EMPLOYEE_TYPES_OPTIONS } from 'constants/employeeTypes';

import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import PhoneField from 'shared/component/Form/PhoneField';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import { EmployeeT } from 'types/Employee';

import {
    EMPLOYEE_FIELD_KEYS,
    EMPLOYEE_LABEL_DATA,
    EmployeeFormFields,
} from './formData';

type PropsT = {
    control: Control<EmployeeFormFields>;
    setValue?: UseFormSetValue<EmployeeFormFields>;
    employee?: EmployeeT;
    isUpdate?: boolean;
};

const EmployeeForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, employee, isUpdate,
    } = props;

    useEffect(() => {
        if (!isUpdate || !employee || !setValue) {
            return;
        }

        Object.keys(employee).forEach((key) => {
            if (key === EMPLOYEE_FIELD_KEYS.type) {
                const selectedOption = EMPLOYEE_TYPES_OPTIONS.find((option) => option.label === employee[key]);

                if (selectedOption) {
                    setValue(key as keyof EmployeeFormFields, selectedOption.value);
                }
            } else {
                setValue(key as keyof EmployeeFormFields, employee[key as keyof EmployeeFormFields as never]);
            }
        });
    }, [employee, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ EMPLOYEE_FIELD_KEYS.fullName }
                    label={ EMPLOYEE_LABEL_DATA.fullName }
                    type="text"
                />
            </Grid2>
            <Grid2>
                <PhoneField
                    size="small"
                    control={ control }
                    name={ EMPLOYEE_FIELD_KEYS.phone }
                    label={ EMPLOYEE_LABEL_DATA.phone }
                    customError
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ EMPLOYEE_FIELD_KEYS.email }
                    label={ EMPLOYEE_LABEL_DATA.email }
                    type="email"
                    customError
                />
            </Grid2>
            <Grid2>
                <Select
                    name={ EMPLOYEE_FIELD_KEYS.type }
                    control={ control }
                    label={ EMPLOYEE_LABEL_DATA.type }
                    options={ EMPLOYEE_TYPES_OPTIONS }
                />
            </Grid2>
        </Grid2>
    );
});

export default EmployeeForm;
