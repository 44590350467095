import { EMPTY_OPTION } from 'constants/emptyOption';

import { ClientFullNameT } from './Client';
import { OptionT } from './Option';

export type WaitingListT = {
    id: string;
    client: ClientFullNameT | null;
    group?: OptionT;
    groupDocument?: OptionT;
    organization: OptionT;
    serviceCategory?: OptionT;
    priceType: OptionT;
    coachPriceType?: OptionT;
    user: OptionT;
    duration: number;
    datetime: Date;
    topPriority?: boolean;
    comment?: string;
    createdAt: Date;
    isArchive?: boolean;
    courts: OptionT[];
    coaches: OptionT[];
};

export type WaitingListsT = {
    waitingLists: WaitingListT[];
    waitingListsCount: number;
};

export const EMPTY_WAITING_LISTS_RESULT: WaitingListsT = {
    waitingLists: [],
    waitingListsCount: 0,
};

export const EMPTY_WAITING_LIST_RESULT: WaitingListT = {
    id: '',
    client: null,
    group: EMPTY_OPTION,
    groupDocument: EMPTY_OPTION,
    organization: EMPTY_OPTION,
    serviceCategory: EMPTY_OPTION,
    priceType: EMPTY_OPTION,
    coachPriceType: EMPTY_OPTION,
    user: EMPTY_OPTION,
    duration: 0,
    datetime: new Date(),
    topPriority: false,
    comment: '',
    createdAt: new Date(),
    isArchive: false,
    courts: [],
    coaches: [],
};
