import { useEffect } from 'react';
import useServiceAppDocument from 'shared/data-hook/serviceAppDocument/fetch/fetchOne/useServiceAppDocument';
import { useStore } from 'store';

const useFetchServiceAppDocument = (id: string) => {
    const { fetchDocument, loading } = useServiceAppDocument();
    const { setDocument } = useStore('updateServiceAppDocumentModal');

    useEffect(() => {
        fetchDocument(id).then((fetchedDocument) => {
            setDocument(fetchedDocument);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchServiceAppDocument;
