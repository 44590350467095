import { BONUS } from 'constants/currency';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_ACTIONS,
    PROPERTY_BONUSES_SUM, PROPERTY_CLIENT_FIRST_NAME,
    PROPERTY_DATETIME_OPERATION,
    PROPERTY_DOCUMENT,
    PROPERTY_FIAT_SUM, PROPERTY_IS_ARCHIVE,
    PROPERTY_ORGANIZATION,
    PROPERTY_PAYMENT_METHOD,
    PROPERTY_PAYMENT_TYPE,
    PROPERTY_PERFORMER, PROPERTY_TRANSACTION_SUM,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import ArchiveButton from 'shared/component/Button/ArchiveButton/ArchiveButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useArchivePaymentDocument from 'shared/hook/paymentDocument/archive/useArchivePaymentDocument';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const renderCost = (value: number, currency?: string) => {
    const { serviceApp } = useStore('serviceApp');

    return (
        <span>{ value } { currency ?? serviceApp.fiatCurrencyTitle }</span>
    );
};

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { paymentDocument } } = props;
    const { id: paymentDocumentId } = paymentDocument;
    const { onOpen } = useStore('updatePaymentDocumentModal');
    const { archivePaymentDocument } = useArchivePaymentDocument();

    const handleArchive = async () => {
        await archivePaymentDocument({ paymentDocumentId });
    };

    return (
        <>
            <IconButton onClick={ () => onOpen(paymentDocumentId) }>
                <EditIcon color="primary" />
            </IconButton>
            { !paymentDocument.isArchive && <ArchiveButton id={ paymentDocumentId } datetime={ paymentDocument.datetime } title={ paymentDocument.number } actionArchive={ handleArchive } /> }
        </>
    );
};

export const PAYMENT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME,
        field: 'clientDatum.fullName',
        minWidth: 525,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: [
            'client_datum', 'client', 'full_name',
        ],
        hasuraFilterFields: [
            'client_datum', 'client', 'full_name',
        ],
        renderCell: (params) =>
            renderStringCell(`${params.row?.clientDatum.fullName}`),
    },
    {
        headerName: PROPERTY_DOCUMENT,
        field: 'paymentDocument',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['payment_document', 'number'],
        hasuraFilterFields: ['payment_document', 'number'],
        renderCell: (params) => renderStringCell(params.row?.paymentDocument.number),
    },
    {
        headerName: PROPERTY_DATETIME_OPERATION,
        field: 'datetime',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraSortFields: ['datetime'],
        hasuraFilterFields: ['datetime'],
        renderCell: (params) => renderDateToGrid(params.row?.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_PAYMENT_METHOD,
        field: 'paymentMethod',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['payment_method', 'title'],
        hasuraFilterFields: ['payment_method', 'title'],
        renderCell: (params) => renderStringCell(params.row?.paymentMethod?.title),
    },
    {
        headerName: PROPERTY_PERFORMER,
        field: 'performer',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['performer'],
        hasuraFilterFields: ['performer'],
        renderCell: (params) => renderStringCell(params.row?.performer),
    },
    {
        headerName: PROPERTY_ORGANIZATION,
        field: 'organizationLocation',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['organization_location', 'name'],
        hasuraFilterFields: ['organization_location', 'name'],
        renderCell: (params) => renderStringCell(params.row?.organizationLocation?.name),
    },
    {
        headerName: PROPERTY_PAYMENT_TYPE,
        field: 'paymentType',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['payment_type', 'title'],
        hasuraFilterFields: ['payment_type', 'title'],
        renderCell: (params) => renderStringCell(params.row?.paymentType?.title),
    },
    {
        headerName: PROPERTY_FIAT_SUM,
        field: 'paymentInFiat',
        minWidth: 75,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraSortFields: ['payment_in_fiat'],
        hasuraFilterFields: ['payment_in_fiat'],
        renderCell: (params) => renderCost(params.row?.paymentInFiat),
    },
    {
        headerName: PROPERTY_BONUSES_SUM,
        field: 'paymentInBonus',
        minWidth: 75,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraSortFields: ['payment_in_bonus'],
        hasuraFilterFields: ['payment_in_bonus'],
        renderCell: (params) => renderCost(params.row?.paymentInBonus, BONUS),
    },
    {
        headerName: PROPERTY_TRANSACTION_SUM,
        field: 'total',
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraSortFields: ['total'],
        hasuraFilterFields: ['total'],
        renderCell: (params) => renderCost(params.row?.total),
    },
    {
        headerName: PROPERTY_IS_ARCHIVE,
        field: 'paymentDocument.isArchive',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        renderCell: (params) => isActiveIcon({ ...params, value: params.row?.paymentDocument.isArchive }),
        hasuraFilterFields: ['payment_document', 'is_archive'],
        hasuraSortFields: ['payment_document', 'is_archive'],
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => RenderActionCell(params),
    },
];
