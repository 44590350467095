import { PAGE_TITLE_COACH, PAGE_TITLE_GROUP_VIEW, PAGE_TITLE_PAYMENT_TYPE } from 'constants/pageTitles';

import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
    Box, Button, Paper, Stack, Typography,
} from '@mui/material';
import {
    DataGridPremium, GridColumns, GridValidRowModel, ruRU,
} from '@mui/x-data-grid-premium';
import NestedTablePrices from 'page/Price/components/TabContext/ByCategory/NestedTablePrices';
import { useStore } from 'store';
import { NestedTablePricesT } from 'types/NestedTablePrices';

type DataType = GridValidRowModel;

interface DetailPanelContentProps {
    row: DataType;
    columns: GridColumns;
    nestedTableProps?: NestedTablePricesT;
}

export const DetailPanelContent: FC<DetailPanelContentProps> = ({
    row: rowProp, columns, nestedTableProps,
}) => {
    const { onOpen: courtCreateModal } = useStore('createCourtModal');
    const { onOpen: paymentTypeCreateModal } = useStore('createPaymentType');
    const { onOpen: priceSalesRoundCreateModal } = useStore('createPriceSalesRound');

    return (
        <Stack sx={ { py: 1, boxSizing: 'border-box' } } direction="column">
            <Paper sx={ { mx: 'auto', width: '90%', p: 1 } }>
                <Stack direction="column" spacing={ 1 } sx={ { height: 1 } }>
                    { 'priceSalesRounds' in rowProp && (
                        <>
                            <Typography variant="h6">Раунды продаж</Typography>
                            <Box>
                                <Button variant="outlined" size="small" onClick={ () => priceSalesRoundCreateModal(rowProp.id) } startIcon={ <AddIcon /> }>
                                    Добавить
                                </Button>
                            </Box>
                            <DataGridPremium
                                density="compact"
                                autoHeight
                                columns={ columns }
                                rows={ rowProp.priceSalesRounds }
                                hideFooter
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                    { 'courts' in rowProp && (
                        <>
                            <Typography variant="h6">Корты</Typography>
                            <Box>
                                <Button variant="outlined" size="small" onClick={ () => courtCreateModal(rowProp.id) } startIcon={ <AddIcon /> }>
                                    Добавить
                                </Button>
                            </Box>
                            <DataGridPremium
                                density="comfortable"
                                autoHeight
                                rows={ rowProp.courts }
                                hideFooter
                                columns={ columns }
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                    { 'paymentTypes' in rowProp && (
                        <>
                            <Typography variant="h6">{ PAGE_TITLE_PAYMENT_TYPE }</Typography>
                            <Box>
                                <Button variant="outlined" size="small" onClick={ () => paymentTypeCreateModal(rowProp.id) } startIcon={ <AddIcon /> }>
                                    Добавить
                                </Button>
                            </Box>
                            <DataGridPremium
                                density="compact"
                                autoHeight
                                columns={ columns }
                                rows={ rowProp.paymentTypes }
                                hideFooter
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                    { 'coaches' in rowProp && (
                        <>
                            <Typography variant="h6">{ PAGE_TITLE_COACH }</Typography>
                            <DataGridPremium
                                density="comfortable"
                                autoHeight
                                columns={ columns }
                                rows={ rowProp.coaches }
                                hideFooter
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                    { 'prices' in rowProp &&
                        (rowProp.prices.length > 0 && nestedTableProps ? (
                            <NestedTablePrices
                                rows={ rowProp.prices }
                                columns={ columns }
                                dataGridProps={ nestedTableProps }
                            />
                        ) : (
                            <Typography>Нет цен</Typography>
                        )) }
                    { 'groupViews' in rowProp && (
                        <>
                            <Typography variant="h6">{ PAGE_TITLE_GROUP_VIEW }</Typography>
                            <DataGridPremium
                                density="compact"
                                autoHeight
                                getRowHeight={ () => 'auto' }
                                columns={ columns }
                                rows={ rowProp.groupViews }
                                hideFooter
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                </Stack>
            </Paper>
        </Stack>
    );
};
