import { MODAL_TITLE_CREATE_SCHEDULE } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import Switch from 'shared/component/Form/Switch';
import { Modal } from 'shared/component/Modal/index';
import { PRICE_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/priceType/PriceTypeQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useScheduleCreate from 'shared/hook/schedule/useScheduleCreate';
import { useStore } from 'store';

import ModalTitle from '../ModalTitle';

import {
    CREATE_SCHEDULE_FORM_DEFAULT_VALUES, CREATE_SCHEDULE_FORM_FIELD_KEYS, CREATE_SCHEDULE_FORM_LABEL_DATA, CREATE_SCHEDULE_FORM_VALIDATION_SCHEMA, CreateScheduleFormFields,
} from './formData';

const CreateScheduleModal: FC = observer(() => {
    const { isOpen, itemId: priceSeasonId, onClose } = useStore('createScheduleModal');
    const {
        control, handleSubmit, reset, formState: { isDirty, isValid }, setValue,
    } = useForm<CreateScheduleFormFields>({
        defaultValues: CREATE_SCHEDULE_FORM_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(CREATE_SCHEDULE_FORM_VALIDATION_SCHEMA),
    });
    const { getFilterOption } = useStore('filterOptions');
    const { createSchedule, loading: scheduleCreateLoading } = useScheduleCreate();
    const { loading: filterOptionsLoading } = useFetchFilterOptions(PRICE_TYPE_FILTER_OPTIONS_QUERY, 'priceType');
    const priceTypes = getFilterOption('priceType');

    const { field: priceTypeIdsField } = useController({ control, name: CREATE_SCHEDULE_FORM_FIELD_KEYS.priceTypeIds });

    useEffect(() => {
        if (!priceSeasonId) {
            return;
        }

        setValue(CREATE_SCHEDULE_FORM_FIELD_KEYS.priceSeasonId, priceSeasonId);
    }, [priceSeasonId]);

    const handleClose = () => {
        onClose();
        reset();
    };

    const handleCreateSchedule = async (data: CreateScheduleFormFields) => {
        createSchedule(data)
            .then(handleClose);
    };

    return (
        <Modal open={ isOpen } maxWidth="xs">
            <ModalTitle onClose={ handleClose }>{ MODAL_TITLE_CREATE_SCHEDULE }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 container spacing={ 2 } padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <MultipleSelect
                            control={ control }
                            label={ CREATE_SCHEDULE_FORM_LABEL_DATA.priceTypeIds }
                            name={ CREATE_SCHEDULE_FORM_FIELD_KEYS.priceTypeIds }
                            options={ priceTypes }
                            selectedValues={ priceTypeIdsField.value }
                            loading={ filterOptionsLoading }
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Switch
                            label={ CREATE_SCHEDULE_FORM_LABEL_DATA.recreateSlots }
                            name={ CREATE_SCHEDULE_FORM_FIELD_KEYS.recreateSlots }
                            control={ control }
                            type="boolean"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Switch
                            label={ CREATE_SCHEDULE_FORM_LABEL_DATA.recreateSlotRates }
                            name={ CREATE_SCHEDULE_FORM_FIELD_KEYS.recreateSlotRates }
                            control={ control }
                            type="boolean"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={ handleClose }>
                    Отменить
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateSchedule) }
                    autoFocus
                    color="success"
                    disabled={ !isValid || !isDirty }
                    loading={ scheduleCreateLoading }
                >
                    Запустить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateScheduleModal;
