import { EmployeeType } from 'constants/employeeTypes';
import { PAGE_TITLE_IMPLEMENTATION } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useClients from 'shared/data-hook/client/fetch/fetchAll/useClients';
import useImplementations from 'shared/data-hook/implementation/fetch/fetchAll/useImplementations';
import { COURT_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/court/CourtQuery';
import { EMPLOYEE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/employee/EmployeeQuery';
import { GROUP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/group/GroupQuery';
import { ORGANIZATION_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/organization/OrganizationQuery';
import { PRICE_SALES_ROUND_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/priceSalesRound/PriceSalesRoundQuery';
import { PRICE_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/priceType/PriceTypeQuery';
import { SERVICE_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import ImplementationList from './components/ImplementationList';
import UpdateImplementationModal from './components/UpdateImplementationModal';
import { IMPLEMENTATION_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const {
        data: implementationsData,
        loading: implementationsLoading,
        count: rowCount,
        fetchImplementations,
    } = useImplementations();
    const { setImplementations, setClients } = useStore('implementation');
    const { refetchTrigger } = useStore('refetchTrigger');

    const { loading: serviceCategoriesLoading } = useFetchFilterOptions(
        SERVICE_CATEGORY_FILTER_OPTIONS_QUERY,
        'serviceCategory',
    );
    const { loading: loadingPriceTypes } = useFetchFilterOptions(
        PRICE_TYPE_FILTER_OPTIONS_QUERY,
        'priceType',
    );
    const { loading: loadingPriceSalesRounds } = useFetchFilterOptions(
        PRICE_SALES_ROUND_FILTER_OPTIONS_QUERY,
        'priceSalesRound',
    );
    const { loading: loadingCoaches } = useFetchFilterOptions(
        EMPLOYEE_FILTER_OPTIONS_QUERY,
        'employee',
        { type: { _in: EmployeeType.Coach } },
    );
    const { loading: loadingOrganization } = useFetchFilterOptions(ORGANIZATION_FILTER_OPTIONS_QUERY, 'organization');
    const { loading: loadingCourts } = useFetchFilterOptions(COURT_FILTER_OPTIONS_QUERY, 'court');
    const { loading: loadingGroups } = useFetchFilterOptions(GROUP_FILTER_OPTIONS_QUERY, 'group');
    const isLoading =
        implementationsLoading ||
        serviceCategoriesLoading ||
        loadingPriceTypes ||
        loadingPriceSalesRounds ||
        loadingCoaches ||
        loadingCourts ||
        loadingOrganization ||
        loadingGroups;

    const { fetchClients, data: clientsData, loading: clientsLoading } = useClients();

    useFetchDataForGrid(
        sortModel,
        filterModel,
        page,
        pageSize,
        fetchImplementations,
        refetchTrigger,
        IMPLEMENTATION_HEADER_DATA,
    );

    usePageTitle(PAGE_TITLE_IMPLEMENTATION);

    useEffect(() => {
        setImplementations(implementationsData, rowCount);
    }, [implementationsData, implementationsLoading]);

    useEffect(() => {
        setClients(clientsData);
    }, [clientsData, clientsLoading]);

    useEffect(() => {
        fetchClients().then();
    }, []);

    return (
        <StyledPageWrap>
            <ControlPanel />
            <ImplementationList
                loading={ isLoading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <UpdateImplementationModal />
        </StyledPageWrap>
    );
});

export default List;
