export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';
export const PATH_PRICE_TYPE = '/price-type';
export const PATH_PRICE_SEASON = '/price-season';
export const PATH_SERVICE_CATEGORY = '/service-category';
export const PATH_PRICE_MEMBER_CARDS = '/price-member-cards';

export const PATH_MEMBER_CARD = '/member-card';
export const PATH_MEMBER_CARD_TYPE = '/member-card-type';

export const PATH_SCHEDULE_SLOT_RESERVE = '/schedule-slot-reserve';
export const PATH_IMPLEMENTATION = '/implementation';
export const PATH_IMPLEMENTATION_DOCUMENTS = '/implementation-documents';
export const PATH_CALCULATION = '/calculation';
export const PATH_WAITING_LIST = '/waiting-list';

export const PATH_PAYMENT = '/payment';
export const PATH_PAYMENT_DOCUMENTS = '/payment-document';
export const PATH_PAYMENT_SETTINGS = '/payment-setting';
export const PATH_INTERNET_ACQUIRING = '/internet-acquiring';

export const PATH_BONUS = '/bonus';
export const PATH_BONUS_DOCUMENT = '/bonus-document';

export const PATH_CLIENT = '/client';
export const PATH_CLIENT_TRANSACTION = '/client-transaction';
export const PATH_CLIENT_BONUS_TRANSACTION = '/client-bonus-transaction';
export const PATH_CLIENT_DELAY_OF_PAYMENT = '/client-delay-of-payment';

export const PATH_COACH = '/coach';
export const PATH_COACH_SETTINGS = '/coach-settings';
export const PATH_COACH_CATEGORY = '/coach-category';
export const PATH_COACH_SALARY = '/coach-salary';
export const PATH_COACHES_IN_GROUPS = '/coaches-in-groups';
export const PATH_COACH_HOURS = '/coach-hours';

export const PATH_GROUP = '/group';

export const PATH_COURT = '/court';
export const PATH_COURT_CATEGORY = '/court-category';

export const PATH_ORGANIZATION = '/organization';
export const PATH_ORGANIZATION_LOCATION = '/organization-location';

export const PATH_SERVICE_APP = '/service-app';
export const PATH_USER = '/user';
export const PATH_EMPLOYEE = '/employee';
export const PATH_DOCUMENTS = '/documents';

export const PATH_AGE_GROUPS = '/age';
export const PATH_PRT = '/prt';
export const PATH_BULK_OPERATIONS = '/bulk-operations';

export const PATH_COURT_VIDEO_ANALYTICS = '/court-video-analytics';

export const PATH_DASHBOARD = '/';

export const PATH_TASK_TYPE = '/task-type';
export const PATH_TASK_STATUS = '/task-status';

export const PATH_IMPORT_PRICE = '/import-price';
