import { PAGE_TITLE_WAITING_LIST } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import WaitingListComponent from 'page/WaitingList/components/WaitingListComponent';
import ControlPanel from 'shared/component/ControlPanel';
import useWaitingLists from 'shared/data-hook/waitingList/fetch/fetchAll/useWaitingLists';
import { COURT_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/court/CourtQuery';
import { EMPLOYEE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/employee/EmployeeQuery';
import { GROUP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/group/GroupQuery';
import { ORGANIZATION_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/organization/OrganizationQuery';
import { PRICE_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/priceType/PriceTypeQuery';
import { SERVICE_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateWaitingListModal from './components/CreateWaitingListModal';
import UpdateWaitingListModal from './components/UpdateWaitingListModal';
import { WAITING_LIST_HEADER_DATA } from './domain/HeaderData';

const WaitingList: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const {
        data: waitingListsData,
        loading: waitingListsLoading,
        count: rowCount,
        fetchWaitingLists,
    } = useWaitingLists();

    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createWaitingListModal');
    const { setWaitingLists } = useStore('waitingList');
    const { loading: loadingCourts } = useFetchFilterOptions(COURT_FILTER_OPTIONS_QUERY, 'court');
    const { loading: loadingGroups } = useFetchFilterOptions(GROUP_FILTER_OPTIONS_QUERY, 'group');
    const { loading: loadingOrganization } = useFetchFilterOptions(ORGANIZATION_FILTER_OPTIONS_QUERY, 'organization');
    const { loading: serviceCategoriesLoading } = useFetchFilterOptions(SERVICE_CATEGORY_FILTER_OPTIONS_QUERY, 'serviceCategory');
    const { loading: loadingCoaches } = useFetchFilterOptions(EMPLOYEE_FILTER_OPTIONS_QUERY, 'employee');
    const { loading: loadingPriceTypes } = useFetchFilterOptions(PRICE_TYPE_FILTER_OPTIONS_QUERY, 'priceType');
    const { loading: groupFilterOptionsLoading } = useFetchFilterOptions(GROUP_FILTER_OPTIONS_QUERY, 'group');
    const { loading: organizationFilterOptionsLoading } = useFetchFilterOptions(ORGANIZATION_FILTER_OPTIONS_QUERY, 'organization');

    const isLoading =
        groupFilterOptionsLoading ||
        organizationFilterOptionsLoading ||
        waitingListsLoading ||
        loadingCourts ||
        loadingGroups ||
        loadingOrganization ||
        serviceCategoriesLoading ||
        loadingCoaches ||
        loadingPriceTypes;

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchWaitingLists, refetchTrigger, WAITING_LIST_HEADER_DATA);

    useEffect(() => {
        setWaitingLists(waitingListsData, rowCount);
    }, [waitingListsData, waitingListsLoading]);

    usePageTitle(PAGE_TITLE_WAITING_LIST);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => onOpen() }
                    startIcon={ <AddIcon /> }
                >
                    добавить
                </Button>
            </ControlPanel>
            <WaitingListComponent
                loading={ isLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateWaitingListModal />
            <UpdateWaitingListModal />
        </StyledPageWrap>
    );
});

export default WaitingList;
