import { DEFAULT_LOCALE } from 'constants/locale';
import { DEFAULT_SITE_DESCRIPTION, DEFAULT_SITE_TITLE } from 'constants/siteTitle';
import { DEFAULT_TIMEZONE } from 'constants/timezone';

import KeyableEntity from 'types/KeyableEntity';

export type ServiceAppT = {
    id: string;
    label: string;
    token: string;
    description: string;
    url: string;
    hash: string;
    timezone: number;
    fiatCurrencyTitle: string;
    videoAnalyticsTelegramBotChatIds: Array<KeyableEntity<string>>;
    entryNotificationTelegramBotChatIds: Array<KeyableEntity<string>>;
    acquiringNotificationTelegramBotChatIds: Array<KeyableEntity<string>>;
    domainName: string | null;
    smsSendingEnabled: boolean | null;
    smsSenderName: string | null;
    locale: string | null;
    defaultCreditForNewClients: number | null;
    frontendAppsAreDisabled: boolean;
    telegramNotificationBot: string | null;
};

export type ServiceAppsT = {
    serviceApps: ServiceAppT[];
    serviceAppsCount: number;
}

export const EMPTY_SERVICE_APPS_RESULT: ServiceAppsT = {
    serviceApps: [],
    serviceAppsCount: 0,
};

export const EMPTY_SERVICE_APP: ServiceAppT = {
    id: '',
    label: DEFAULT_SITE_TITLE,
    token: '',
    description: DEFAULT_SITE_DESCRIPTION,
    url: '',
    hash: '',
    timezone: DEFAULT_TIMEZONE,
    fiatCurrencyTitle: '',
    videoAnalyticsTelegramBotChatIds: [],
    entryNotificationTelegramBotChatIds: [],
    acquiringNotificationTelegramBotChatIds: [],
    domainName: '',
    smsSendingEnabled: false,
    smsSenderName: '',
    locale: DEFAULT_LOCALE,
    defaultCreditForNewClients: 0,
    frontendAppsAreDisabled: false,
    telegramNotificationBot: '',
};
