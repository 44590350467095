import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PriceT } from 'types/Price';

export const DTO = t.type({
    id: t.string,
    day: t.number,
    time_start: t.string,
    time_end: t.string,
    court_type: t.type({
        name: t.string,
    }),
    service_category: t.type({
        name: t.string,
    }),
    coach_category: Nullable(t.type({
        name: t.string,
    })),
    group_view: Nullable(t.type({
        name: t.string,
    })),
    price_rates: t.array(t.type({
        price_sales_round_id: t.string,
        sale_rate: t.number,
        min_rate: t.number,
        forward_transfer_cost: t.number,
        back_transfer_cost: t.number,
        transferable: t.boolean,
        cancellable: t.boolean,
        return_rate: t.number,
        performer_return_income_rate: t.number,
        performer_return_income_sum: t.number,
        performer_sale_income_rate: t.number,
        performer_sale_income_sum: t.number,
        share_of_payment_fiat: Nullable(t.number),
        share_of_payment_bonus: Nullable(t.number),
        transfer_disabled_before_hours: t.number,
    })),
});

export type PriceExtendedDTOType = t.TypeOf<typeof DTO>;

export const PriceExtendedDTO = (currency: string) => tPromise.extendDecoder<PriceExtendedDTOType, PriceT>(
    DTO,
    ({
        id, day, time_end, time_start, court_type, service_category, coach_category, group_view, price_rates,
    }) => {
        const priceRates = price_rates.map((item) => ({
            priceSalesRoundId: item.price_sales_round_id,
            saleRate: item.sale_rate,
            minRate: item.min_rate,
            forwardTransferCost: item.forward_transfer_cost,
            backTransferCost: item.back_transfer_cost,
            transferable: item.transferable,
            cancellable: item.cancellable,
            returnRate: item.return_rate,
            performerReturnIncomeValue: item.performer_return_income_rate || item.performer_return_income_sum,
            performerSaleIncomeValue: item.performer_sale_income_rate || item.performer_sale_income_sum,
            shareOfPaymentFiat: item.share_of_payment_fiat ?? undefined,
            shareOfPaymentBonus: item.share_of_payment_bonus ?? undefined,
            performerReturnIncomeCurrency:
                (item.performer_return_income_sum && currency)
                || (item.performer_return_income_rate && '%') || '',
            performerSaleIncomeCurrency:
                (item.performer_sale_income_sum && currency)
                || (item.performer_sale_income_rate && '%') || '',
            transferDisabledBeforeHours: item.transfer_disabled_before_hours,
        }));

        return {
            id,
            day,
            timeStart: time_start,
            timeEnd: time_end,
            courtType: court_type.name,
            category: service_category.name,
            coachCategory: coach_category?.name,
            groupView: group_view?.name,
            priceRates,
        };
    },
) as t.Type<PriceT, PriceExtendedDTOType>;
