import { PROPERTY_TITLE } from 'constants/propertyNames';

import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { GroupCategoryT } from 'types/GroupCategory';

import {
    GROUP_CATEGORY_FIELD_KEYS,
    GroupCategoryFormFields,
} from './formData';

type PropsT = {
    control: Control<GroupCategoryFormFields>;
    setValue?: UseFormSetValue<GroupCategoryFormFields>;
    groupCategory?: GroupCategoryT;
    isUpdate?: boolean;
};

const GroupCategoryForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, groupCategory, isUpdate,
    } = props;

    useEffect(() => {
        if (isUpdate && groupCategory && setValue) {
            Object.keys(groupCategory).forEach((key) => {
                setValue(key as keyof GroupCategoryFormFields, groupCategory[key as keyof GroupCategoryFormFields as never]);
            });
        }
    }, [groupCategory, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ GROUP_CATEGORY_FIELD_KEYS.name }
                    label={ PROPERTY_TITLE }
                    type="text"
                />
            </Grid2>
        </Grid2>
    );
});

export default GroupCategoryForm;
