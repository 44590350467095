import { PROPERTY_SERVICE_APP } from 'constants/propertyNames';

import React, { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import {
    SERVICE_APP_DOCUMENT_FIELD_KEYS,
    SERVICE_APP_DOCUMENT_LABEL_DATA,
    ServiceAppDocumentFormFields,
} from 'page/ServiceAppDocuments/components/formData';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { useStore } from 'store';
import { ServiceAppDocumentT } from 'types/ServiceAppDocument';

type PropsT = {
    control: Control<ServiceAppDocumentFormFields>;
    setValue?: UseFormSetValue<ServiceAppDocumentFormFields>;
    document?: ServiceAppDocumentT;
    isUpdate?: boolean;
};

const DocumentForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, document, isUpdate,
    } = props;
    const { getFilterOption } = useStore('filterOptions');
    const serviceAppFilterOptions = getFilterOption('serviceApp');

    useEffect(() => {
        if (isUpdate && document) {
            Object.keys(document).forEach((key) => {
                if (key === 'serviceApp') {
                    setValue?.(key as keyof ServiceAppDocumentFormFields, document[key]?.id);
                } else {
                    setValue?.(key as keyof ServiceAppDocumentFormFields, document[key as keyof ServiceAppDocumentFormFields as never]);
                }
            });
        }
    }, [document, isUpdate]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <Switch
                    label={ SERVICE_APP_DOCUMENT_LABEL_DATA.isActive }
                    name={ SERVICE_APP_DOCUMENT_FIELD_KEYS.isActive }
                    control={ control }
                    type="boolean"
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ serviceAppFilterOptions }
                    name={ SERVICE_APP_DOCUMENT_FIELD_KEYS.serviceApp }
                    control={ control }
                    size="small"
                    label={ PROPERTY_SERVICE_APP }
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ SERVICE_APP_DOCUMENT_FIELD_KEYS.title }
                    label={ SERVICE_APP_DOCUMENT_LABEL_DATA.title }
                    type="text"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ SERVICE_APP_DOCUMENT_FIELD_KEYS.position }
                    label={ SERVICE_APP_DOCUMENT_LABEL_DATA.position }
                    type="number"
                />
            </Grid2>
            <Grid2>
                <TextField
                    size="small"
                    control={ control }
                    name={ SERVICE_APP_DOCUMENT_FIELD_KEYS.url }
                    label={ SERVICE_APP_DOCUMENT_LABEL_DATA.url }
                    type="text"
                />
            </Grid2>
        </Grid2>
    );
});

export default DocumentForm;
