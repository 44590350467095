import { makeAutoObservable } from 'mobx';
import { GroupCategoryT } from 'types/GroupCategory';

export default class GroupCategoryStore {
    public groupCategoryList: GroupCategoryT[] = [];

    public groupByCategory: GroupCategoryT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get categories(): GroupCategoryT[] {
        return this.groupCategoryList;
    }

    public setGroupCategories(groupCategories: GroupCategoryT[], count?: number) {
        this.groupCategoryList = groupCategories;
        this.count = count ?? 0;
    }
}
