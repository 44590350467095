import { useMutation } from '@apollo/client';
import {
    EMPLOYEE_DELETE,
    EmployeeDeleteResponseDataT,
    EmployeeDeleteVarT,
} from 'shared/graphql/mutation/employee/EmployeeDelete';
import { EmployeeT } from 'types/Employee';

import { EmployeeDeleteDataT } from './EmployeeDeleteData';

const useEmployeeDelete = (): EmployeeDeleteDataT => {
    const [employeeDelete, { loading, error }] = useMutation<
        EmployeeDeleteResponseDataT,
        EmployeeDeleteVarT
    >(EMPLOYEE_DELETE);

    const deleteEmployee = async (id: EmployeeT['id']) => {
        try {
            const { data } = await employeeDelete({
                variables: {
                    id,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteEmployee,
        loading,
        error,
    };
};

export default useEmployeeDelete;
