import {
    PAGE_TITLE_AGE,
    PAGE_TITLE_BULK_OPERATIONS,
    PAGE_TITLE_CLIENT,
    PAGE_TITLE_COACH_HOURS_REPORT,
    PAGE_TITLE_COACH_SETTINGS,
    PAGE_TITLE_COACHES_IN_GROUPS,
    PAGE_TITLE_COURT_CATEGORY,
    PAGE_TITLE_COURT_VIDEO_ANALYTICS,
    PAGE_TITLE_CRM_SETTINGS_STATUS,
    PAGE_TITLE_CRM_SETTINGS_TEMPLATES,
    PAGE_TITLE_DOCUMENTS, PAGE_TITLE_EMPLOYEE,
    PAGE_TITLE_IMPLEMENTATION,
    PAGE_TITLE_IMPLEMENTATION_DOCUMENT,
    PAGE_TITLE_IMPORT_PRICE,
    PAGE_TITLE_INTERNET_ACQUIRING,
    PAGE_TITLE_MEMBER_CARD_TYPE,
    PAGE_TITLE_ORGANIZATION,
    PAGE_TITLE_ORGANIZATION_LOCATION,
    PAGE_TITLE_PAYMENT,
    PAGE_TITLE_PAYMENT_SETTING,
    PAGE_TITLE_PRICE,
    PAGE_TITLE_PRICE_MEMBER_CARDS,
    PAGE_TITLE_PRICE_TYPE,
    PAGE_TITLE_PRT,
    PAGE_TITLE_SCHEDULE_SLOT_RESERVE,
    PAGE_TITLE_SERVICE_APP,
    PAGE_TITLE_SERVICE_CATEGORY,
    PAGE_TITLE_WAITING_LIST,
} from 'constants/pageTitles';
import {
    PATH_AGE_GROUPS, PATH_BONUS, PATH_BONUS_DOCUMENT, PATH_BULK_OPERATIONS,
    PATH_CALCULATION, PATH_CLIENT, PATH_CLIENT_BONUS_TRANSACTION,
    PATH_CLIENT_DELAY_OF_PAYMENT,
    PATH_CLIENT_TRANSACTION,
    PATH_COACH_HOURS,
    PATH_COACH_SETTINGS, PATH_COACHES_IN_GROUPS,
    PATH_COURT as COURT_LINK,
    PATH_COURT_CATEGORY,
    PATH_COURT_VIDEO_ANALYTICS, PATH_DOCUMENTS, PATH_EMPLOYEE,
    PATH_GROUP as GROUP_LINK,
    PATH_IMPLEMENTATION, PATH_IMPLEMENTATION_DOCUMENTS, PATH_IMPORT_PRICE, PATH_INTERNET_ACQUIRING, PATH_MEMBER_CARD, PATH_MEMBER_CARD_TYPE,
    PATH_ORGANIZATION as ORGANIZATION_LINK,
    PATH_ORGANIZATION_LOCATION,
    PATH_PAYMENT,
    PATH_PAYMENT_SETTINGS, PATH_PRICE_MEMBER_CARDS,
    PATH_PRICE_SEASON,
    PATH_PRICE_TYPE,
    PATH_PRT,
    PATH_SCHEDULE_SLOT_RESERVE,
    PATH_SERVICE_APP,
    PATH_SERVICE_CATEGORY,
    PATH_TASK_STATUS, PATH_TASK_TYPE,
    PATH_USER,
    PATH_WAITING_LIST,
} from 'constants/routes';

import { MenuItemT } from 'types/MenuItems';

export const PRICE_MANAGEMENT: MenuItemT = {
    id: 1,
    header: PAGE_TITLE_PRICE,
    rootLink: PATH_PRICE_SEASON,
    links: [
        {
            path: PATH_PRICE_TYPE,
            header: PAGE_TITLE_PRICE_TYPE,
        },
        {
            path: PATH_SERVICE_CATEGORY,
            header: PAGE_TITLE_SERVICE_CATEGORY,
        },
        {
            path: PATH_PRICE_MEMBER_CARDS,
            header: PAGE_TITLE_PRICE_MEMBER_CARDS,
        },
    ],
};

export const MEMBERSHIP_CARD_MANAGEMENT: MenuItemT = {
    id: 2,
    header: 'Членские карты',
    rootLink: PATH_MEMBER_CARD,
    links: [
        {
            path: PATH_MEMBER_CARD_TYPE,
            header: PAGE_TITLE_MEMBER_CARD_TYPE,
        },
    ],
};

export const SCHEDULE: MenuItemT = {
    id: 3,
    header: 'Расписание',
    rootLink: PATH_SCHEDULE_SLOT_RESERVE,
    links: [
        {
            path: PATH_SCHEDULE_SLOT_RESERVE,
            header: PAGE_TITLE_SCHEDULE_SLOT_RESERVE,
        },
        {
            path: PATH_IMPLEMENTATION,
            header: PAGE_TITLE_IMPLEMENTATION,
        },
        {
            path: PATH_IMPLEMENTATION_DOCUMENTS,
            header: PAGE_TITLE_IMPLEMENTATION_DOCUMENT,
        },
        {
            path: PATH_CALCULATION,
            header: 'Предрасчеты',
        },
        {
            path: PATH_WAITING_LIST,
            header: PAGE_TITLE_WAITING_LIST,
        },
    ],
};

export const PAYMENTS: MenuItemT = {
    id: 4,
    header: PAGE_TITLE_PAYMENT,
    rootLink: PATH_PAYMENT,
    links: [
        {
            path: PATH_PAYMENT_SETTINGS,
            header: PAGE_TITLE_PAYMENT_SETTING,
        },
        {
            path: PATH_INTERNET_ACQUIRING,
            header: PAGE_TITLE_INTERNET_ACQUIRING,
        },
    ],
};

export const BONUSES: MenuItemT = {
    id: 5,
    header: 'Бонусы',
    rootLink: PATH_BONUS,
    links: [
        {
            path: PATH_BONUS,
            header: 'Бонусы',
        },
        {
            path: PATH_BONUS_DOCUMENT,
            header: 'Бонусные документы',
        },
    ],
};

export const CLIENTS: MenuItemT = {
    id: 6,
    header: PAGE_TITLE_CLIENT,
    rootLink: PATH_CLIENT,
    links: [
        {
            path: PATH_CLIENT_TRANSACTION,
            header: 'Транзакции клиентов',
        },
        {
            path: PATH_CLIENT_BONUS_TRANSACTION,
            header: 'Бонусные транзакции клиентов',
        },
        {
            path: PATH_CLIENT_DELAY_OF_PAYMENT,
            header: 'Отсрочка платежа',
        },
    ],
};

export const COACH: MenuItemT = {
    id: 7,
    header: 'Тренеры',
    rootLink: PATH_COACH_SETTINGS,
    links: [
        {
            path: PATH_COACH_SETTINGS,
            header: PAGE_TITLE_COACH_SETTINGS,
        },
    ],
};

export const GROUP: MenuItemT = {
    id: 8,
    header: 'Группы',
    rootLink: GROUP_LINK,
    links: [
        {
            path: PATH_AGE_GROUPS,
            header: PAGE_TITLE_AGE,
        },
        {
            path: PATH_PRT,
            header: PAGE_TITLE_PRT,
        },
    ],
};

export const COURT: MenuItemT = {
    id: 9,
    header: 'Корты',
    rootLink: COURT_LINK,
    links: [
        {
            path: PATH_COURT_CATEGORY,
            header: PAGE_TITLE_COURT_CATEGORY,
        },
        {
            path: PATH_COURT_VIDEO_ANALYTICS,
            header: PAGE_TITLE_COURT_VIDEO_ANALYTICS,
        },
    ],
};

export const ORGANIZATION: MenuItemT = {
    id: 10,
    header: 'Организация',
    rootLink: ORGANIZATION_LINK,
    links: [
        {
            path: ORGANIZATION_LINK,
            header: PAGE_TITLE_ORGANIZATION,
        },
        {
            path: PATH_ORGANIZATION_LOCATION,
            header: PAGE_TITLE_ORGANIZATION_LOCATION,
        },
        {
            path: PATH_EMPLOYEE,
            header: PAGE_TITLE_EMPLOYEE,
        },
    ],
};

export const ADMINISTRATION: MenuItemT = {
    id: 11,
    header: 'Администрирование',
    rootLink: PATH_BULK_OPERATIONS,
    links: [
        {
            path: PATH_BULK_OPERATIONS,
            header: PAGE_TITLE_BULK_OPERATIONS,
        },
        {
            path: PATH_SERVICE_APP,
            header: PAGE_TITLE_SERVICE_APP,
        },
        {
            path: PATH_USER,
            header: 'Пользователи',
        },
        {
            path: PATH_DOCUMENTS,
            header: PAGE_TITLE_DOCUMENTS,
        },
    ],
};

export const OTHER_DIRECTORIES: MenuItemT = {
    id: 12,
    header: 'Прочие справочники',
    rootLink: PATH_AGE_GROUPS,
    links: [
        {
            path: PATH_AGE_GROUPS,
            header: 'Возрастные группы',
        },
        {
            path: PATH_PRT,
            header: 'Группы ПРТ',
        },
    ],
};

export const REPORTS: MenuItemT = {
    id: 13,
    header: 'Отчеты',
    rootLink: PATH_COACHES_IN_GROUPS,
    links: [
        {
            path: PATH_COACHES_IN_GROUPS,
            header: PAGE_TITLE_COACHES_IN_GROUPS,
        },
        {
            path: PATH_COACH_HOURS,
            header: PAGE_TITLE_COACH_HOURS_REPORT,
        },
    ],
};

export const CRM_SETTINGS: MenuItemT = {
    id: 14,
    header: 'Настройки CRM',
    rootLink: PATH_TASK_TYPE,
    links: [
        {
            path: PATH_TASK_TYPE,
            header: PAGE_TITLE_CRM_SETTINGS_TEMPLATES,
        },
        {
            path: PATH_TASK_STATUS,
            header: PAGE_TITLE_CRM_SETTINGS_STATUS,
        },
    ],
};

export const IMPORT_EXPORT_OPERATIONS: MenuItemT = {
    id: 15,
    header: 'Импорт / экспорт',
    rootLink: PATH_IMPORT_PRICE,
    links: [
        {
            path: PATH_IMPORT_PRICE,
            header: PAGE_TITLE_IMPORT_PRICE,
        },
    ],
};

// export const MENU_ITEMS = [PRICE_MANAGEMENT, MEMBERSHIP_CARD_MANAGEMENT, SCHEDULE, PAYMENTS, BONUSES, CLIENTS, COACH, GROUP, COURT, ORGANIZATION, ADMINISTRATION, OTHER_DIRECTORIES];
export const MENU_ITEMS = [
    PRICE_MANAGEMENT,
    MEMBERSHIP_CARD_MANAGEMENT,
    SCHEDULE,
    CLIENTS,
    COURT,
    GROUP,
    ORGANIZATION,
    COACH,
    PAYMENTS,
    ADMINISTRATION,
    REPORTS,
    CRM_SETTINGS,
    IMPORT_EXPORT_OPERATIONS,
];
