import { ERROR_GROUP_VIEW_UPDATE, SUCCESS_GROUP_VIEW_UPDATE } from 'constants/notificationMessage';

import { GroupViewFormFields } from 'page/GroupView/components/formData';
import useGroupViews from 'shared/data-hook/groupView/fetch/fetchAll/useGroupViews';
import useGroupViewUpdate from 'shared/data-hook/groupView/update/useGroupViewUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { GroupViewT } from 'types/GroupView';

const useUpdateGroupView = () => {
    const { error, loading, groupViewUpdate } = useGroupViewUpdate();
    const { fetchGroupViews: fetchGroupViewsWithoutCategory } = useGroupViews();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateGroupView = async (input: GroupViewFormFields, groupViewId: GroupViewT['id']) => {
        const response = await groupViewUpdate(input, groupViewId);

        if (error || !response || !response.updateGroupView) {
            showError(ERROR_GROUP_VIEW_UPDATE);
        } else {
            showSuccess(SUCCESS_GROUP_VIEW_UPDATE);
        }

        toggleRefetchTrigger();
        await fetchGroupViewsWithoutCategory({ search: { without: true } });
    };

    return {
        updateGroupView,
        loading,
    };
};

export default useUpdateGroupView;
