import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ServiceCategoryT } from 'types/ServiceCategory';

const DTO = t.type({
    id: t.string,
    name: t.string,
    color: Nullable(t.string),
    min_duration: t.number,
    min_additional_duration: t.number,
    position: t.number,
    allowed_for_client: t.boolean,
});

export type ServiceCategoryDTOT = t.TypeOf<typeof DTO>;

export const ServiceCategoryDTO = tPromise.extendDecoder<ServiceCategoryDTOT, ServiceCategoryT>(
    DTO,
    ({
        id,
        name,
        color,
        min_duration,
        min_additional_duration,
        position,
        allowed_for_client,
    }) => ({
        id,
        name,
        color,
        minDuration: min_duration,
        minAdditionalDuration: min_additional_duration,
        position,
        allowedForClient: allowed_for_client,
    }),
) as t.Type<ServiceCategoryT, ServiceCategoryDTOT>;
