import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import DynamicSelectWithSearch from 'shared/component/Form/DynamicSelectWithSearch';
import useClientFilterOptions from 'shared/data-hook/client/fetch/fetchClientFilterOptions/useClientFilterOptions';

type PropsT<T extends FieldValues> = {
    name: string;
    control: Control<T>;
    size?: 'small';
    label: string;
};

const ClientSelect = <T extends FieldValues>(props: PropsT<T>) => {
    const { fetchClientFilterOptions } = useClientFilterOptions();

    return (
        <DynamicSelectWithSearch
            { ...props }
            fetchOptions={ fetchClientFilterOptions }
        />
    );
};

export default ClientSelect;
