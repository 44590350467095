import { EMPLOYEE_TYPE_LABELS } from 'constants/employeeTypes';

import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { EmployeeT } from 'types/Employee';

const DTO = t.type({
    id: t.string,
    full_name: t.string,
    email: Nullable(t.string),
    phone: Nullable(t.string),
    type: t.string,
});

export type EmployeeDTOT = t.TypeOf<typeof DTO>;

export const EmployeeDTO = tPromise.extendDecoder<EmployeeDTOT, EmployeeT>(
    DTO,
    ({
        id, full_name, phone, email, type,
    }) => ({
        id,
        fullName: full_name,
        phone: phone || undefined,
        email: email || undefined,
        type: EMPLOYEE_TYPE_LABELS[type as keyof typeof EMPLOYEE_TYPE_LABELS] || type,
    }),
) as t.Type<EmployeeT, EmployeeDTOT>;
