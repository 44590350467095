import { gql } from '@apollo/client';

export type CreateWaitingListPayloadT = {
    client_data_id: string;
    group_id: string | null;
    group_document_id: string | null;
    organization_id: string;
    service_category_id: string | null;
    price_type_id: string;
    coach_price_type_id: string | null;
    user_id: string;
    duration: number;
    datetime: string;
    top_priority: boolean | null;
    comment: string | null;
    created_at: string;
    waiting_list_coaches?: {
        data: Array<{ coach_id: string}>;
    };
    waiting_list_courts?: {
        data: Array<{court_id: string}>;
    };
}

export type WaitingListCreateResponseDataT = {
    createWaitingList: {
        id: string;
    };
} | null | undefined;

export type CreateWaitingListVarT = {
    object: CreateWaitingListPayloadT;
};

export const CREATE_WAITING_LIST = gql`
    mutation WaitingListCreate($object: waiting_list_insert_input = {}) {
        createWaitingList: insert_waiting_list_one(
            object: $object
        ) {
            id
        }
    }
`;
