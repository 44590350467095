import { makeAutoObservable } from 'mobx';

import { PricesByCoachCategoryT } from '../types/PricesByCoachCategory';

export default class PricesByCoachCategoryStore {
    public pricesByCoachCategoryList: PricesByCoachCategoryT[] = [];

    public pricesByCoachCategory: PricesByCoachCategoryT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get coachCategories(): PricesByCoachCategoryT[] {
        return this.pricesByCoachCategoryList;
    }

    public setPricesByCoachCategories(pricesByCoachCategories: PricesByCoachCategoryT[], count?: number) {
        this.pricesByCoachCategoryList = pricesByCoachCategories;
        this.count = count ?? 0;
    }
}
