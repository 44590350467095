import React, { ReactElement } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import ru from 'react-phone-input-2/lang/ru.json';
import { Box, InputLabel, Skeleton } from '@mui/material';
import { PropsT as AuxiliaryPopoverPropsT } from 'shared/component/AuxiliaryPopover';
import { PhoneInput } from 'shared/styled/styledPhoneInput';

type PropsT = Partial<AuxiliaryPopoverPropsT> & {
    label?: string | ReactElement;
    loading?: boolean;
    size?: 'small' | 'medium';
    customError?: boolean;
};

const PhoneField = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control,
        name,
        label,
        loading = false,
        size = 'medium',
    } = props;

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field }) => (
                <Box>
                    { label && (
                        <InputLabel>
                            { label }
                        </InputLabel>
                    ) }

                    { loading ? (
                        <Skeleton variant="rounded" height={ size === 'small' ? 40 : 56 } />
                    ) : (
                        <PhoneInput
                            { ...field }
                            country="ru"
                            value={ field.value ?? '' }
                            onChange={ (value) => field.onChange(value) }
                            localization={ ru }
                        />
                    ) }
                </Box>
            ) }
        />
    );
};

export default PhoneField;
