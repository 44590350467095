import { useStore } from 'store';
import { GroupCategoryT } from 'types/GroupCategory';
import { GroupViewsGrouping, GroupViewsGroupingT, GroupViewT } from 'types/GroupView';

export type RowsT = GroupViewT[] | GroupCategoryT[];

type DataT = {
    rows: RowsT;
    count: number;
}

type ReturnT = {
    data: DataT;
}

export const useGroupedGroupViewsData = (): ReturnT => {
    const { groupCategoryList, count: groupCategoriesCount } = useStore('groupCategory');
    const { groupViewList, count: groupViewsCount } = useStore('groupView');
    const { groupViewsGrouping } = useStore('selectGroupViewsGroupingModal');

    const mapGroupViewsGroupingToFetchFunction: Record<GroupViewsGroupingT, DataT> = {
        [GroupViewsGrouping.GroupCategory]: { rows: groupCategoryList, count: groupCategoriesCount },
        [GroupViewsGrouping.Empty]: { rows: groupViewList, count: groupViewsCount },
    };

    return { data: mapGroupViewsGroupingToFetchFunction[groupViewsGrouping] };
};
