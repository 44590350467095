import {
    ERROR_EMPLOYEE_DELETE, SUCCESS_EMPLOYEE_DELETE,
} from 'constants/notificationMessage';

import useEmployeeDelete from 'shared/data-hook/employee/delete/useEmployeeDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { EmployeeT } from 'types/Employee';

const useDeleteEmployee = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteEmployee } = useEmployeeDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const employeeDelete = async (id: EmployeeT['id']): Promise<void> => {
        const response = await deleteEmployee(id);

        if (error || !response?.deleteEmployee) {
            showError(ERROR_EMPLOYEE_DELETE);
        } else {
            showSuccess(SUCCESS_EMPLOYEE_DELETE);
        }

        toggleRefetchTrigger();
    };

    return { employeeDelete };
};

export default useDeleteEmployee;
