import { useEffect } from 'react';
import useGroupCategories from 'shared/data-hook/groupCategory/fetch/fetchAll/useGroupCategories';
import { SearchT as GroupCategorySearchT } from 'shared/data-hook/groupCategory/filter/getGroupCategoriesFilter';
import useGroupViews from 'shared/data-hook/groupView/fetch/fetchAll/useGroupViews';
import { SearchT as GroupViewSearchT } from 'shared/data-hook/groupView/filter/getGroupViewsFilter';
import { useStore } from 'store';
import { FetchDataParams, SearchT } from 'types/DataGrid';
import { GroupViewsGrouping, GroupViewsGroupingT } from 'types/GroupView';

type FetchT = (params?: FetchDataParams<GroupCategorySearchT & GroupViewSearchT>) => Promise<void>;

type ReturnT = {
    fetch: FetchT;
    loading: boolean;
};

const useFetchGroupViewsByCategories = (props: SearchT): ReturnT => {
    const { name } = props;
    const { setGroupCategories } = useStore('groupCategory');
    const { setGroupViews } = useStore('groupView');
    const { groupViewsGrouping } = useStore('selectGroupViewsGroupingModal');

    const {
        data: groupCategoriesData,
        loading: groupCategoriesLoading,
        count: groupCategoriesCount,
        fetchGroupCategories,
    } = useGroupCategories();

    const {
        data: groupViewsData,
        loading: groupViewsLoading,
        count: groupViewsCount,
        fetchGroupViews,
    } = useGroupViews();

    const loading = groupViewsLoading || groupCategoriesLoading;

    useEffect(() => {
        fetchGroupCategories().then();

        if (groupViewsGrouping === GroupViewsGrouping.GroupCategory) {
            fetchGroupViews({ search: { without: true, name } }).then();
        } else {
            fetchGroupViews().then();
        }
    }, [groupViewsGrouping, name]);

    useEffect(() => {
        if (groupCategoriesData) {
            setGroupCategories(groupCategoriesData, groupCategoriesCount);
        }
    }, [groupCategoriesLoading, groupCategoriesData]);

    useEffect(() => {
        if (groupViewsData) {
            setGroupViews(groupViewsData, groupViewsCount);
        }
    }, [groupViewsLoading, groupViewsData]);

    const mapGroupViewsGroupingToFetch: Record<GroupViewsGroupingT, FetchT> = {
        [GroupViewsGrouping.GroupCategory]: fetchGroupCategories,
        [GroupViewsGrouping.Empty]: fetchGroupViews,
    };

    return { fetch: mapGroupViewsGroupingToFetch[groupViewsGrouping], loading };
};

export default useFetchGroupViewsByCategories;
