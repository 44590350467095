import { useMutation } from '@apollo/client';
import { CoachFormFields } from 'page/Coach/components/formData';
import { COACH_CREATE, CoachCreateResponseDataT, CoachCreateVarT } from 'shared/graphql/mutation/coach/CoachCreate';

import { CreateCoachDataT } from './CreateCoachData';

const useCoachCreate = (): CreateCoachDataT => {
    const [create, { loading, error }] = useMutation<CoachCreateResponseDataT, CoachCreateVarT>(
        COACH_CREATE,
    );

    const createAction = async (input: CoachFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        category_id: input.categoryId || null,
                        employee_id: input.name,
                        service_app_id: input.serviceAppId,
                        color: input.color,
                        active: input.active,
                        work_days: input.workDays.map((workDay) => ({
                            day: workDay.day,
                            start: workDay.start,
                            end: workDay.end,
                        })),
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        coachCreate: createAction,
    };
};

export default useCoachCreate;
