import {
    ERROR_SERVICE_APP_DOCUMENT_DELETE, SUCCESS_SERVICE_APP_DOCUMENT_DELETE,
} from 'constants/notificationMessage';

import useServiceAppDocumentDelete from 'shared/data-hook/serviceAppDocument/delete/useServiceAppDocumentDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { ServiceAppDocumentT } from 'types/ServiceAppDocument';

const useDeleteServiceAppDocument = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteDocument } = useServiceAppDocumentDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const documentDelete = async (documentId: ServiceAppDocumentT['id']): Promise<boolean> => {
        const response = await deleteDocument(documentId);

        if (error || !response?.deleteServiceAppDocument) {
            showError(ERROR_SERVICE_APP_DOCUMENT_DELETE);

            return false;
        }

        showSuccess(SUCCESS_SERVICE_APP_DOCUMENT_DELETE);
        toggleRefetchTrigger();

        return true;
    };

    return { documentDelete };
};

export default useDeleteServiceAppDocument;
