import {
    PROPERTY_ACTIONS, PROPERTY_CARD_PURCHASE_PRICE, PROPERTY_TITLE,
} from 'constants/propertyNames';

import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DuplicateButton from 'shared/component/Button/DuplicateButton';
import useDuplicateMemberCardType from 'shared/hook/memberCardType/duplicate/useDuplicateMemberCardType';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const RenderCardPriceCell = (props: GridRenderCellParams) => {
    const { row: { price } } = props;
    const { serviceApp: { fiatCurrencyTitle } } = useStore('serviceApp');

    return (
        <span>
            { price } { fiatCurrencyTitle }
        </span>
    );
};

const RenderActionCell = (props: GridRenderCellParams) => {
    const {
        row: { id, title },
    } = props;
    const { onOpen } = useStore('updatePriceMemberCardModal');
    const { memberCardTypeDuplicate } = useDuplicateMemberCardType();

    return (
        <>
            <IconButton onClick={ () => onOpen(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DuplicateButton id={ id } title={ title } actionDuplicate={ memberCardTypeDuplicate } />
        </>
    );
};

export const PRICE_MEMBER_CARD_HEADER_DATA: CustomHeaderData[] = [
    {
        field: 'title',
        headerName: PROPERTY_TITLE,
        minWidth: 200,
        type: 'singleSelect',
    },
    {
        field: 'price',
        headerName: PROPERTY_CARD_PURCHASE_PRICE,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        width: 200,
        hasuraSortFields: ['member_card_type_price_season_prices_aggregate', 'avg', 'price'],
        hasuraFilterFields: ['member_card_type_price_season_prices', 'price'],
        renderCell: RenderCardPriceCell,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
