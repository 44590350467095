import { SearchT } from 'types/DataGrid';

export const getFullNameFilter = ({ name }: SearchT) => ({
    ...(name && {
        client: {
            full_name: {
                _iregex: name.replaceAll(' ', '.*'),
            },
        },
    }),
});
