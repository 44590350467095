import { useMutation } from '@apollo/client';
import { CoachFormFields } from 'page/Coach/components/formData';
import { COACH_UPDATE, CoachUpdateResponseDataT, CoachUpdateVarT } from 'shared/graphql/mutation/coach/CoachUpdate';
import { CoachT } from 'types/Coach';

import { CoachUpdateDataT } from './CoachUpdateData';

const useCoachUpdate = (): CoachUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        CoachUpdateResponseDataT,
        CoachUpdateVarT
    >(COACH_UPDATE);

    const updateAction = async (input: CoachFormFields, coachId: CoachT['id']) => {
        if (!coachId) {
            // TODO: use Sentry
            throw new Error('CoachSetting is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: coachId,
                    set: {
                        service_app_id: input.serviceAppId,
                        category_id: input.categoryId || null,
                        color: input.color,
                        active: input.active,
                        work_days: input.workDays.map((workDay) => ({
                            day: workDay.day,
                            start: workDay.start,
                            end: workDay.end,
                        })),
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        coachUpdate: updateAction,
        loading,
        error,
    };
};

export default useCoachUpdate;
