import { useEffect } from 'react';
import useGroupCategory from 'shared/data-hook/groupCategory/fetch/fetchOne/useGroupCategory';
import { useStore } from 'store';

const useFetchGroupCategory = (id: string) => {
    const { fetchGroupCategory, loading } = useGroupCategory();
    const { isOpen, setGroupCategory } = useStore('updateGroupCategoryModal');

    useEffect(() => {
        if (isOpen && id) {
            fetchGroupCategory(id).then((fetchedGroupCategory) => {
                setGroupCategory(fetchedGroupCategory);
            });
        }
    }, [isOpen, id]);

    return {
        loading,
    };
};

export default useFetchGroupCategory;
