import {
    ERROR_GROUP_CATEGORY_DELETE, SUCCESS_GROUP_CATEGORY_DELETE,
} from 'constants/notificationMessage';

import useGroupCategoryDelete from 'shared/data-hook/groupCategory/delete/single/useGroupCategoryDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { GroupCategoryT } from 'types/GroupCategory';

const useDeleteGroupCategory = () => {
    const { error, deleteGroupCategory } = useGroupCategoryDelete();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const groupCategoryDelete = async (groupCategoryId: GroupCategoryT['id']) => {
        const response = await deleteGroupCategory(groupCategoryId);

        if (error || !response) {
            showError(ERROR_GROUP_CATEGORY_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_GROUP_CATEGORY_DELETE);
        }
    };

    return {
        groupCategoryDelete,
    };
};

export default useDeleteGroupCategory;
