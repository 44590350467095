import { useEffect, useMemo } from 'react';
import { GridColumnGroupingModel, GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { PRICE_MEMBER_CARD_HEADER_DATA } from 'page/PriceMemberCard/domain/HeaderData';
import { setColumnGroupingModel } from 'page/PriceMemberCard/domain/setColumnGroupingModel';
import { parseSelectOptions } from 'shared/helper/common/parseSelectOptions';
import useFetchDataByPriceTypeIdAndPriceSeasonId
    from 'shared/hook/dataGrid/useFetchDataByPriceTypeIdAndPriceSeasonId';
import { useStore } from 'store';
import { DataGridPropsT, FetchDataParams } from 'types/DataGrid';
import { OptionT } from 'types/Option';
import { ActivePriceSeasonT, PriceMemberCardT } from 'types/PriceMemberCard';
import { PriceSalesRoundT } from 'types/PriceSalesRound';
import { PriceSeasonT } from 'types/PriceSeason';
import { PriceTypeT } from 'types/PriceType';

type PropsT = {
    fetchPriceMemberCards: (
        priceTypeId: string,
        priceSeasonId: string,
        params?: FetchDataParams
    ) => Promise<PriceMemberCardT[]>;
    fetchSalesRounds: (priceTypeId?: string | undefined) => Promise<void>;
    handleTabChange: (newTab: string) => void;
    loading: boolean;
    tab: string;
} & DataGridPropsT

type ReturnT = {
    activePriceSeason: ActivePriceSeasonT;
    columnGroupingModel: GridColumnGroupingModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    count: number;
    headerData: typeof PRICE_MEMBER_CARD_HEADER_DATA;
    memberCardTypeFilterOptions: OptionT[];
    priceMemberCardsList: PriceMemberCardT[];
    priceSalesRounds: PriceSalesRoundT[];
    priceSeasonOptions: OptionT[];
    priceSeasons: PriceSeasonT[];
    priceTypes: PriceTypeT[];
    setActivePriceSeason: (value: ActivePriceSeasonT) => void;
    setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void;
}

const useTabContext = (props: PropsT): ReturnT => {
    const {
        tab, fetchSalesRounds, fetchPriceMemberCards, sortModel, filterModel, page, pageSize, handleTabChange,
    } = props;
    const { priceSalesRounds } = useStore('priceSalesRound');
    const {
        priceMemberCardsList,
        count,
        priceTypes,
        setActivePriceType,
        priceSeasons,
        activePriceSeason,
        setActivePriceSeason,
        setColumnVisibilityModel,
        columnVisibilityModel,
    } = useStore('priceMemberCards');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { getFilterOption } = useStore('filterOptions');
    const memberCardTypeFilterOptions = getFilterOption('memberCardType');
    const priceSeasonOptions = parseSelectOptions(priceSeasons);
    const headerData = [...PRICE_MEMBER_CARD_HEADER_DATA];
    const columnGroupingModel: GridColumnGroupingModel = useMemo(() => setColumnGroupingModel(priceSalesRounds), [priceSalesRounds]);

    useEffect(() => {
        if (!tab) {
            handleTabChange(priceTypes[0]?.id ?? '');
        }
    }, [priceTypes]);

    useEffect(() => {
        const priceType = priceTypes.find((item) => item.id === tab);

        if (priceType) {
            setActivePriceType(priceType);
        }
    }, [tab, priceTypes]);

    useEffect(() => {
        if (tab === '' || activePriceSeason.id === '') {
            return;
        }

        useFetchDataByPriceTypeIdAndPriceSeasonId(tab, activePriceSeason.id, sortModel, filterModel, page, pageSize, PRICE_MEMBER_CARD_HEADER_DATA, fetchPriceMemberCards).then();
        fetchSalesRounds(tab).then();
    }, [sortModel, filterModel, page, pageSize, tab, refetchTrigger, activePriceSeason]);

    return {
        activePriceSeason,
        columnGroupingModel,
        columnVisibilityModel,
        count,
        headerData,
        memberCardTypeFilterOptions,
        priceMemberCardsList,
        priceSalesRounds,
        priceSeasonOptions,
        priceSeasons,
        priceTypes,
        setActivePriceSeason,
        setColumnVisibilityModel,
    };
};

export default useTabContext;
