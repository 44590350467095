import { MODAL_TITLE_CREATE_SERVICE_APP_DOCUMENT } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import DocumentForm from 'page/ServiceAppDocuments/components/DocumentForm';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateServiceAppDocument from 'shared/hook/serviceAppDocument/create/useCreateServiceAppDocument';
import { useStore } from 'store';

import {
    getServiceAppDocumentValidationSchema,
    SERVICE_APP_DOCUMENT_DEFAULT_VALUES,
    ServiceAppDocumentFormFields,
} from './formData';

const CreateDocumentModal: FC = observer(() => {
    const { isOpen, onClose } = useStore('createServiceAppDocumentModal');
    const { createDocument, loading: loadingSave } = useCreateServiceAppDocument();

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<ServiceAppDocumentFormFields>({
        defaultValues: SERVICE_APP_DOCUMENT_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getServiceAppDocumentValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateDocument = (data: ServiceAppDocumentFormFields) => {
        createDocument(data).then(() => {
            handleCloseModal();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_SERVICE_APP_DOCUMENT }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <DocumentForm control={ control } />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateDocument) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateDocumentModal;
