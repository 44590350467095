import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PricesByCoachCategoryDTO } from 'shared/data-hook/pricesByCoachCategory/fetch/DTO/PricesByCoachCategoryDTO';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PricesByGroupViewsT } from 'types/PricesByGroupView';

const DTO = (currency: string) => t.type({
    pricesByGroupViews: t.array(PricesByCoachCategoryDTO(currency)),
    pricesByGroupViewsCount: AggregateDTO,
});

export type PricesByGroupViewsDTOT = t.TypeOf<ReturnType<typeof DTO>>;

export const PricesByGroupViewsDTO = (currency: string) => tPromise.extendDecoder<PricesByGroupViewsDTOT, PricesByGroupViewsT>(
    DTO(currency),
    ({
        pricesByGroupViews,
        pricesByGroupViewsCount,
    }) => ({
        pricesByGroupViews,
        pricesByGroupViewsCount,
    }),
) as t.Type<PricesByGroupViewsT, PricesByGroupViewsDTOT>;
