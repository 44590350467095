import { LOCALE_SELECT_OPTIONS } from 'constants/locale';
import { TIMEZONE_SELECT_OPTIONS } from 'constants/timezone';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import {
    ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY,
} from 'shared/graphql/query/organizationLocation/OrganizationLocationQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useUpdateServiceApp from 'shared/hook/serviceApp/update/useUpdateServiceApp';
import { useStore } from 'store';

import {
    SERVICE_APP_DEFAULT_VALUES,
    SERVICE_APP_FIELDS,
    SERVICE_APP_FIELDS_KEYS,
    SERVICE_APP_LABEL_DATA,
    SERVICE_APP_VALIDATION_SCHEMA,
    ServiceAppFormFields,
} from '../formData';

import NotificationIds from './NotificationIds';

const ServiceAppForm: FC = observer(() => {
    const { serviceApp } = useStore('serviceApp');
    const { updateServiceApp, loading: loadingUpdate } = useUpdateServiceApp();
    const { getFilterOption } = useStore('filterOptions');
    const { loading: organizationLocationFilterOptionsLoading } = useFetchFilterOptions(ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY, 'organizationLocation');
    const organizationLocationOptions = getFilterOption('organizationLocation');
    const {
        control, handleSubmit, setValue,
    } = useForm<ServiceAppFormFields>({
        defaultValues: SERVICE_APP_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(SERVICE_APP_VALIDATION_SCHEMA),
    });

    useEffect(() => {
        if (!serviceApp) return;

        Object.keys(serviceApp).forEach((key) => {
            setValue(key as keyof ServiceAppFormFields, serviceApp[key as keyof ServiceAppFormFields as never]);
        });
    }, [serviceApp]);

    const handleUpdateServiceApp = (data: ServiceAppFormFields) => {
        if (!serviceApp) return;

        updateServiceApp(data, serviceApp.id).then();
    };

    return (
        <form onSubmit={ handleSubmit(handleUpdateServiceApp) }>
            <Box my={ 2 } mx={ 3 }>
                <Grid2 container direction="column" spacing={ 1 }>
                    {
                        SERVICE_APP_FIELDS.map((field) => (
                            <Grid2 key={ field } sm={ 12 } md={ 8 }>
                                <TextField
                                    size="small"
                                    key={ field }
                                    name={ field as keyof ServiceAppFormFields }
                                    type={ typeof SERVICE_APP_DEFAULT_VALUES[field as keyof ServiceAppFormFields] }
                                    control={ control }
                                    label={ SERVICE_APP_LABEL_DATA[field as keyof ServiceAppFormFields] }
                                />
                            </Grid2>
                        ))
                    }
                    <Grid2>
                        <Switch
                            name={ SERVICE_APP_FIELDS_KEYS.smsSendingEnabled as keyof ServiceAppFormFields }
                            control={ control }
                            label={ SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.smsSendingEnabled] }
                        />
                    </Grid2>
                    <Grid2 sm={ 12 } md={ 8 }>
                        <Select
                            options={ TIMEZONE_SELECT_OPTIONS }
                            control={ control }
                            name={ SERVICE_APP_FIELDS_KEYS.timezone as keyof ServiceAppFormFields }
                            label={ SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.timezone] }
                        />
                    </Grid2>
                    <Grid2 sm={ 12 } md={ 8 }>
                        <Select
                            options={ LOCALE_SELECT_OPTIONS }
                            control={ control }
                            name={ SERVICE_APP_FIELDS_KEYS.locale as keyof ServiceAppFormFields }
                            label={ SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.locale] }
                        />
                    </Grid2>
                    <Grid2 sm={ 12 } md={ 8 }>
                        <Select
                            options={ organizationLocationOptions }
                            control={ control }
                            loading={ organizationLocationFilterOptionsLoading }
                            name={ SERVICE_APP_FIELDS_KEYS.defaultOrganizationLocationId }
                            label={ SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.defaultOrganizationLocationId] }
                            empty
                        />
                    </Grid2>
                    <Grid2 sm={ 12 } md={ 10 }>
                        <NotificationIds
                            title={ SERVICE_APP_LABEL_DATA.videoAnalyticsTelegramBotChatIds }
                            name={ SERVICE_APP_FIELDS_KEYS.videoAnalyticsTelegramBotChatIds }
                            control={ control }
                        />
                    </Grid2>
                    <Grid2 sm={ 12 } md={ 10 }>
                        <NotificationIds
                            title={ SERVICE_APP_LABEL_DATA.entryNotificationTelegramBotChatIds }
                            name={ SERVICE_APP_FIELDS_KEYS.entryNotificationTelegramBotChatIds }
                            control={ control }
                        />
                    </Grid2>
                    <Grid2 sm={ 12 } md={ 10 }>
                        <NotificationIds
                            title={ SERVICE_APP_LABEL_DATA.acquiringNotificationTelegramBotChatIds }
                            name={ SERVICE_APP_FIELDS_KEYS.acquiringNotificationTelegramBotChatIds }
                            control={ control }
                        />
                    </Grid2>
                    <Grid2>
                        <Switch
                            name={ SERVICE_APP_FIELDS_KEYS.frontendAppsAreDisabled as keyof ServiceAppFormFields }
                            control={ control }
                            label={ SERVICE_APP_LABEL_DATA[SERVICE_APP_FIELDS_KEYS.frontendAppsAreDisabled] }
                        />
                    </Grid2>
                </Grid2>
                <Stack direction="row" my={ 4 }>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={ loadingUpdate }
                    >
                        Сохранить
                    </LoadingButton>
                </Stack>
            </Box>
        </form>
    );
});

export default ServiceAppForm;
