import {
    BONUS, FIAT, MULTIPLYING_FACTOR, PROMO,
} from 'constants/currency';

import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { MemberCardTypeDiscountRatesT } from 'types/PriceMemberCard';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

type HeaderDataT = {
    discountRates: MemberCardTypeDiscountRatesT[];
    property: 'discountPercentFiat' | 'cashbackPercentFiat' | 'discountPercentBonus' | 'cashbackPercentBonus' | 'discountPercentBonusPerFreeSlot' | 'multiplyingFactor';
    id: string;
}

const renderCell = ({ discountRates, property, id }: HeaderDataT): string | null => {
    const priceMemberCardDiscount = discountRates.find(({ priceSalesRoundId }) => priceSalesRoundId === id);

    return priceMemberCardDiscount ?
        `${priceMemberCardDiscount[property]} ${property === 'multiplyingFactor' ? '' : '%'}`
        : null;
};

export const setHeaderData = (headerData: GridColumns, priceSalesRound: PriceSalesRoundT, index: number) => {
    headerData.push(
        {
            field: `fiatDiscount-${index}`,
            headerName: `${FIAT} discount %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            sortable: false,
            filterable: false,
            valueGetter: (params: GridValueGetterParams) => renderCell({ discountRates: params.row.memberCardTypeDiscountRates, property: 'discountPercentFiat', id: priceSalesRound.id }),
        },
        {
            field: `fiatCashback-${index}`,
            headerName: `${FIAT} cashback %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            sortable: false,
            filterable: false,
            valueGetter: (params: GridValueGetterParams) => renderCell({ discountRates: params.row.memberCardTypeDiscountRates, property: 'cashbackPercentFiat', id: priceSalesRound.id }),
        },
        {
            field: `bonusDiscount-${index}`,
            headerName: `${BONUS} discount %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            sortable: false,
            filterable: false,
            valueGetter: (params: GridValueGetterParams) => renderCell({ discountRates: params.row.memberCardTypeDiscountRates, property: 'discountPercentBonus', id: priceSalesRound.id }),
        },
        {
            field: `bonusCashback-${index}`,
            headerName: `${BONUS} cashback %`,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            sortable: false,
            filterable: false,
            valueGetter: (params: GridValueGetterParams) => renderCell({ discountRates: params.row.memberCardTypeDiscountRates, property: 'cashbackPercentBonus', id: priceSalesRound.id }),
        },
        {
            field: `promo-${index}`,
            headerName: `${PROMO} % `,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            sortable: false,
            filterable: false,
            valueGetter: (params: GridValueGetterParams) => renderCell({ discountRates: params.row.memberCardTypeDiscountRates, property: 'discountPercentBonusPerFreeSlot', id: priceSalesRound.id }),
        },
        {
            field: `multiply-${index}`,
            headerName: MULTIPLYING_FACTOR,
            align: 'center',
            headerAlign: 'center',
            width: 150,
            type: 'number',
            sortable: false,
            filterable: false,
            valueGetter: (params: GridValueGetterParams) => renderCell({ discountRates: params.row.memberCardTypeDiscountRates, property: 'multiplyingFactor', id: priceSalesRound.id }),
        },
    );
};
