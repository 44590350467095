import { ERROR_PRICE_SEASON_DATE_OVERLAP, SUCCESS_SCHEDULE_CREATE } from 'constants/notificationMessage';
import { REASON_PRICE_SEASON_DATE_OVERLAP } from 'constants/reasons';

import { CreateScheduleFormFields } from 'shared/component/Modal/CreateScheduleModal/formData';
import useCreateSchedule from 'shared/data-hook/schedule/create/useCreateSchedule';

import { useNotification } from '../useNotification';

const useScheduleCreate = () => {
    const { error, loading, createSchedule: create } = useCreateSchedule();
    const { showError, showSuccess } = useNotification();

    const createSchedule = async (data: CreateScheduleFormFields) => {
        const { reason, status } = await create(data);

        if (error || status === 'error') {
            switch (reason) {
                case REASON_PRICE_SEASON_DATE_OVERLAP: {
                    showError(ERROR_PRICE_SEASON_DATE_OVERLAP);
                    break;
                }
                default:
                    showError(reason);
            }
        } else {
            showSuccess(SUCCESS_SCHEDULE_CREATE);
        }
    };

    return {
        createSchedule,
        loading,
    };
};

export default useScheduleCreate;
