import { useEffect } from 'react';
import useEmployee from 'shared/data-hook/employee/fetch/fetchOne/useEmployee';
import { useStore } from 'store';

const useFetchEmployee = (id: string) => {
    const { fetchEmployee, loading } = useEmployee();
    const { setEmployee } = useStore('updateEmployeeModal');

    useEffect(() => {
        if (!id) {
            return;
        }

        fetchEmployee(id).then((data) => {
            setEmployee(data);
        });
    }, [id]);

    return {
        loading,
    };
};

export default useFetchEmployee;
