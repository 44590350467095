import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { WaitingListDTO, WaitingListDTOT } from 'shared/data-hook/waitingList/fetch/DTO/WaitingListDTO';
import { WaitingListsT } from 'types/WaitingList';

const DTO = t.type({
    waitingLists: t.array(WaitingListDTO),
    waitingListsCount: AggregateDTO,
});

type WaitingListsDTOT = t.TypeOf<typeof DTO>;

export const WaitingListsDTO = tPromise.extendDecoder<WaitingListsDTOT, WaitingListsT>(
    DTO,
    ({
        waitingLists,
        waitingListsCount,
    }) => ({
        waitingLists,
        waitingListsCount,
    }),
) as t.Type<WaitingListsDTOT, WaitingListDTOT>;
