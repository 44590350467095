import {
    ERROR_SERVICE_APP_DOCUMENT_UPDATE, SUCCESS_SERVICE_APP_DOCUMENT_UPDATE,
} from 'constants/notificationMessage';

import { ServiceAppDocumentFormFields } from 'page/ServiceAppDocuments/components/formData';
import useServiceAppDocumentUpdate from 'shared/data-hook/serviceAppDocument/update/useServiceAppDocumentUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdateServiceAppDocument = () => {
    const { error, documentUpdate, loading } = useServiceAppDocumentUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateDocument = async (data: ServiceAppDocumentFormFields, id: string) => {
        const response = await documentUpdate(data, id);

        if (error || !response) {
            showError(ERROR_SERVICE_APP_DOCUMENT_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_SERVICE_APP_DOCUMENT_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updateDocument,
        loading,
    };
};

export default useUpdateServiceAppDocument;
