import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceExtendedDTO } from 'shared/data-hook/price/fetch/DTO/PriceExtendedDTO';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PricesT } from 'types/Price';

const DTO = (currency: string) => t.type({
    prices: t.array(PriceExtendedDTO(currency)),
    pricesCount: AggregateDTO,
});

export type PricesDTOT = t.TypeOf<ReturnType<typeof DTO>>;

export const PricesDTO = (currency: string) => tPromise.extendDecoder<PricesDTOT, PricesT>(
    DTO(currency),
    ({
        prices,
        pricesCount,
    }) => ({
        prices,
        pricesCount,
    }),
) as t.Type<PricesT, PricesDTOT>;
