import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { ServiceCategoryT } from 'types/ServiceCategory';

export type ServiceCategoryResponseT = {
    serviceCategory: ServiceCategoryT;
}

export type ServiceCategoriesResponseT = {
    serviceCategories: ServiceCategoryT[];
    serviceCategoriesCount: AggregateT;
};

export const SERVICE_CATEGORY_FILTER_OPTIONS_QUERY = gql`
    query ServiceCategoryFilterOptions {
        filterOptions: service_category(
            order_by: { name: asc }
        ) {
            value: id
            label: name
        }
    }
`;

export const SERVICE_CATEGORIES_QUERY = gql`
    query ServiceCategory(
        $limit: Int,
        $offset: Int,
        $orderBy: service_category_order_by! = {},
        $where: service_category_bool_exp! = {}
    ) {
        serviceCategories: service_category(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            name
            color
            min_duration
            min_additional_duration
            position
            allowed_for_client
        }
        serviceCategoriesCount: service_category_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const SERVICE_CATEGORY_QUERY = gql`
    query ServiceCategory($id: String!) {
        serviceCategory: service_category_by_pk(id: $id) {
            id
            name
            color
            min_duration
            min_additional_duration
            position
            allowed_for_client
        }
    }
`;
