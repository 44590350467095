import {
    ERROR_WAITING_LIST_UPDATE, SUCCESS_WAITING_LIST_UPDATE,
} from 'constants/notificationMessage';

import { WaitingListFormFields } from 'page/WaitingList/components/formData';
import useWaitingListUpdate from 'shared/data-hook/waitingList/update/useWaitingListUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { WaitingListT } from 'types/WaitingList';

const useUpdateWaitingList = () => {
    const { error, waitingListUpdate, loading } = useWaitingListUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateWaitingList = async (id: WaitingListT['id'], data: WaitingListFormFields) => {
        const response = await waitingListUpdate(id, data);

        if (error || !response) {
            showError(ERROR_WAITING_LIST_UPDATE);
        } else {
            showSuccess(SUCCESS_WAITING_LIST_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateWaitingList,
        loading,
    };
};

export default useUpdateWaitingList;
