import * as React from 'react';
import {
    FieldValues, useController,
} from 'react-hook-form';
import { InputLabel, Skeleton, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';
import { getOptions } from 'shared/utils/getOptions';
import { OptionT } from 'types/Option';

type PropsT = {
    label: string;
    loading?: boolean;
    options: OptionT[];
    size?: 'small';
    selectedValue: string;
    empty?: boolean;
};

const SelectWithSearch = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, size, loading, options, selectedValue, empty,
    } = props;

    const { field, fieldState } = useController({
        name,
        control,
    });

    const preparedSelectedValue =
        selectedValue === ''
            ? null
            : {
                value: selectedValue,
                label: options.find((option) => option.value === selectedValue)!.label,
            };

    const optionsWithEmpty = getOptions(options, empty);

    return (
        <Autocomplete
            isOptionEqualToValue={ (option, value) => option.value === value.value }
            getOptionLabel={ (option) => option.label }
            value={ preparedSelectedValue }
            disablePortal
            options={ optionsWithEmpty }
            onChange={ (_, data) => {
                if (data !== null) {
                    field.onChange(data.value);
                }
            } }
            renderOption={ (prop, option) => (
                <li { ...prop } key={ option.value }>
                    { option.label }
                </li>
            ) }
            renderInput={ (params) => (
                <>
                    { label && <InputLabel>{ label }</InputLabel> }
                    { loading ? (
                        <Skeleton variant="rounded" height={ size ? 40 : 56 } />
                    ) : (
                        <TextField
                            { ...params }
                            size={ size || 'medium' }
                            error={ !!fieldState.error }
                            helperText={
                                (fieldState.error?.type === FormErrorMessages.Required &&
                                    `${COMMON_FORM_ERRORS[FormErrorMessages.Required]}`) ||
                                (fieldState.error?.type === FormErrorMessages.TypeError &&
                                    `${COMMON_FORM_ERRORS[FormErrorMessages.Required]}`) ||
                                fieldState.error?.message
                            }
                        />
                    ) }
                </>
            ) }
        />
    );
};

export default SelectWithSearch;
