import { DEFAULT_LOCALE } from 'constants/locale';
import {
    PROPERTY_ACQUIRING_NOTIFICATION_BOT_IDS,
    PROPERTY_DEFAULT_CREDIT_FOR_NEW_CLIENTS, PROPERTY_DEFAULT_ORGANIZATION_LOCATION_ID,
    PROPERTY_DESCRIPTION,
    PROPERTY_DOMAIN_NAME, PROPERTY_ENTRY_NOTIFICATION_BOT_IDS,
    PROPERTY_FIAT_CURRENCY_TITLE, PROPERTY_FRONTEND_APPS_ARE_DISABLED,
    PROPERTY_HASH,
    PROPERTY_LABEL,
    PROPERTY_LOCALE,
    PROPERTY_SMS_SENDER_NAME,
    PROPERTY_SMS_SENDING_ENABLED,
    PROPERTY_TELEGRAM_BOT,
    PROPERTY_TIMEZONE,
    PROPERTY_TOKEN,
    PROPERTY_URL,
    PROPERTY_VIDEO_ANALYTICS_BOT_IDS,
} from 'constants/propertyNames';
import { DEFAULT_SITE_TITLE } from 'constants/siteTitle';
import { DEFAULT_TIMEZONE } from 'constants/timezone';

import { omit } from 'lodash';
import KeyableEntity from 'types/KeyableEntity';
import {
    array, bool, number, object, string,
} from 'yup';

export type ServiceAppFormFields = {
    label: string;
    token: string;
    description: string;
    url: string;
    hash: string;
    timezone: number;
    fiatCurrencyTitle: string;
    videoAnalyticsTelegramBotChatIds: Array<KeyableEntity<string>>;
    entryNotificationTelegramBotChatIds: Array<KeyableEntity<string>>;
    acquiringNotificationTelegramBotChatIds: Array<KeyableEntity<string>>;
    domainName: string;
    smsSenderName: string;
    smsSendingEnabled: boolean;
    locale: string;
    defaultCreditForNewClients: number;
    defaultOrganizationLocationId: string;
    frontendAppsAreDisabled: boolean;
    telegramNotificationBot: string;
};

export const SERVICE_APP_DEFAULT_VALUES: ServiceAppFormFields = {
    label: DEFAULT_SITE_TITLE,
    token: '',
    description: DEFAULT_SITE_TITLE,
    url: '',
    hash: '',
    timezone: DEFAULT_TIMEZONE,
    fiatCurrencyTitle: '',
    videoAnalyticsTelegramBotChatIds: [],
    entryNotificationTelegramBotChatIds: [],
    acquiringNotificationTelegramBotChatIds: [],
    domainName: '',
    smsSenderName: '',
    smsSendingEnabled: false,
    locale: DEFAULT_LOCALE,
    defaultCreditForNewClients: 0,
    defaultOrganizationLocationId: '',
    frontendAppsAreDisabled: false,
    telegramNotificationBot: '',
};

export const SERVICE_APP_FIELDS = Object.keys(omit(SERVICE_APP_DEFAULT_VALUES, [
    'timezone',
    'videoAnalyticsTelegramBotChatIds',
    'entryNotificationTelegramBotChatIds',
    'acquiringNotificationTelegramBotChatIds',
    'smsSendingEnabled',
    'locale',
    'defaultOrganizationLocationId',
    'frontendAppsAreDisabled',
]));

export enum SERVICE_APP_FIELDS_KEYS {
    label = 'label',
    token = 'token',
    description = 'description',
    url = 'url',
    hash = 'hash',
    timezone = 'timezone',
    fiatCurrencyTitle = 'fiatCurrencyTitle',
    videoAnalyticsTelegramBotChatIds = 'videoAnalyticsTelegramBotChatIds',
    entryNotificationTelegramBotChatIds = 'entryNotificationTelegramBotChatIds',
    acquiringNotificationTelegramBotChatIds = 'acquiringNotificationTelegramBotChatIds',
    domainName = 'domainName',
    smsSenderName = 'smsSenderName',
    smsSendingEnabled = 'smsSendingEnabled',
    locale = 'locale',
    defaultCreditForNewClients = 'defaultCreditForNewClients',
    defaultOrganizationLocationId = 'defaultOrganizationLocationId',
    frontendAppsAreDisabled = 'frontendAppsAreDisabled',
    telegramNotificationBot = 'telegramNotificationBot',
}

type ServiceAppLabelDataT = Record<keyof ServiceAppFormFields, string>;

export const SERVICE_APP_LABEL_DATA: ServiceAppLabelDataT = {
    label: PROPERTY_LABEL,
    token: PROPERTY_TOKEN,
    description: PROPERTY_DESCRIPTION,
    url: PROPERTY_URL,
    hash: PROPERTY_HASH,
    timezone: PROPERTY_TIMEZONE,
    fiatCurrencyTitle: PROPERTY_FIAT_CURRENCY_TITLE,
    videoAnalyticsTelegramBotChatIds: PROPERTY_VIDEO_ANALYTICS_BOT_IDS,
    entryNotificationTelegramBotChatIds: PROPERTY_ENTRY_NOTIFICATION_BOT_IDS,
    acquiringNotificationTelegramBotChatIds: PROPERTY_ACQUIRING_NOTIFICATION_BOT_IDS,
    domainName: PROPERTY_DOMAIN_NAME,
    smsSenderName: PROPERTY_SMS_SENDER_NAME,
    smsSendingEnabled: PROPERTY_SMS_SENDING_ENABLED,
    locale: PROPERTY_LOCALE,
    defaultCreditForNewClients: PROPERTY_DEFAULT_CREDIT_FOR_NEW_CLIENTS,
    defaultOrganizationLocationId: PROPERTY_DEFAULT_ORGANIZATION_LOCATION_ID,
    frontendAppsAreDisabled: PROPERTY_FRONTEND_APPS_ARE_DISABLED,
    telegramNotificationBot: PROPERTY_TELEGRAM_BOT,
};

export const SERVICE_APP_VALIDATION_SCHEMA = object().shape({
    label: string().required(),
    token: string().required(),
    description: string().required(),
    url: string().required(),
    hash: string().required(),
    timezone: number().required(),
    fiatCurrencyTitle: string().required(),
    videoAnalyticsTelegramBotChatIds: array().of(
        object().shape({
            id: string().required(),
        }),
    ),
    entryNotificationTelegramBotChatIds: array().of(
        object().shape({
            id: string().required(),
        }),
    ),
    acquiringNotificationTelegramBotChatIds: array().of(
        object().shape({
            id: string().required(),
        }),
    ),
    domainName: string().required(),
    smsSenderName: string().required(),
    smsSendingEnabled: bool().required(),
    locale: string().required(),
    defaultCreditForNewClients: number().required(),
});
