export type SearchT = {
    without: boolean;
}

export const getCoachesWithoutCategoryFilter = (without: SearchT) => ({
    ...(without && {
        category_id: {
            _is_null: true,
        },
    }),
});
