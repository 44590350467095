import { useStore } from 'store';
import { PriceGrouping, PriceGroupingT, PriceT } from 'types/Price';
import { PricesByCoachCategoryT } from 'types/PricesByCoachCategory';
import { PricesByGroupViewT } from 'types/PricesByGroupView';
import { PricesByServiceCategoryT } from 'types/PricesByServiceCategory';

export type RowsT = PricesByCoachCategoryT[] | PricesByServiceCategoryT[] | PricesByGroupViewT[] | PriceT[];

type DataT = {
    rows: RowsT;
    count: number;
}

type ReturnT = {
    data: DataT;
}

export const useGroupedPriceData = (): ReturnT => {
    const { pricesByServiceCategoryList, count: serviceCategoriesCount } = useStore('pricesByServiceCategory');
    const { pricesByCoachCategoryList, count: coachCategoriesCount } = useStore('pricesByCoachCategory');
    const { pricesByGroupViewList, count: groupViewsCount } = useStore('pricesByGroupView');
    const { priceList, count: pricesCount } = useStore('price');
    const { priceGrouping } = useStore('selectPriceGroupingModal');

    const mapPriceGroupingToFetchFunction: Record<PriceGroupingT, DataT> = {
        [PriceGrouping.Coach]: { rows: pricesByCoachCategoryList, count: coachCategoriesCount },
        [PriceGrouping.Service]: { rows: pricesByServiceCategoryList, count: serviceCategoriesCount },
        [PriceGrouping.GroupView]: { rows: pricesByGroupViewList, count: groupViewsCount },
        [PriceGrouping.Empty]: { rows: priceList, count: pricesCount },
    };

    return { data: mapPriceGroupingToFetchFunction[priceGrouping] };
};
