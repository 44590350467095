import { useEffect } from 'react';
import usePrices from 'shared/data-hook/price/fetch/fetchAll/usePrices';
import usePricesByCoachCategories
    from 'shared/data-hook/pricesByCoachCategory/fetch/fetchAll/usePricesByCoachCategories';
import usePricesByGroupViews from 'shared/data-hook/pricesByGroupViews/fetch/fetchAll/usePricesByGroupViews';
import usePricesByServiceCategories
    from 'shared/data-hook/pricesByServiceCategory/fetch/fetchAll/usePricesByServiceCategories';
import { useStore } from 'store';
import { FetchDataParams } from 'types/DataGrid';
import { PriceGrouping, PriceGroupingT } from 'types/Price';

type PropsT = {
    priceSeasonId?: string;
    priceTypeId?: string;
}
type ReturnT = {
    fetch: FetchPricesT;
    loading: boolean;
}
type FetchPricesT = (priceTypeId: string, priceSeasonId: string, params?: FetchDataParams) => Promise<void>;

const useFetchPricesByCategories = (props: PropsT): ReturnT => {
    const { priceSeasonId, priceTypeId } = props;
    const { setPricesByServiceCategories } = useStore('pricesByServiceCategory');
    const { setPricesByCoachCategories } = useStore('pricesByCoachCategory');
    const { setPricesByGroupViews } = useStore('pricesByGroupView');
    const { setPrices } = useStore('price');
    const { priceGrouping } = useStore('selectPriceGroupingModal');

    const {
        data: pricesByServiceCategoriesData,
        loading: pricesByServiceCategoriesLoading,
        count: pricesByServiceCategoriesCount,
        fetchPricesByServiceCategories,
    } = usePricesByServiceCategories();

    const {
        data: pricesByCoachCategoriesData,
        loading: pricesByCoachCategoriesLoading,
        count: pricesByCoachCategoriesCount,
        fetchPricesByCoachCategories,
    } = usePricesByCoachCategories();

    const {
        data: pricesByGroupViewsData,
        loading: pricesByGroupViewsLoading,
        count: pricesByGroupViewsCount,
        fetchPricesByGroupViews,
    } = usePricesByGroupViews();

    const {
        data: pricesData,
        loading: pricesLoading,
        count: pricesCount,
        fetchPrices,
    } = usePrices();

    const loading = pricesLoading || pricesByServiceCategoriesLoading || pricesByCoachCategoriesLoading || pricesByGroupViewsLoading;

    useEffect(() => {
        if (priceSeasonId && priceTypeId) {
            fetchPricesByCoachCategories(priceTypeId, priceSeasonId).then();
            fetchPricesByServiceCategories(priceTypeId, priceSeasonId).then();
            fetchPricesByGroupViews(priceTypeId, priceSeasonId).then();
            fetchPrices(priceTypeId, priceSeasonId).then();
        }
    }, []);

    useEffect(() => {
        if (pricesByServiceCategoriesData) {
            setPricesByServiceCategories(pricesByServiceCategoriesData, pricesByServiceCategoriesCount);
        }
    }, [pricesByServiceCategoriesLoading, pricesByServiceCategoriesData]);

    useEffect(() => {
        if (pricesByCoachCategoriesData) {
            setPricesByCoachCategories(pricesByCoachCategoriesData, pricesByCoachCategoriesCount);
        }
    }, [pricesByCoachCategoriesLoading, pricesByCoachCategoriesData]);

    useEffect(() => {
        if (pricesByGroupViewsData) {
            setPricesByGroupViews(pricesByGroupViewsData, pricesByGroupViewsCount);
        }
    }, [pricesByGroupViewsLoading, pricesByGroupViewsData]);

    useEffect(() => {
        if (pricesData) {
            setPrices(pricesData, pricesCount);
        }
    }, [pricesLoading, pricesData]);

    const mapPriceGroupingToFetchFunction: Record<PriceGroupingT, FetchPricesT> = {
        [PriceGrouping.Coach]: fetchPricesByCoachCategories,
        [PriceGrouping.Service]: fetchPricesByServiceCategories,
        [PriceGrouping.GroupView]: fetchPricesByGroupViews,
        [PriceGrouping.Empty]: fetchPrices,
    };

    return { fetch: mapPriceGroupingToFetchFunction[priceGrouping], loading };
};

export default useFetchPricesByCategories;
