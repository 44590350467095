import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { GroupCategoryT } from 'types/GroupCategory';

export type GroupCategoryResponseT = {
    groupCategory: GroupCategoryT;
};

export type GroupCategoriesResponseT = {
    groupCategories: GroupCategoryT[];
    groupCategoriesCount: AggregateT;
};

export const GROUP_CATEGORY_QUERY = gql`
    query GroupCategory($id: String!) {
        groupCategory: group_category_by_pk(id: $id) {
            id
            name
            group_views {
                id
                name
                age_type {
                    name
                }
                group_category {
                    name
                }
                min_size
                avg_size
                max_size
                clients_for_main_coach
                correction_factors
                ignore_busy_coaches
                group_constant_price
            }
        }
    }
`;

export const GROUP_CATEGORIES_QUERY = gql`
    query GroupCategories(
        $limit: Int
        $offset: Int
        $orderBy: group_category_order_by! = {}
        $where: group_category_bool_exp = {}
        $name: String!
    ) {
        groupCategories: group_category(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            name
            group_views(where: { name: { _iregex: $name } }) {
                id
                name
                age_type {
                    name
                }
                group_category {
                    name
                }
                min_size
                avg_size
                max_size
                clients_for_main_coach
                correction_factors
                ignore_busy_coaches
                group_constant_price
            }
        }
        groupCategoriesCount: group_category_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
