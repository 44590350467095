import { useMutation } from '@apollo/client';
import { CreateScheduleFormFields } from 'shared/component/Modal/CreateScheduleModal/formData';
import {
    CREATE_SCHEDULE, CreateScheduleResponseT,
    CreateScheduleT,
    CreateScheduleVarT, EMPTY_CREATE_SCHEDULE_RESPONSE,
} from 'shared/graphql/mutation/schedule/CreateSchedule';
import { useStore } from 'store';

import { CreateScheduleDataT } from './CreateScheduleData';

const useCreateSchedule = (): CreateScheduleDataT => {
    const [create, { loading, error }] = useMutation<CreateScheduleT, CreateScheduleVarT>(
        CREATE_SCHEDULE,
    );

    const { serviceApp } = useStore('serviceApp');

    const createSchedule = async (requestData: CreateScheduleFormFields) => {
        try {
            const {
                priceSeasonId, priceTypeIds, recreateSlotRates, recreateSlots,
            } = requestData;
            const { data } = await create({
                variables: {
                    appId: serviceApp.id,
                    payload: priceTypeIds.map((priceTypeId) => ({
                        price_season_id: priceSeasonId,
                        price_type_id: priceTypeId,
                        recreate_slots: recreateSlots,
                        recreate_slot_rates: recreateSlotRates,
                    })),
                },
            });

            return data?.createSchedule as CreateScheduleResponseT;
        } catch {
            return EMPTY_CREATE_SCHEDULE_RESPONSE;
        }
    };

    return {
        createSchedule,
        loading,
        error,
    };
};

export default useCreateSchedule;
