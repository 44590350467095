import { useMutation } from '@apollo/client';
import { GroupCategoryDeleteDataT } from 'shared/data-hook/groupCategory/delete/single/GroupCategoryDeleteData';
import {
    GROUP_CATEGORY_DELETE,
    GroupCategoryDeleteResponseDataT,
    GroupCategoryDeleteVarT,
} from 'shared/graphql/mutation/groupCategory/GroupCategoryDelete';
import { GroupCategoryT } from 'types/GroupCategory';

const useGroupCategoryDelete = (): GroupCategoryDeleteDataT => {
    const [deleteGroupCategory, { loading, error }] = useMutation<
        GroupCategoryDeleteResponseDataT,
        GroupCategoryDeleteVarT
    >(GROUP_CATEGORY_DELETE);

    const deleteAction = async (GroupCategoryId: GroupCategoryT['id']) => {
        try {
            const { data } = await deleteGroupCategory({
                variables: {
                    id: GroupCategoryId,
                },
            });

            return data;
        } catch (errorDelete) {
            console.error((errorDelete as Error).message); // eslint-disable-line
            throw errorDelete;
        }
    };

    return {
        deleteGroupCategory: deleteAction,
        loading,
        error,
    };
};

export default useGroupCategoryDelete;
