import { gql } from '@apollo/client';

export type WaitingListFields = {
    client_data_id: string;
    group_id: string | null;
    group_document_id: string | null;
    organization_id: string;
    service_category_id: string | null;
    price_type_id: string;
    coach_price_type_id: string | null;
    user_id: string;
    duration: number;
    datetime: string;
    top_priority: boolean | null;
    comment: string | null;
    created_at: string;
};

export type WaitingListUpdateVar = {
    id: string;
    set: WaitingListFields;
};

export type WaitingListUpdateResponseDataT = {
    updateWaitingList: {
        id: string;
    };
} | null | undefined;

export const WAITING_LIST_UPDATE = gql`
    mutation UpdateWaitingList(
        $set: waiting_list_set_input = {},
        $id: String = ""
    ) {
        updateWaitingList: update_waiting_list_by_pk(
            pk_columns: { id: $id },
            _set: $set
        ) {
            id
        }
    }
`;
