import {
    ERROR_SERVICE_APP_DOCUMENT_CREATE, SUCCESS_SERVICE_APP_DOCUMENT_CREATE,
} from 'constants/notificationMessage';

import { ServiceAppDocumentFormFields } from 'page/ServiceAppDocuments/components/formData';
import useServiceAppDocumentCreate from 'shared/data-hook/serviceAppDocument/create/useServiceAppDocumentCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateServiceAppDocument = () => {
    const { error, loading, createDocument: actionCreate } = useServiceAppDocumentCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createDocument = async (input: ServiceAppDocumentFormFields): Promise<boolean> => {
        await actionCreate(input);

        if (error) {
            showError(ERROR_SERVICE_APP_DOCUMENT_CREATE);

            return false;
        }

        showSuccess(SUCCESS_SERVICE_APP_DOCUMENT_CREATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        createDocument,
        loading,
    };
};

export default useCreateServiceAppDocument;
