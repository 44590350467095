// Прайс
export const PAGE_TITLE_PRICE = 'Прайс';
export const PAGE_TITLE_PRICE_UPDATE = 'Редактирование прайса';
export const PAGE_TITLE_PRICE_CREATE = 'Создание прайса';

// Тип прайса
export const PAGE_TITLE_PRICE_TYPE = 'Настройка услуг';
export const PAGE_TITLE_PRICE_TYPE_UPDATE = 'Редактирование типа прайса';
export const PAGE_TITLE_PRICE_TYPE_CREATE = 'Создание типа прайса';
export const PAGE_TITLE_PRICE_MEMBER_CARDS = 'Прайс для членских карт';

// Сезон прайса
export const PAGE_TITLE_PRICE_SEASON_UPDATE = 'Редактирование сезона прайса';
export const PAGE_TITLE_PRICE_SEASON_CREATE = 'Создание сезона прайса';

// Виды членских карт
export const PAGE_TITLE_MEMBER_CARD_TYPE = 'Виды членских карт';
export const PAGE_TITLE_MEMBER_CARD_TYPE_UPDATE = 'Редактирование членской карты';

// Выданные членские карты
export const PAGE_TITLE_MEMBER_CARD = 'Выданные членские карты';

// Массовые операции
export const PAGE_TITLE_BULK_OPERATIONS = 'Массовые операции';

// Категории услуг
export const PAGE_TITLE_SERVICE_CATEGORY = 'Категории услуг';

// Занятые клетки
export const PAGE_TITLE_SCHEDULE_SLOT_RESERVE = 'Занятые клетки';

// Документы резервирования
export const PAGE_TITLE_IMPLEMENTATION_DOCUMENT = 'Документы резервирования';

// Резервирования
export const PAGE_TITLE_IMPLEMENTATION = 'Резервирования';

// Типы кортов
export const PAGE_TITLE_COURT_TYPE = 'Типы кортов';

// Корты
export const PAGE_TITLE_COURT = 'Корты';

// Категории кортов
export const PAGE_TITLE_COURT_CATEGORY = 'Категории кортов';

// Настройка типов кортов
export const PAGE_TITLE_COURT_SETTING = 'Настройка площадок';

export const PAGE_TITLE_COURT_VIDEO_ANALYTICS = 'Видеоаналитика кортов';

// Реквизиты организации
export const PAGE_TITLE_ORGANIZATION = 'Реквизиты организации';

// Локации
export const PAGE_TITLE_ORGANIZATION_LOCATION = 'Локации';

// Сотрудники
export const PAGE_TITLE_EMPLOYEE = 'Сотрудники';

// Настройка приложения
export const PAGE_TITLE_SERVICE_APP = 'Настройка приложения';

// Настройки интернет-эйквайринга
export const PAGE_TITLE_INTERNET_ACQUIRING = 'Настройки интернет-эквайринга';

// Клиенты
export const PAGE_TITLE_CLIENT = 'Клиенты';

// Отчет по количеству часов тренера
export const PAGE_TITLE_COACH_HOURS_REPORT = 'Отчет по количеству часов тренера';

// Тренеры в группах
export const PAGE_TITLE_COACHES_IN_GROUPS = 'Тренеры в группах';

// Платежи
export const PAGE_TITLE_PAYMENT = 'Платежи';

// Платежные документы
export const PAGE_TITLE_PAYMENT_DOCUMENT = 'Платежные документы';

// Типы платежей
export const PAGE_TITLE_PAYMENT_TYPE = 'Типы платежей';

// Методы транзакций
export const PAGE_TITLE_PAYMENT_METHOD = 'Способы оплаты';

// Настройка платежей
export const PAGE_TITLE_PAYMENT_SETTING = 'Настройка платежей';

//  Виды групп
export const PAGE_TITLE_GROUP_VIEW = 'Виды групп';

//  Настройка видов групп
export const PAGE_TITLE_GROUP_VIEW_SETTING = 'Настройка категорий видов групп';

//  Тренера
export const PAGE_TITLE_COACH = 'Тренеры';

// Настройка тренеров
export const PAGE_TITLE_COACH_SETTINGS = 'Настройка категорий тренеров';

// Настройки CRM
export const PAGE_TITLE_CRM_SETTINGS_TEMPLATES = 'Шаблоны задач';
export const PAGE_TITLE_CRM_SETTINGS_STATUS = 'Статусы';

// Импорт / экспорт
export const PAGE_TITLE_IMPORT_PRICE = 'Импорт прайсов';

// Возраст
export const PAGE_TITLE_AGE = 'Возрастные группы';

// PRT
export const PAGE_TITLE_PRT = 'ПРТ';

// Документы
export const PAGE_TITLE_DOCUMENTS = 'Документы';

// Листы ожидания
export const PAGE_TITLE_WAITING_LIST = 'Листы ожидания';
