import { useMutation } from '@apollo/client';
import { EmployeeFormFields } from 'page/Employee/components/formData';
import {
    CREATE_EMPLOYEE, CreateEmployeeResponseT,
    CreateEmployeeT,
    CreateEmployeeVarT, EMPTY_CREATE_EMPLOYEE_RESPONSE,
} from 'shared/graphql/mutation/employee/EmployeeCreate';

import { CreateEmployeeDTO } from './DTO/CreateWaitingListDTO';
import { CreateEmployeeDataT } from './CreateEmployeeData';

const useEmployeeCreate = (): CreateEmployeeDataT => {
    const [create, { loading, error }] = useMutation<CreateEmployeeT, CreateEmployeeVarT>(
        CREATE_EMPLOYEE,
    );

    const createEmployee = async (input: EmployeeFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: CreateEmployeeDTO(input),
                },
            });

            return data?.adminEmployeeCreate as CreateEmployeeResponseT;
        } catch {
            return EMPTY_CREATE_EMPLOYEE_RESPONSE;
        }
    };

    return {
        createEmployee,
        loading,
        error,
    };
};

export default useEmployeeCreate;
