import { PROPERTY_ACTIONS, PROPERTY_TITLE, PROPERTY_WITHOUT_CATEGORY } from 'constants/propertyNames';

import React, { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteGroupCategory from 'shared/hook/groupCategory/delete/useDeleteGroupCategory';
import { useStore } from 'store';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title, name } } = props;
    const { onOpen: handleOpenModal } = useStore('updateGroupCategoryModal');
    const { groupCategoryDelete } = useDeleteGroupCategory();

    if (name === PROPERTY_WITHOUT_CATEGORY) {
        return null;
    }

    return (
        <>
            <IconButton onClick={ () => handleOpenModal(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ groupCategoryDelete } />
        </>
    );
};

export const GROUP_CATEGORY_HEADER_DATA: GridColumns = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 1,
        type: 'string',
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        minWidth: 100,
        flex: 0.2,
        align: 'center',
        headerAlign: 'center',
    },
];
