import { PriceT } from './Price';

export type PricesByCoachCategoryT = {
    id: string;
    name: string;
    prices: PriceT[];
};

export type PricesByCoachCategoriesT = {
    pricesByCoachCategories: PricesByCoachCategoryT[];
    pricesByCoachCategoriesCount: number;
};

export const EMPTY_PRICES_BY_COACH_CATEGORIES_RESULT: PricesByCoachCategoriesT = {
    pricesByCoachCategories: [],
    pricesByCoachCategoriesCount: 0,
};
