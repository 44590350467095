import {
    PROPERTY_GROUP_CATEGORY as GROUP_VIEWS_GROUPING_GROUP_VIEW_LABEL,
    PROPERTY_WITHOUT_GROUPING as GROUP_VIEWS_GROUPING_WITHOUT_LABEL,
} from 'constants/propertyNames';

import { GroupViewsGrouping } from 'types/GroupView';

export const GROUP_VIEWS_GROUPING_OPTIONS = [
    {
        value: GroupViewsGrouping.Empty,
        label: GROUP_VIEWS_GROUPING_WITHOUT_LABEL,
    },
    {
        value: GroupViewsGrouping.GroupCategory,
        label: GROUP_VIEWS_GROUPING_GROUP_VIEW_LABEL,
    },
];
