import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PricesByCoachCategoriesT } from 'types/PricesByCoachCategory';

import { PricesByCoachCategoryDTO } from './PricesByCoachCategoryDTO';

const DTO = (currency: string) => t.type({
    pricesByCoachCategories: t.array(PricesByCoachCategoryDTO(currency)),
    pricesByCoachCategoriesCount: AggregateDTO,
});

export type PricesByCoachCategoriesDTOT = t.TypeOf<ReturnType<typeof DTO>>;

export const PricesByCoachCategoriesDTO = (currency: string) => tPromise.extendDecoder<PricesByCoachCategoriesDTOT, PricesByCoachCategoriesT>(
    DTO(currency),
    ({
        pricesByCoachCategories,
        pricesByCoachCategoriesCount,
    }) => ({
        pricesByCoachCategories,
        pricesByCoachCategoriesCount,
    }),
) as t.Type<PricesByCoachCategoriesT, PricesByCoachCategoriesDTOT>;
