import { action, makeObservable, observable } from 'mobx';
import { EmployeeT, EMPTY_EMPLOYEE_RESULT } from 'types/Employee';

import BaseModalStore from './BaseModalStore';

export default class UpdateEmployeeModal extends BaseModalStore {
    public employee: EmployeeT = EMPTY_EMPLOYEE_RESULT;

    public setEmployee(employee: EmployeeT) {
        this.employee = employee;
    }

    constructor() {
        super();

        makeObservable(this, {
            employee: observable,
            setEmployee: action.bound,
        });
    }
}
