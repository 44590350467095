import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PaymentT } from 'types/Payment';

const DTO = t.type({
    id: t.string,
    payment_document: t.type({
        id: t.string,
        number: t.string,
        is_archive: Nullable(t.boolean),
    }),
    place: Nullable(t.string),
    service: Nullable(t.string),
    operation: Nullable(t.string),
    reason: Nullable(t.string),
    datetime: t.string,
    client_id: Nullable(t.string),
    payer: Nullable(t.string),
    payment_method: Nullable(t.type({
        id: t.string,
        title: t.string,
    })),
    performer: Nullable(t.string),
    user_id: Nullable(t.string),
    comment: Nullable(t.string),
    organization_location: Nullable(t.type({
        id: t.string,
        name: t.string,
    })),
    total: t.number,
    payment_in_fiat: Nullable(t.number),
    payment_in_bonus: Nullable(t.number),
    payment_type: Nullable(t.type({
        id: t.string,
        title: t.string,
    })),
    client_datum: t.type({
        client: t.type({
            full_name: t.string,
        }),
    }),
});

export type PaymentDTOT = t.TypeOf<typeof DTO>;

export const PaymentDTO = tPromise.extendDecoder<PaymentDTOT, PaymentT>(
    DTO,
    ({
        id,
        payment_document,
        datetime,
        client_id,
        payer,
        payment_method,
        performer,
        user_id,
        comment,
        organization_location,
        total,
        payment_in_fiat,
        payment_in_bonus,
        payment_type,
        client_datum,
        place,
        service,
        operation,
        reason,
    }) => ({
        id,
        paymentDocument: {
            id: payment_document.id,
            number: payment_document.number,
            isArchive: payment_document.is_archive,
        },
        datetime: new Date(datetime),
        clientId: client_id,
        payer,
        place,
        service,
        operation,
        reason,
        paymentMethod: payment_method,
        performer,
        userId: user_id,
        comment: comment ?? '',
        organizationLocation: organization_location,
        total,
        paymentInFiat: payment_in_fiat,
        paymentInBonus: payment_in_bonus,
        paymentType: payment_type,
        clientDatum: {
            fullName: client_datum.client.full_name,
        },
    }),
) as t.Type<PaymentT, PaymentDTOT>;
