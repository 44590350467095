import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { WaitingListsDTO } from 'shared/data-hook/waitingList/fetch/DTO/WaitingListsDTO';
import { WaitingListsDataT } from 'shared/data-hook/waitingList/fetch/fetchAll/WaitingListsData';
import { WAITING_LISTS_QUERY, WaitingListsResponseT } from 'shared/graphql/query/waitingList/WaitingListQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_WAITING_LISTS_RESULT } from 'types/WaitingList';

const useWaitingLists = (): WaitingListsDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<WaitingListsResponseT, QueryVarsT>(
        WAITING_LISTS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedWaitingListsData = decodeDTO(
        WaitingListsDTO,
        EMPTY_WAITING_LISTS_RESULT,
        (waitingLists) => waitingLists,
        {
            waitingLists: data?.waitingLists,
            waitingListsCount: data?.waitingListsCount,
        },
    );

    const fetchWaitingLists = async ({
        limit,
        offset,
        orderBy = { created_at: 'desc' },
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedWaitingListsData.waitingLists,
        count: decodedWaitingListsData.waitingListsCount,
        fetchWaitingLists,
        loading,
        error,
    };
};

export default useWaitingLists;
