import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { GroupViewDTO } from 'shared/data-hook/groupView/fetch/DTO/GroupViewDTO';
import { GroupCategoryT } from 'types/GroupCategory';

const DTO = t.type({
    id: t.string,
    name: t.string,
    group_views: t.array(
        GroupViewDTO,
    ),
});

export type GroupCategoryDTOT = t.TypeOf<typeof DTO>;

export const GroupCategoryDTO = tPromise.extendDecoder<GroupCategoryDTOT, GroupCategoryT>(
    DTO,
    ({
        id,
        name,
        group_views,
    }) => ({
        id,
        name,
        groupViews: group_views,
    }),
) as t.Type<GroupCategoryT, GroupCategoryDTOT>;
