import { action, makeObservable, observable } from 'mobx';
import BaseModalStore from 'store/modal/BaseModalStore';
import { EMPTY_WAITING_LIST_RESULT, WaitingListT } from 'types/WaitingList';

export default class UpdateWaitingListModal extends BaseModalStore {
    public waitingList: WaitingListT = EMPTY_WAITING_LIST_RESULT;

    public setWaitingList(waitingList: WaitingListT) {
        this.waitingList = waitingList;
    }

    constructor() {
        super();

        makeObservable(this, {
            waitingList: observable,
            setWaitingList: action.bound,
        });
    }
}
