import { EMPTY_CELL } from 'constants/dataGrid';

import React from 'react';
import { Chip } from '@mui/material';
import KeyableEntity from 'types/KeyableEntity';

import { FlexWrapBox } from '../domain/Styled';

type RenderChipsBaseItem = KeyableEntity<string> & Record<string, string>;

type RenderChipsProps<T> = {
    data: T[];
    getId: (item: T) => string;
    getLabel: (item: T) => string;
};

export const renderChips = <T extends RenderChipsBaseItem>({
    data,
    getId,
    getLabel,
}: RenderChipsProps<T>) => {
    if (data.length === 0) {
        return <span>{ EMPTY_CELL }</span>;
    }

    return (
        <FlexWrapBox>
            { data.map((item) => (
                <Chip
                    label={ getLabel(item) }
                    variant="outlined"
                    color="primary"
                    size="small"
                    key={ getId(item) }
                />
            )) }
        </FlexWrapBox>
    );
};
