import { DEFAULT_DEBOUNCE_DELAY_IN_MS } from 'constants/time';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { CircularProgress, InputLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';
import { debounce } from 'lodash';
import { getOptions } from 'shared/utils/getOptions';
import { FetchDataParams, SearchT } from 'types/DataGrid';
import { OptionT } from 'types/Option';

type PropsT = {
    label: string;
    loading?: boolean;
    size?: 'small';
    empty?: boolean;
    fetchOptions: (
        params?: FetchDataParams<SearchT> & {
            page?: number;
        },
    ) => Promise<OptionT[]>;
    limit?: number;
};

const DynamicSelectWithSearch = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, size, empty, fetchOptions, limit = 10,
    } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });
    const [options, setOptions] = useState<OptionT[]>([]);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetch = async (search = '', pageNum = 1) => {
        try {
            setLoading(true);
            const data = await fetchOptions({
                page: pageNum,
                limit,
                search: { name: search },
            });

            setOptions((prev) => (pageNum === 1 ? data : [...prev, ...data]));
            setHasMore(data.length === limit);
        } finally {
            setLoading(false);
        }
    };

    const loadMore = () => {
        if (!loading && hasMore) {
            setPage((prev) => prev + 1);
        }
    };

    const onInputChange = debounce((_, value: string) => {
        setQuery(value);
    }, DEFAULT_DEBOUNCE_DELAY_IN_MS);

    const optionsWithEmpty = getOptions(options, empty);

    useEffect(() => {
        setPage(1);
        fetch(query, 1).then();
    }, [query]);

    useEffect(() => {
        if (page > 1) {
            fetch(query, page).then();
        }
    }, [page]);

    return (
        <Autocomplete
            isOptionEqualToValue={ (option, value) => option.value === value.value }
            freeSolo
            getOptionLabel={ (option) => option.label }
            value={ field.value }
            disablePortal
            options={ optionsWithEmpty }
            onInputChange={ (_, value) => {
                onInputChange(_, value);
            } }
            onChange={ (_, data) => {
                field.onChange(data || null);
            } }
            clearOnBlur
            clearOnEscape
            loading={ loading }
            ListboxProps={ {
                onScroll: (event) => {
                    const listBoxNode = event.currentTarget;

                    if (
                        listBoxNode.scrollTop + listBoxNode.clientHeight >=
                        listBoxNode.scrollHeight - 5
                    ) {
                        loadMore();
                    }
                },
            } }
            renderOption={ (prop, option) => (
                <li { ...prop } key={ option.value }>
                    { option.label }
                </li>
            ) }
            renderInput={ (params) => (
                <>
                    { label && <InputLabel>{ label }</InputLabel> }
                    <TextField
                        { ...params }
                        value={ query }
                        size={ size || 'medium' }
                        error={ !!fieldState.error }
                        helperText={
                            (fieldState.error?.type === FormErrorMessages.Required &&
                                `${COMMON_FORM_ERRORS[FormErrorMessages.Required]}`) ||
                            (fieldState.error?.type === FormErrorMessages.TypeError &&
                                `${COMMON_FORM_ERRORS[FormErrorMessages.Required]}`) ||
                            fieldState.error?.message
                        }
                        InputProps={ {
                            ...params.InputProps,
                            endAdornment: loading ? (
                                <CircularProgress size={ 20 } />
                            ) : (
                                params.InputProps.endAdornment
                            ),
                        } }
                    />
                </>
            ) }
        />
    );
};

export default DynamicSelectWithSearch;
