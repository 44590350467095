import { gql } from '@apollo/client';
import { AgeT } from 'types/Age';
import { AggregateT } from 'types/Graphql';

export type ServiceAppDocumentsResponseT = {
    documents: AgeT[];
    documentsCount: AggregateT;
};

export type ServiceAppDocumentResponseT = {
    document: AgeT;
};

export const SERVICE_APP_DOCUMENT_QUERY = gql`
    query ServiceAppDocument($id: String!) {
        document: service_app_document_by_pk(id: $id) {
            id
            service_app {
                id
                label
            }
            title
            url
            is_active
            position
        }
    }
`;

export const SERVICE_APP_DOCUMENTS_QUERY = gql`
    query ServiceAppDocuments(
        $limit: Int
        $offset: Int
        $orderBy: service_app_document_order_by! = {}
        $where: service_app_document_bool_exp! = {}
    ) {
        documents: service_app_document(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            service_app {
                id
                label
            }
            title
            url
            is_active
            position
        }
        documentsCount: service_app_document_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
