import { gql } from '@apollo/client';

export type CoachCreatePayloadT = {
    category_id: string | null;
    employee_id: string;
    service_app_id: string;
    color: string;
    active: boolean;
    work_days: Array<{
        day: number;
        start: string;
        end: string;
    }>;
};

export type CoachCreateResponseDataT = {
    createCoach: {
        id: string;
    };
} | null | undefined;

export type CoachCreateVarT = {
    object: CoachCreatePayloadT;
};

export const COACH_CREATE = gql`
    mutation CoachCreate($object: coach_insert_input = {}) {
        createCoach: insert_coach_one(
            object: $object
        ) {
            id
        }
    }
`;
