import { ERROR_MEMBER_CARD_TYPE_CREATE, SUCCESS_MEMBER_CARD_TYPE_CREATE } from 'constants/notificationMessage';
import { PATH_MEMBER_CARD_TYPE } from 'constants/routes';

import { useNavigate } from 'react-router-dom';
import { MemberCardTypeFormFields } from 'page/MemberCardType/components/formData';
import useMemberCardTypeCreate from 'shared/data-hook/memberCardType/create/useMemberCardTypeCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateMemberCardType = () => {
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { memberCardTypeCreate, loading, error } = useMemberCardTypeCreate();
    const navigate = useNavigate();

    const createMemberCardType = async (input: MemberCardTypeFormFields) => {
        const response = await memberCardTypeCreate(input);

        if (error || !response || !response.createMemberCardType) {
            showError(ERROR_MEMBER_CARD_TYPE_CREATE);
        } else {
            showSuccess(SUCCESS_MEMBER_CARD_TYPE_CREATE);
        }

        toggleRefetchTrigger();

        return navigate(PATH_MEMBER_CARD_TYPE);
    };

    return {
        createMemberCardType,
        loading,
    };
};

export default useCreateMemberCardType;
