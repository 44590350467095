import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import DynamicSelectWithSearch from 'shared/component/Form/DynamicSelectWithSearch';
import useUserFilterOptions from 'shared/data-hook/user/fetch/fetchUserFilterOptions/useUserFilterOptions';

type PropsT<T extends FieldValues> = {
    name: string;
    control: Control<T>;
    size?: 'small';
    label: string;
};

const UserSelect = <T extends FieldValues>(props: PropsT<T>) => {
    const { fetchUserFilterOptions } = useUserFilterOptions();

    return <DynamicSelectWithSearch { ...props } fetchOptions={ fetchUserFilterOptions } />;
};

export default UserSelect;
