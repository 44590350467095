import { useLazyQuery } from '@apollo/client';
import {
    IMPLEMENTATION_DOCUMENT_FILTER_OPTIONS_QUERY, ImplementationDocumentFilterOptionsResponseT,
} from 'shared/graphql/query/implementationDocument/ImplementationDocumentQuery';
import { FetchDataParams, SearchT } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { OptionT } from 'types/Option';

import { getNumberFilter } from '../filter/getNumberFilter';

import { ImplementationDocumentFilterOptionsDataT } from './ImplementationDocumentFilterOptionsData';

const useImplementationDocumentFilterOptions = (): ImplementationDocumentFilterOptionsDataT => {
    const [fetch, { loading, error }] = useLazyQuery<ImplementationDocumentFilterOptionsResponseT, QueryVarsT>(
        IMPLEMENTATION_DOCUMENT_FILTER_OPTIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchImplementationDocumentFilterOptions = async ({
        page = 1,
        limit,
        orderBy = {},
        search = { name: '' },
    }: FetchDataParams<SearchT> & {
        page?: number;
    } = {}): Promise<OptionT[]> => {
        try {
            const { data } = await fetch({
                variables: {
                    limit,
                    offset: limit ? (page - 1) * limit : 0,
                    orderBy,
                    where: {
                        ...getNumberFilter(search),
                    },
                },
            });

            return data?.implementationDocuments || [];
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchImplementationDocumentFilterOptions,
        loading,
        error,
    };
};

export default useImplementationDocumentFilterOptions;
