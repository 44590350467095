import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { EMPLOYEES_QUERY, EmployeesResponseT } from 'shared/graphql/query/employee/EmployeeQuery';
import { FetchDataParams } from 'types/DataGrid';
import { EMPTY_EMPLOYEES_RESULT } from 'types/Employee';
import { QueryVarsT } from 'types/Graphql';

import { EmployeesDTO } from '../DTO/EmployeesDTO';

import { EmployeesDataT } from './EmployeesData';

const useEmployees = (): EmployeesDataT => {
    const [fetch, { data, loading, error }] = useLazyQuery<EmployeesResponseT, QueryVarsT>(
        EMPLOYEES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedEmployeesData = decodeDTO(
        EmployeesDTO,
        EMPTY_EMPLOYEES_RESULT,
        (employees) => employees,
        {
            employees: data?.employees,
            employeesCount: data?.employeesCount,
        },
    );

    const fetchEmployees = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedEmployeesData.employees,
        count: decodedEmployeesData.employeesCount,
        fetchEmployees,
        loading,
        error,
    };
};

export default useEmployees;
