import { useMutation } from '@apollo/client';
import { GroupCategoryFormFields } from 'page/GroupCategory/components/modals/GroupCategoryModal/formData';
import { UpdateGroupCategoryDataT } from 'shared/data-hook/groupCategory/update/UpdateGroupCategoryData';
import {
    GROUP_CATEGORY_UPDATE,
    GroupCategoryUpdateResponseDataT,
    GroupCategoryUpdateVarT,
} from 'shared/graphql/mutation/groupCategory/GroupCategoryUpdate';
import { GroupCategoryT } from 'types/GroupCategory';

const useGroupCategoryUpdate = (): UpdateGroupCategoryDataT => {
    const [update, { error, loading }] = useMutation<GroupCategoryUpdateResponseDataT, GroupCategoryUpdateVarT>(
        GROUP_CATEGORY_UPDATE,
    );

    const updateAction = async (input: GroupCategoryFormFields, groupCategoryId: GroupCategoryT['id']) => {
        if (!groupCategoryId) {
            throw new Error('Не передан id группы');
        }

        try {
            const { data } = await update({
                variables: {
                    id: groupCategoryId,
                    set: {
                        name: input.name,
                    },
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        groupCategoryUpdate: updateAction,
        loading,
        error,
    };
};

export default useGroupCategoryUpdate;
