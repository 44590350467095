import { MODAL_TITLE_CREATE_EMPLOYEE } from 'constants/modalTitles';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCreateEmployee from 'shared/hook/employee/create/useCreateEmployee';
import { useStore } from 'store';

import EmployeeForm from './EmployeeForm';
import {
    EMPLOYEE_DEFAULT_VALUES, EmployeeFormFields, getEmployeeValidationSchema,
} from './formData';

const CreateEmployeeModal: FC = observer(() => {
    const { isOpen, onClose } = useStore('createEmployeeModal');
    const { createEmployee, loading: loadingSave } = useCreateEmployee();

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<EmployeeFormFields>({
        defaultValues: EMPLOYEE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getEmployeeValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateEmployee = (data: EmployeeFormFields) => {
        createEmployee(data).then(() => {
            handleCloseModal();
        });
    };

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_EMPLOYEE }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <EmployeeForm control={ control } />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateEmployee) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateEmployeeModal;
