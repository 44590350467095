import {
    ERROR_COACH_DELETE,
    SUCCESS_COACH_DELETE,
} from 'constants/notificationMessage';

import useCoachDelete from 'shared/data-hook/coach/delete/single/useCoachDelete';
import useCoaches from 'shared/data-hook/coach/fetch/fetchAll/useCoaches';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CoachT } from 'types/Coach';

const useDeleteCoach = () => {
    const { error, deleteCoach } = useCoachDelete();
    const { showError, showSuccess } = useNotification();
    const { fetchCoaches: fetchCoachesWithoutCategory } = useCoaches();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const coachDelete = async (coachId: CoachT['id']) => {
        const response = await deleteCoach(coachId);

        if (error || !response) {
            showError(ERROR_COACH_DELETE);
        } else {
            showSuccess(SUCCESS_COACH_DELETE);
        }

        toggleRefetchTrigger();
        await fetchCoachesWithoutCategory({ search: { without: true } });
    };

    return {
        coachDelete,
    };
};

export default useDeleteCoach;
