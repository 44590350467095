import { useMutation } from '@apollo/client';
import { ServiceAppDocumentFormFields } from 'page/ServiceAppDocuments/components/formData';
import { ServiceAppDocumentUpdateDataT } from 'shared/data-hook/serviceAppDocument/update/ServiceAppDocumentUpdateData';
import {
    SERVICE_APP_DOCUMENT_UPDATE,
    ServiceAppDocumentUpdateResponseDataT,
    ServiceAppDocumentUpdateVar,
} from 'shared/graphql/mutation/serviceAppDocument/ServiceAppDocumentUpdate';
import { ServiceAppDocumentT } from 'types/ServiceAppDocument';

const useServiceAppDocumentUpdate = (): ServiceAppDocumentUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        ServiceAppDocumentUpdateResponseDataT,
        ServiceAppDocumentUpdateVar
    >(SERVICE_APP_DOCUMENT_UPDATE);

    const updateAction = async (
        input: ServiceAppDocumentFormFields,
        documentId: ServiceAppDocumentT['id'],
    ) => {
        if (!documentId) {
            // TODO: use Sentry
            throw new Error('Document is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: documentId,
                    set: {
                        service_app_id: input.serviceApp,
                        title: input.title,
                        url: input.url,
                        is_active: input.isActive,
                        position: input.position,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // TODO: use Sentry
            console.error((errorUpdate as Error).message); // eslint-disable-line
            throw errorUpdate;
        }
    };

    return {
        documentUpdate: updateAction,
        loading,
        error,
    };
};

export default useServiceAppDocumentUpdate;
