import { API_DATE_TIME_FORMAT_WITHOUT_SECONDS } from 'constants/date';
import { EMPTY_OPTION } from 'constants/emptyOption';

import dayjs from 'dayjs';
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { WaitingListFormFields } from 'page/WaitingList/components/formData';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';

const DTO = t.intersection([
    t.type({
        client_data_id: t.string,
        group_id: Nullable(t.string),
        group_document_id: Nullable(t.string),
        organization_id: t.string,
        service_category_id: Nullable(t.string),
        price_type_id: t.string,
        coach_price_type_id: Nullable(t.string),
        user_id: t.string,
        duration: t.number,
        datetime: t.string,
        top_priority: Nullable(t.boolean),
        comment: Nullable(t.string),
        created_at: t.string,
    }),
    t.partial({
        waiting_list_coaches: t.type({
            data: t.array(
                t.type({
                    coach_id: t.string,
                }),
            ),
        }),
        waiting_list_courts: t.type({
            data: t.array(
                t.type({
                    court_id: t.string,
                }),
            ),
        }),
    }),
]);

export type WaitingListDTOT = t.TypeOf<typeof DTO>;

export const CreateWaitingListDTO = tPromise.extendType<WaitingListDTOT, WaitingListFormFields>(
    DTO,
    () => ({
        client: EMPTY_OPTION,
        group: '',
        groupDocument: EMPTY_OPTION,
        organization: '',
        serviceCategory: '',
        priceType: '',
        coachPriceType: '',
        user: EMPTY_OPTION,
        duration: 0,
        datetime: new Date(),
        topPriority: false,
        comment: '',
        createdAt: new Date(),
        coaches: [],
        courts: [],
    }),
    ({
        client,
        group,
        groupDocument,
        organization,
        serviceCategory,
        priceType,
        user,
        duration,
        datetime,
        topPriority,
        comment,
        createdAt,
        coachPriceType,
        coaches: coachIds,
        courts: courtIds,
    }) => {
        return {
            client_data_id: client?.value ?? '',
            group_id: group || null,
            group_document_id: groupDocument?.value || null,
            organization_id: organization,
            service_category_id: serviceCategory || null,
            price_type_id: priceType,
            coach_price_type_id: coachPriceType || null,
            user_id: user.value,
            duration,
            datetime: dayjs(datetime).format(API_DATE_TIME_FORMAT_WITHOUT_SECONDS),
            top_priority: topPriority || false,
            comment: comment || null,
            created_at: dayjs(createdAt).format(API_DATE_TIME_FORMAT_WITHOUT_SECONDS),
            ...(coachIds && {
                waiting_list_coaches: {
                    data: coachIds?.map((item) => ({ coach_id: item })) || [],
                },
            }),
            ...(courtIds && {
                waiting_list_courts: {
                    data: courtIds?.map((item) => ({ court_id: item })) || [],
                },
            }),
        };
    },
) as t.Type<WaitingListFormFields, WaitingListDTOT>;
