import { gql } from '@apollo/client';

export type GroupCategoryCreatePayloadT = {
    name: string;
}

export type GroupCategoryCreateResponseDataT = {
    createGroupCategory: {
        id: string;
    };
} | null | undefined;

export type GroupCategoryCreateVarT = {
    object: GroupCategoryCreatePayloadT;
}

export const GROUP_CATEGORY_CREATE = gql`
    mutation GroupCategoryCreate($object: group_category_insert_input = {}) {
        insert_group_category: insert_group_category_one(
            object: $object
        ) {
            id
        }
    }
`;
