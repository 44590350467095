import { ERROR_GROUP_VIEW_CREATE, SUCCESS_GROUP_VIEW_CREATE } from 'constants/notificationMessage';

import { GroupViewFormFields } from 'page/GroupView/components/formData';
import useGroupViewCreate from 'shared/data-hook/groupView/create/useGroupViewCreate';
import useGroupViews from 'shared/data-hook/groupView/fetch/fetchAll/useGroupViews';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateGroupView = () => {
    const { showError, showSuccess } = useNotification();
    const { fetchGroupViews: fetchGroupViewsWithoutCategory } = useGroupViews();
    const { loading, error, groupViewCreate } = useGroupViewCreate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createGroupView = async (input: GroupViewFormFields) => {
        const response = await groupViewCreate(input);

        if (error || !response) {
            showError(ERROR_GROUP_VIEW_CREATE);
        } else {
            showSuccess(SUCCESS_GROUP_VIEW_CREATE);
        }

        toggleRefetchTrigger();
        await fetchGroupViewsWithoutCategory({ search: { without: true } });
    };

    return {
        createGroupView,
        loading,
    };
};

export default useCreateGroupView;
