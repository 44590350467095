import {
    ERROR_WAITING_LIST_CREATE, SUCCESS_WAITING_LIST_CREATE,
} from 'constants/notificationMessage';

import { WaitingListFormFields } from 'page/WaitingList/components/formData';
import useWaitingListCreate from 'shared/data-hook/waitingList/create/useWaitingListCreate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useCreateWaitingList = () => {
    const { error, loading, createWaitingList: actionCreate } = useWaitingListCreate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createWaitingList = async (input: WaitingListFormFields) => {
        const response = await actionCreate(input);

        if (error || !response) {
            showError(ERROR_WAITING_LIST_CREATE);
        } else {
            showSuccess(SUCCESS_WAITING_LIST_CREATE);
        }

        toggleRefetchTrigger();
    };

    return {
        createWaitingList,
        loading,
    };
};

export default useCreateWaitingList;
