import { ERROR_PRICE_TYPE_CREATE, SUCCESS_PRICE_TYPE_CREATE } from 'constants/notificationMessage';
import { PATH_PRICE_TYPE } from 'constants/routes';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PriceTypeFormFields } from 'page/PriceType/components/formData';
import usePriceTypeCreate from 'shared/data-hook/priceType/create/usePriceTypeCreate';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const useCreatePriceType = () => {
    const { error, loading, priceTypeCreate } = usePriceTypeCreate();
    const { showError, showSuccess } = useNotification();
    const { setPriceTypeLoading } = useStore('priceTypes');
    const navigate = useNavigate();

    useEffect(() => {
        setPriceTypeLoading(loading);
    }, [loading]);

    const createPriceType = async (input: PriceTypeFormFields) => {
        const response = await priceTypeCreate(input);

        if (error || !response || !response.createPriceType) {
            showError(ERROR_PRICE_TYPE_CREATE);
        } else {
            showSuccess(SUCCESS_PRICE_TYPE_CREATE);
        }

        return navigate(PATH_PRICE_TYPE);
    };

    return {
        createPriceType,
    };
};

export default useCreatePriceType;
