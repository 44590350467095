import { PAGE_TITLE_COACH_SETTINGS } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import CreateCoachModal from 'page/Coach/components/CreateCoachModal';
import UpdateCoachModal from 'page/Coach/components/UpdateCoachModal';
import CoachCategoryList from 'page/CoachCategory/components/CoachCategoryList';
import CreateCoachCategoryModal from 'page/CoachCategory/components/CreateCoachCategory';
import UpdateCoachCategoryModal from 'page/CoachCategory/components/UpdateCoachCategoryModal';
import { COACH_CATEGORY_HEADER_DATA } from 'page/CoachCategory/domain/HeaderData';
import ControlPanel from 'shared/component/ControlPanel';
import useCoaches from 'shared/data-hook/coach/fetch/fetchAll/useCoaches';
import useCoachCategories from 'shared/data-hook/coachCategory/fetch/fetchAll/useCoachCategories';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import { DEFAULT_COACH_CATEGORY_SORT_MODEL } from './domain/coachCategorySortModel';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid(DEFAULT_COACH_CATEGORY_SORT_MODEL);
    const {
        data: coachCategoriesData, loading: coachCategoriesLoading, fetchCoachCategories, count: rowCount,
    } = useCoachCategories();
    const { data: coachesWithoutCategoryData, fetchCoaches: fetchCoachesWithoutCategory, loading: coachesWithoutCategoryLoading } = useCoaches();

    const { setCoachCategories } = useStore('coachCategory');
    const { setCoaches } = useStore('coach');
    const { onOpen: onOpenCoach } = useStore('createCoachModal');
    const { onOpen: onOpenCoachCategory } = useStore('createCoachCategoryModal');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_COACH_SETTINGS);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchCoachCategories, refetchTrigger, COACH_CATEGORY_HEADER_DATA);

    useEffect(() => {
        setCoachCategories(coachCategoriesData, rowCount);
    }, [coachCategoriesData, coachCategoriesLoading]);

    useEffect(() => {
        setCoaches(coachesWithoutCategoryData);
    }, [coachesWithoutCategoryData, coachesWithoutCategoryLoading]);

    useEffect(() => {
        fetchCoachesWithoutCategory({ search: { without: true } }).then();
    }, []);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" startIcon={ <AddIcon /> } onClick={ () => onOpenCoachCategory() }>добавить категорию</Button>
                <Button variant="outlined" size="small" startIcon={ <AddIcon /> } onClick={ () => onOpenCoach() }>добавить тренера</Button>
            </ControlPanel>
            <CoachCategoryList
                loading={ coachCategoriesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <UpdateCoachCategoryModal />
            <CreateCoachCategoryModal />
            <CreateCoachModal />
            <UpdateCoachModal />
        </StyledPageWrap>
    );
});

export default List;
