import { action, makeObservable, observable } from 'mobx';
import { PriceGrouping, PriceGroupingT } from 'types/Price';

import BaseModalStore from './BaseModalStore';

export default class SelectPriceGroupingModal extends BaseModalStore {
    public priceGrouping: PriceGroupingT = PriceGrouping.Empty;

    public setPriceGrouping(priceGrouping: PriceGroupingT) {
        this.priceGrouping = priceGrouping;
    }

    constructor() {
        super();

        makeObservable(this, {
            priceGrouping: observable,
            setPriceGrouping: action.bound,
        });
    }
}
