import { FC } from 'react';
import { observer } from 'mobx-react';
import { prepareGroupHeaderData } from 'page/GroupView/domain/prepareGroupHeaderData';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { useGroupedGroupViewsData } from './useGroupedGroupViewsData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const GroupViewsGrid: FC<PropsT> = observer((props) => {
    const { data: { rows, count } } = useGroupedGroupViewsData();
    const { getFilterOption } = useStore('filterOptions');
    const groupCategoryFilterOptions = getFilterOption('groupCategory');
    const columns = prepareGroupHeaderData(groupCategoryFilterOptions);

    return (
        <DataGrid
            rows={ rows }
            rowCount={ count }
            columns={ columns }
            rowAutoHeight={ true }
            { ...props }
        />
    );
});

export default GroupViewsGrid;
