import { GroupViewT } from './GroupView';

export type GroupCategoriesT = {
    groupCategories: GroupCategoryT[];
    groupCategoriesCount: number;
};

export const EMPTY_GROUP_CATEGORIES_RESULT: GroupCategoriesT = {
    groupCategories: [],
    groupCategoriesCount: 0,
};

export type GroupCategoryT = {
    id: string;
    name: string;
    groupViews: GroupViewT[];
};

export const EMPTY_GROUP_CATEGORY_RESULT: GroupCategoryT = {
    id: '',
    name: '',
    groupViews: [],
};
