import { useMutation } from '@apollo/client';
import { GroupViewFormFields } from 'page/GroupView/components/formData';
import { CreateGroupViewDataT } from 'shared/data-hook/groupView/create/CreateGroupViewData';
import {
    GROUP_VIEW_CREATE,
    GroupViewCreateResponseDataT,
    GroupViewCreateVarT,
} from 'shared/graphql/mutation/groupView/GroupViewCreate';

const useGroupViewCreate = (): CreateGroupViewDataT => {
    const [create, { loading, error }] = useMutation<GroupViewCreateResponseDataT, GroupViewCreateVarT>(
        GROUP_VIEW_CREATE,
    );

    const createAction = async (input: GroupViewFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        category_id: input.categoryId || null,
                        age_type_id: input.ageTypeId || null,
                        min_size: input.minSize,
                        avg_size: input.avgSize,
                        max_size: input.maxSize,
                        clients_for_main_coach: input.clientsForMainCoach,
                        ignore_busy_coaches: input.ignoreBusyCoaches,
                        group_constant_price: input.groupConstantPrice,
                        correction_factors: input.correctionFactors.map((cf, index) => ({
                            [index + 1]: parseFloat(cf.value),
                        })),
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        groupViewCreate: createAction,
    };
};

export default useGroupViewCreate;
