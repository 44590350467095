import {
    ERROR_EMPLOYEE_UPDATE, SUCCESS_EMPLOYEE_UPDATE,
} from 'constants/notificationMessage';

import { EmployeeFormFields } from 'page/Employee/components/formData';
import useEmployeeUpdate from 'shared/data-hook/employee/update/useEmployeeUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useUpdateEmployee = () => {
    const { error, employeeUpdate, loading } = useEmployeeUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateEmployee = async (data: EmployeeFormFields, id: string) => {
        const response = await employeeUpdate(data, id);

        if (error || !response) {
            showError(ERROR_EMPLOYEE_UPDATE);

            return false;
        }

        showSuccess(SUCCESS_EMPLOYEE_UPDATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        updateEmployee,
        loading,
    };
};

export default useUpdateEmployee;
