import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { COACH_HEADER_DATA } from 'page/Coach/domain/HeaderData';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { COACH_CATEGORY_HEADER_DATA } from '../domain/HeaderData';
import { ROW_WITHOUT_CATEGORY } from '../domain/rows';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const CoachCategoryList: FC<PropsT> = observer((props) => {
    const { coachCategories, count } = useStore('coachCategory');
    const { coaches } = useStore('coach');

    return (
        <DataGrid
            rows={ [...coachCategories, { ...ROW_WITHOUT_CATEGORY, coaches }] }
            rowCount={ count }
            columns={ COACH_CATEGORY_HEADER_DATA }
            detailPanelColumns={ COACH_HEADER_DATA }
            { ...props }
        />
    );
});

export default CoachCategoryList;
