export const SERVICE_CATEGORY_DURATION = [
    {
        label: 'Минимальная продолжительность',
        value: 'service_category_min_duration',
        popoverText: 'Режим работы корта, где начало занятия всегда будет с 00 или 30 минут, либо отключить эти ограничения и свободно выбирать время начала любого занятия',
    },
    {
        label: 'Минимальная дополнительная продолжительность',
        value: 'service_category_min_additional_duration',
        popoverText: 'Режим работы корта отключен, начало занятия можно выбирать свободно',
    },
];
