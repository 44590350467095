import { PAGE_TITLE_DOCUMENTS } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import CreateDocumentModal from 'page/ServiceAppDocuments/components/CreateDocumentModal';
import DocumentListComponent from 'page/ServiceAppDocuments/components/DocumentListComponent';
import UpdateDocumentModal from 'page/ServiceAppDocuments/components/UpdateDocumentModal';
import { SERVICE_APP_DOCUMENT_HEADER_DATA } from 'page/ServiceAppDocuments/domain/HeaderData';
import ControlPanel from 'shared/component/ControlPanel';
import useServiceAppDocuments from 'shared/data-hook/serviceAppDocument/fetch/fetchAll/useServiceAppDocuments';
import { SERVICE_APP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceApp/ServiceAppQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

const DocumentList: FC = observer(() => {
    const {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    } = useDataGrid();
    const {
        data: documentsData,
        loading: documentsLoading,
        count: rowCount,
        fetchDocuments,
    } = useServiceAppDocuments();

    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createServiceAppDocumentModal');
    const { loading: serviceAppFilterOptionsLoading } = useFetchFilterOptions(
        SERVICE_APP_FILTER_OPTIONS_QUERY,
        'serviceApp',
    );
    const { setDocuments } = useStore('serviceAppDocument');

    useFetchDataForGrid(
        sortModel,
        filterModel,
        page,
        pageSize,
        fetchDocuments,
        refetchTrigger,
        SERVICE_APP_DOCUMENT_HEADER_DATA,
    );
    useEffect(() => {
        setDocuments(documentsData, rowCount);
    }, [documentsData, documentsLoading]);

    const loading = serviceAppFilterOptionsLoading || documentsLoading;

    usePageTitle(PAGE_TITLE_DOCUMENTS);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => onOpen() }
                    startIcon={ <AddIcon /> }
                >
                    добавить
                </Button>
            </ControlPanel>
            <DocumentListComponent
                loading={ loading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateDocumentModal />
            <UpdateDocumentModal />
        </StyledPageWrap>
    );
});

export default DocumentList;
