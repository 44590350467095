import React, { FC, MouseEventHandler } from 'react';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { IconButton, Tooltip } from '@mui/material';
import { useStore } from 'store';

type PropsT = {
    id: string;
};

const CreateScheduleButton: FC<PropsT> = (props) => {
    const { id } = props;
    const { onOpen } = useStore('createScheduleModal');

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id);
    };

    return (
        <Tooltip title="Сгенерировать расписание" placement="top">
            <IconButton onClick={ handleButtonClick }>
                <PostAddIcon color="success" />
            </IconButton>
        </Tooltip>
    );
};

export default CreateScheduleButton;
