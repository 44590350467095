import { PROPERTY_TITLE } from 'constants/propertyNames';

import { useMemo } from 'react';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';
import { PriceGrouping } from 'types/Price';

export const useCategoryHeaderData = (): CustomHeaderData[] => {
    const { priceGrouping } = useStore('selectPriceGroupingModal');
    const sort = priceGrouping === PriceGrouping.Service ? 'position' : 'name';

    return useMemo(() => {
        return [
            {
                headerName: PROPERTY_TITLE,
                field: 'name',
                minWidth: 500,
                type: 'string',
                headerAlign: 'left',
                align: 'left',
                hasuraSortFields: [sort],
            },
        ];
    }, [sort]);
};
