import { useMutation } from '@apollo/client';
import {
    DUPLICATE_MEMBER_CARD_TYPE,
    DuplicateMemberCardTypeResponseT,
    DuplicateMemberCardTypeVarT,
    EMPTY_DUPLICATE_RESULT_DATA,
} from 'shared/graphql/mutation/memberCardType/MemberCardTypeDuplicate';
import { useStore } from 'store';

import { DuplicateMemberCardTypeDataT } from './MemberCardTypeDuplicateData';

const useMemberCardTypeDuplicate = (): DuplicateMemberCardTypeDataT => {
    const [duplicate, { loading }] = useMutation<
        DuplicateMemberCardTypeResponseT,
        DuplicateMemberCardTypeVarT
    >(DUPLICATE_MEMBER_CARD_TYPE);
    const { serviceApp } = useStore('serviceApp');

    const duplicateMemberCardType = async (id: string) => {
        try {
            const { data } = await duplicate({
                variables: {
                    appId: serviceApp.id,
                    payload: {
                        member_card_type_id: id,
                    },
                },
            });

            return data?.adminMemberCardTypeDuplicate || EMPTY_DUPLICATE_RESULT_DATA;
        } catch {
            return EMPTY_DUPLICATE_RESULT_DATA;
        }
    };

    return {
        duplicateMemberCardType,
        loading,
    };
};

export default useMemberCardTypeDuplicate;
