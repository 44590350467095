import {
    ERROR_MEMBER_CARD_CURRENT_DATE_LATER_THAN_EXP,
    ERROR_MEMBER_CARD_EXP_DATE_EARLIER_THAN_CURRENT,
} from 'constants/notificationMessage';
import {
    PROPERTY_CASHBACK_FIAT_TOTAL_ACCRUED,
    PROPERTY_CASHBACK_FIAT_TOTAL_OPERATIONS,
    PROPERTY_END,
    PROPERTY_IS_ACTIVE,
    PROPERTY_START,
} from 'constants/propertyNames';

import dayjs, { Dayjs } from 'dayjs';
import {
    date, number, object, ref,
} from 'yup';

export type UpdateMemberCardFormFields = {
    datetimeStart: Dayjs;
    datetimeEnd: Dayjs;
    isActive: boolean;
    memberCardType: string;
    cashbackFiatTotalAccrued: number;
    cashbackFiatTotalOperations: number;
};

export const UPDATE_MEMBER_CARD_DEFAULT_VALUES: UpdateMemberCardFormFields = {
    datetimeStart: dayjs(),
    datetimeEnd: dayjs(),
    isActive: false,
    memberCardType: '',
    cashbackFiatTotalAccrued: 0,
    cashbackFiatTotalOperations: 0,
};

export enum UPDATE_MEMBER_CARD_FIELDS_KEYS {
    memberCardType = 'memberCardType',
    datetimeStart = 'datetimeStart',
    datetimeEnd = 'datetimeEnd',
    isActive = 'isActive',
    cashbackFiatTotalAccrued = 'cashbackFiatTotalAccrued',
    cashbackFiatTotalOperations = 'cashbackFiatTotalOperations',
}

export const UPDATE_MEMBER_CARD_LABEL_DATA = {
    datetimeStart: PROPERTY_START,
    datetimeEnd: PROPERTY_END,
    isActive: PROPERTY_IS_ACTIVE,
    cashbackFiatTotalAccrued: PROPERTY_CASHBACK_FIAT_TOTAL_ACCRUED,
    cashbackFiatTotalOperations: PROPERTY_CASHBACK_FIAT_TOTAL_OPERATIONS,
};

export const getUpdateMemberCardValidationSchema = () => (
    object().shape({
        datetimeStart: date()
            .max(ref('datetimeEnd'), ERROR_MEMBER_CARD_CURRENT_DATE_LATER_THAN_EXP)
            .required(),
        datetimeEnd: date()
            .min(ref('datetimeStart'), ERROR_MEMBER_CARD_EXP_DATE_EARLIER_THAN_CURRENT)
            .required(),
        cashbackFiatTotalAccrued: number().required(),
        cashbackFiatTotalOperations: number().required(),
    })
);
