import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PriceExtendedDTO } from 'shared/data-hook/price/fetch/DTO/PriceExtendedDTO';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PricesByServiceCategoryT } from 'types/PricesByServiceCategory';

const DTO = (currency: string) => t.type({
    id: t.string,
    name: t.string,
    position: Nullable(t.number),
    prices: t.array(PriceExtendedDTO(currency)),
});

export type PricesByServiceCategoryDTOT = t.TypeOf<ReturnType<typeof DTO>>;

export const PricesByServiceCategoryDTO = (currency: string) => tPromise.extendDecoder<PricesByServiceCategoryDTOT, PricesByServiceCategoryT>(
    DTO(currency),
    ({
        id,
        name,
        position,
        prices,
    }) => ({
        id,
        name,
        position,
        prices,
    }),
) as t.Type<PricesByServiceCategoryT, PricesByServiceCategoryDTOT>;
