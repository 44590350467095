import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { EmployeesT } from 'types/Employee';

import { EmployeeDTO } from './EmployeeDTO';

const DTO = t.type({
    employees: t.array(EmployeeDTO),
    employeesCount: AggregateDTO,
});

type EmployeeDTOT = t.TypeOf<typeof DTO>;

export const EmployeesDTO = tPromise.extendDecoder<EmployeeDTOT, EmployeesT>(
    DTO,
    ({
        employees,
        employeesCount,
    }) => ({
        employees,
        employeesCount,
    }),
) as t.Type<EmployeesT, EmployeeDTOT>;
